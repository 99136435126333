// TODO: Move this to a common folder outside calls table since these
// are re-used across all table filters.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAccountData, getAllUsersInTenant, getContactData, getLeadData, getOpportunityData, } from "../common/endpoints";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { getAccountLabel } from "../common/account_popover/account_popover_button";
import { getDepartments } from "../departments/endpoints";
import { getTeams } from "../teams/endpoints";
export const leadsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const leadsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getLeadData(JSON.stringify({
                table: "Lead",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getLeads", cursor, userQuery, ids],
    });
    return {
        results: leadsQueryResponse.data.map((lead) => {
            var _a, _b;
            return {
                label: (_b = (_a = lead.name) !== null && _a !== void 0 ? _a : lead.email) !== null && _b !== void 0 ? _b : "",
                value: lead.id.toString(),
            };
        }),
    };
});
export const accountsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const accountsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getAccountData(JSON.stringify({
                table: "Account",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getAccounts", cursor, userQuery, ids],
    });
    return {
        results: accountsQueryResponse.data.map((account) => {
            return {
                label: getAccountLabel(undefined, Object.assign(Object.assign({}, account), { account_name: "" })),
                value: account.id.toString(),
            };
        }),
    };
});
export const opportunitiesFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const opportunitiesQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getOpportunityData(JSON.stringify({
                table: "Opportunity",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getOpportunities", cursor, userQuery, ids],
    });
    return {
        results: opportunitiesQueryResponse.data.map((opportunity) => {
            return {
                label: opportunity.name,
                value: opportunity.id.toString(),
            };
        }),
    };
});
export const usersFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const usersQueryResponse = yield queryClient.fetchQuery({
        queryKey: ["getAllUsersInTenant", cursor, userQuery],
        queryFn: () => getAllUsersInTenant(ids, cursor, userQuery),
    });
    return {
        results: usersQueryResponse.items.map((user) => {
            return { label: user.email, value: user.id.toString() };
        }),
        next_cursor: (_a = usersQueryResponse.next_cursor) === null || _a === void 0 ? void 0 : _a.toString(),
    };
});
export const contactsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const contactsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "email",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getContactData(JSON.stringify({
                table: "Contact",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getContacts", cursor, userQuery, ids],
    });
    return {
        results: contactsQueryResponse.data.map((contact) => {
            var _a;
            return {
                label: (_a = contact.email) !== null && _a !== void 0 ? _a : "",
                value: contact.id.toString(),
            };
        }),
    };
});
export const departmentsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const departmentsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getDepartments(JSON.stringify({
                table: "Department",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getDepartments", cursor, userQuery, ids],
    });
    return {
        results: departmentsQueryResponse.data.map((department) => {
            return {
                label: department.name,
                value: department.id.toString(),
            };
        }),
    };
});
export const teamsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const teamsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getTeams(JSON.stringify({
                table: "Team",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getTeams", cursor, userQuery, ids],
    });
    return {
        results: teamsQueryResponse.data.map((team) => {
            return {
                label: team.name,
                value: team.id.toString(),
            };
        }),
    };
});
