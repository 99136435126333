var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import classNames from "../class_names";
const buttonVariants = cva("inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 font-['Lato']", {
    variants: {
        variant: {
            default: "bg-primary text-primary-foreground shadow hover:bg-primary-dark",
            destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive-dark",
            outline: "border border-primary text-primary bg-background shadow-sm hover:bg-accent hover:text-primary-dark",
            secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary-dark",
            ghost: "text-primary hover:bg-accent hover:text-accent-foreground",
            link: "text-primary underline-offset-4 hover:underline",
            light: "bg-wds-blue-1 border-wds-blue-2 text-wds-blue-4 font-extrabold hover:bg-wds-blue-2",
            tableAction: "text-black flex justify-center items-center gap-1.5 border border-wds-gray-3 shadow-md bg-white hover:bg-wds-gray-1",
            dark: "bg-gray-800 text-white hover:bg-gray-700",
            black: "bg-wds-black text-white hover:bg-gray-700",
            invertedBlack: "bg-white text-wds-black hover:bg-gray-100",
        },
        size: {
            default: "h-9 px-4 py-2 rounded-full",
            sm: "h-8 px-3 text-sm rounded-full",
            lg: "h-10 px-4 rounded-full",
            icon: "h-9 w-9",
            tableAction: "h-9 px-4 py-2 rounded-md",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
});
/**
 * Button Component
 *
 * A customizable button component with multiple variants and sizes.
 * Uses the `cva` utility to manage styles based on the provided variants.
 *
 * `buttonVariants` defines the base styles and the variants for the button:
 * - Variants:
 *   - `default`: Primary button style with background and text colors.
 *   - `destructive`: Button style for destructive actions.
 *   - `outline`: Button with an outline, used for less prominent actions.
 *   - `secondary`: Secondary button style.
 *   - `ghost`: Button with no background, used for subtle actions.
 *   - `link`: Styled like a link with underline.
 * - Sizes:
 *   - `default`: Standard button size.
 *   - `sm`: Small button size.
 *   - `lg`: Large button size.
 *   - `icon`: Square button for icons.
 *
 * Usage:
 * ```
 * <Button variant="outline" size="lg">Large Outline Button</Button>
 * <Button variant="destructive">Destructive Button</Button>
 * <Button asChild><a href="/login">Login</a></Button>
 * ```
 *
 * Props:
 * - `variant` (string): The variant style of the button. Default is "default".
 * - `size` (string): The size of the button. Default is "default".
 * - `className` (string): Additional CSS classes to apply to the button.
 * - `asChild` (boolean): If true, the button will render as a different component specified in `Slot`. See 3rd usage for example.
 * - `...props` (React.ButtonHTMLAttributes<HTMLButtonElement>): Any other HTML attributes to apply to the button.
 *
 * @param {ButtonProps} props - The properties for the button component.
 * @returns {JSX.Element} The rendered button component.
 */
const Button = React.forwardRef((_a, ref) => {
    var { className, variant, size, asChild = false } = _a, props = __rest(_a, ["className", "variant", "size", "asChild"]);
    const Comp = asChild ? Slot : "button";
    return (_jsx(Comp, Object.assign({ className: classNames(buttonVariants({ variant, size, className })), ref: ref }, props)));
});
Button.displayName = "Button";
export { Button, buttonVariants };
