import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowRightOnRectangleIcon, PuzzlePieceIcon, } from "@heroicons/react/24/outline";
import { UserContext } from "./App";
import classNames from "./class_names";
import { Popover, PopoverContent, PopoverTrigger } from "./components/popover";
import { Button } from "./components/button";
import { useQuery } from "react-query";
import { checkExtensionInstalled } from "./home/extension_checker";
import { SidebarGroup, SidebarGroupContent, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarSeparator, } from "./components/sidebar";
const WISER_CALENDAR_URL = "https://chromewebstore.google.com/detail/wiser/eojamodgpoihijaopafkbllbdmhlbemf";
export function NavBottom(props) {
    const [extensionPopupOpen, setExtensionPopupOpen] = useState(false);
    const [extensionPopupDismissed, setExtensionPopupDismissed] = useState(false);
    const { userPicture } = useContext(UserContext);
    const wiserExtensionInstalled = useQuery("wiserExtensionInstalled", checkExtensionInstalled);
    const profileName = props.expanded ? (
    /** TODO: replace with user's actual name */
    _jsx(Fragment, { children: "Your Profile" })) : (_jsx("span", Object.assign({ className: classNames(props.expanded ? "" : "sr-only") }, { children: "Your Profile" })));
    const chromeExtension = props.expanded ? (_jsx(Fragment, { children: "Get web extension" })) : (_jsx("span", Object.assign({ className: classNames(props.expanded ? "" : "sr-only") }, { children: "Get web extension" })));
    const logoutName = props.expanded ? (_jsx(Fragment, { children: "Logout" })) : (_jsx("span", Object.assign({ className: classNames(props.expanded ? "" : "sr-only") }, { children: "Logout" })));
    const logoutButton = (_jsxs("button", Object.assign({ type: "button", className: "cursor-pointer w-[--sidebar-width] text-wds-blue-1 font-medium group flex gap-x-3 hover:bg-wds-blue-4 p-2 text-[.9375rem] leading-6 rounded-lg", onClick: props.onLogoutButtonClick }, { children: [_jsx(ArrowRightOnRectangleIcon, { className: "h-6 w-6 shrink-0 stroke-2 text-wds-blue-1", "aria-hidden": "true" }), logoutName] })));
    const profileButton = (_jsxs(Link, Object.assign({ to: "settings/user_settings", onClick: (evt) => evt.stopPropagation(), className: "w-[--sidebar-width] text-wds-blue-1 font-medium group gap-x-3 flex p-2 hover:bg-wds-blue-4 text-[.9375rem] leading-6 rounded-lg" }, { children: [_jsx("img", { className: "h-6 w-6 rounded-full bg-gray-800", src: userPicture, alt: "Your profile" }), profileName] })));
    const extensionButton = (_jsx(_Fragment, { children: _jsxs(Popover, Object.assign({ open: extensionPopupOpen && !extensionPopupDismissed, onOpenChange: (o) => {
                setExtensionPopupOpen(o);
                if (!o) {
                    setExtensionPopupDismissed(true);
                }
            } }, { children: [_jsx(PopoverTrigger, Object.assign({ onClick: (e) => {
                        e.stopPropagation();
                    } }, { children: _jsxs(Link, Object.assign({ to: "#", onClick: (e) => {
                            setExtensionPopupOpen((o) => !o);
                            setExtensionPopupDismissed(false);
                        }, className: "w-[--sidebar-width] text-wds-blue-1 font-medium group flex gap-x-3  hover:bg-wds-blue-4 p-2 text-[.9375rem] leading-6 rounded-lg" }, { children: [_jsx(PuzzlePieceIcon, { className: "h-6 w-6 shrink-0 stroke-2 text-wds-blue-1", "aria-hidden": "true" }), chromeExtension] })) })), _jsx(PopoverContent, Object.assign({ side: "right", className: "w-80 data-[state=open]:animate-fade-in-800ms data-[state=closed]:animate-fade-out-0 data-[state=closed]:animate-zoom-out-0" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start text-wds-black" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2 mb-2" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/icon_on_branded_bg__1_.png", alt: "wiser", className: "w-8 h-8" }), _jsx("h2", Object.assign({ className: "text-lg font-semibold" }, { children: "Wiser Chrome extension" }))] })), _jsx("p", Object.assign({ className: "mb-4 text-sm" }, { children: "Install Wiser's Chrome extension to gain easy access from Google Calendar." })), _jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [_jsx(Button, Object.assign({ size: "sm", onClick: (e) => {
                                            window.open(WISER_CALENDAR_URL, "_blank");
                                            e.stopPropagation();
                                        } }, { children: "Install" })), _jsx(Button, Object.assign({ size: "sm", variant: "outline", onClick: (e) => {
                                            const dismissal_count_str = localStorage.getItem("wiser-extension-dismissal-count") ||
                                                "0";
                                            localStorage.setItem("wiser-extension-dismissal-count", (parseInt(dismissal_count_str) + 1).toString());
                                            setExtensionPopupDismissed(true);
                                            e.stopPropagation();
                                        } }, { children: "Not now" }))] }))] })) }))] })) }));
    if (wiserExtensionInstalled.data === false && !extensionPopupOpen) {
        setExtensionPopupOpen(true);
    }
    return (_jsxs("div", Object.assign({ className: "mb-4 gap-2" }, { children: [_jsx(SidebarSeparator, {}), _jsx(SidebarGroup, Object.assign({ className: "px-0 space-y-2" }, { children: _jsx(SidebarGroupContent, { children: _jsxs(SidebarMenu, { children: [_jsx(SidebarMenuItem, { children: _jsx(SidebarMenuButton, Object.assign({ asChild: true, tooltip: "Extension" }, { children: extensionButton })) }), _jsx(SidebarMenuItem, { children: _jsx(SidebarMenuButton, Object.assign({ asChild: true, tooltip: "Account" }, { children: profileButton })) }), _jsx(SidebarMenuItem, { children: _jsx(SidebarMenuButton, Object.assign({ asChild: true, tooltip: "Logout" }, { children: logoutButton })) })] }) }) }))] })));
}
