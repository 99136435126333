import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HomeIcon, UserGroupIcon, ClipboardDocumentListIcon, BookOpenIcon, Square3Stack3DIcon, ArrowsRightLeftIcon, UsersIcon, ClockIcon, Bars3Icon, ChevronRightIcon, BriefcaseIcon, CurrencyDollarIcon, RectangleGroupIcon, CogIcon, ChatBubbleBottomCenterTextIcon, FolderIcon, ClipboardDocumentIcon, Bars4Icon, IdentificationIcon, VideoCameraIcon, } from "@heroicons/react/24/outline";
import { HomeIcon as HomeIconSolid, UserGroupIcon as UserGroupIconSolid, ClipboardDocumentListIcon as ClipboardDocumentListIconSolid, FolderIcon as FolderIconSolid, BookOpenIcon as BookOpenIconSolid, Square3Stack3DIcon as Square3Stack3DIconSolid, ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid, ArrowsRightLeftIcon as ArrowsRightLeftIconSolid, UsersIcon as UsersSolidIcon, ClockIcon as ClockIconSolid, XMarkIcon as XMarkIconSolid, BriefcaseIcon as BriefcaseIconSolid, CurrencyDollarIcon as CurrencyDollarIconSolid, RectangleGroupIcon as RectangleGroupIconSolid, CogIcon as CogIconSolid, ClipboardDocumentIcon as ClipboardDocumentIconSolid, Bars4Icon as Bars4IconSolid, IdentificationIcon as IdentificationIconSolid, VideoCameraIcon as VideoCameraIconSolid, } from "@heroicons/react/24/solid";
import { Sidebar, SidebarContent, SidebarFooter, SidebarGroup, SidebarGroupContent, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton, SidebarMenuSubItem, SidebarSeparator, useSidebar, } from "./components/sidebar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger, } from "./components/collapsible";
import { useLocation, useNavigate } from "react-router-dom";
import WiserLogoDarkBgTransparent from "./icons/wiser_logo_dark_bg_transparent";
import { Button } from "./components/button";
import { NavBottom } from "./nav_bottom";
const getNavigationMenuItems = (includeStaffUserItems, includeTenantAdminItems, enabledFeatures) => {
    var _a;
    let items = [
        { name: "Home", href: "home", icon: HomeIcon, iconSolid: HomeIconSolid },
        {
            name: "CRM",
            href: "#",
            icon: BriefcaseIcon,
            iconSolid: BriefcaseIconSolid,
            items: [
                {
                    name: "Accounts",
                    href: `crm/home/accounts?default=true`,
                    icon: UserGroupIcon,
                    iconSolid: UserGroupIconSolid,
                },
                {
                    name: "Contacts",
                    href: `crm/home/contacts?default=true`,
                    icon: UsersIcon,
                    iconSolid: UsersSolidIcon,
                },
                {
                    name: "Opportunities",
                    href: `crm/home/opportunities?default=true`,
                    icon: CurrencyDollarIcon,
                    iconSolid: CurrencyDollarIconSolid,
                },
            ],
        },
        {
            name: "Calls",
            href: `calls?default=true`,
            icon: ClockIcon,
            iconSolid: ClockIconSolid,
        },
    ];
    const adminSubItems = [
        {
            name: "Trackers",
            href: "trackers",
            icon: ClipboardDocumentListIcon,
            iconSolid: ClipboardDocumentListIconSolid,
        },
        {
            name: "Prompts",
            href: "custom_prompts",
            icon: ChatBubbleBottomCenterTextIcon,
            iconSolid: ChatBubbleBottomCenterTextIconSolid,
        },
        {
            name: "Prompt Sets",
            href: "research",
            icon: FolderIcon,
            iconSolid: FolderIconSolid,
        },
    ];
    if (enabledFeatures.includes("LEADS_TABLE")) {
        (_a = items[1].items) === null || _a === void 0 ? void 0 : _a.push({
            name: "Leads",
            href: `crm/home/leads?default=true`,
            icon: IdentificationIcon,
            iconSolid: IdentificationIconSolid,
        });
    }
    if (enabledFeatures.includes("WISER_GALLERY_UI")) {
        adminSubItems.push({
            name: "Prompts Gallery",
            href: "brick_gallery",
            icon: ClipboardDocumentIcon,
            iconSolid: ClipboardDocumentIconSolid,
        });
    }
    adminSubItems.push({
        name: "Docs",
        href: "live_cards",
        icon: BookOpenIcon,
        iconSolid: BookOpenIconSolid,
    }, {
        name: "Meeting Templates",
        href: "meeting_templates",
        icon: Square3Stack3DIcon,
        iconSolid: Square3Stack3DIconSolid,
    }, {
        name: "Users",
        href: `users?default=true`,
        icon: UsersIcon,
        iconSolid: UsersSolidIcon,
    }, {
        name: "Teams",
        href: `teams?default=true`,
        icon: UserGroupIcon,
        iconSolid: UserGroupIconSolid,
    }, {
        name: "Departments",
        href: `departments?default=true`,
        icon: RectangleGroupIcon,
        iconSolid: RectangleGroupIconSolid,
    }, {
        name: "Company Details",
        href: "company_details",
        icon: Bars4Icon,
        iconSolid: Bars4IconSolid,
    });
    if (enabledFeatures.includes("CLIP_LIBRARY")) {
        adminSubItems.push({
            name: "Library",
            href: "library",
            icon: VideoCameraIcon,
            iconSolid: VideoCameraIconSolid,
        });
    }
    const adminItem = {
        name: "Admin",
        href: "#",
        icon: CogIcon,
        iconSolid: CogIconSolid,
        items: adminSubItems,
    };
    if (includeTenantAdminItems) {
        items.push(adminItem);
    }
    if (includeStaffUserItems) {
        items.push({
            name: "Tenants",
            href: "tenants",
            icon: ArrowsRightLeftIcon,
            iconSolid: ArrowsRightLeftIconSolid,
        });
    }
    return items;
};
export function AppSidebar({ onLogoutButtonClick, includeStaffUserItems, includeTenantAdminItems, enabledFeatures, }) {
    const { state, open, setOpen, toggleSidebar, isMobile, openMobile, setOpenMobile, } = useSidebar();
    const items = getNavigationMenuItems(includeStaffUserItems, includeTenantAdminItems, enabledFeatures);
    const isOpen = open || openMobile;
    const navigate = useNavigate();
    const location = useLocation();
    return (_jsxs(Sidebar, Object.assign({ side: "left", collapsible: "icon" }, { children: [_jsx(SidebarHeader, Object.assign({ className: open ? "p-4" : "" }, { children: _jsxs("div", Object.assign({ className: "flex justify-start items-center relative text-wds-gray-5 gap-2" }, { children: [!isOpen && (_jsxs(Button, Object.assign({ variant: "ghost", onClick: toggleSidebar, className: "hover:bg-wds-blue-5 hover:text-wds-blue-1 m-0 p-0" }, { children: [_jsx(Bars3Icon, { className: "h-6 w-10 text-wds-blue-1", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Open sidebar" }))] }))), isOpen && (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs(Button, Object.assign({ variant: "ghost", onClick: toggleSidebar, className: "hover:bg-wds-blue-5 hover:text-wds-blue-1 m-0 p-0" }, { children: [_jsx(XMarkIconSolid, { className: "h-6 w-10 text-wds-blue-1", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Close sidebar" }))] })) }), _jsx("div", { children: _jsx("span", Object.assign({ className: "h-10 w-auto" }, { children: _jsx(WiserLogoDarkBgTransparent, { className: "w-[147px] h-8" }) })) })] }))] })) })), _jsx(SidebarSeparator, {}), _jsx(SidebarContent, { children: _jsx(SidebarGroup, { children: _jsx(SidebarGroupContent, { children: _jsx(SidebarMenu, { children: items.map((item) => {
                                var _a;
                                const isSelected = `/${item.href.split("?")[0]}` === location.pathname;
                                const IconComponent = isSelected ? item.iconSolid : item.icon;
                                return (_jsx(Collapsible, Object.assign({ asChild: true, defaultOpen: false, className: "group/collapsible" }, { children: _jsxs(SidebarMenuItem, Object.assign({ className: isSelected ? "bg-wds-blue-5" : "" }, { children: [_jsx(CollapsibleTrigger, Object.assign({ className: "w-full" }, { children: _jsx(SidebarMenuButton, Object.assign({ asChild: true, tooltip: item.name, className: "h-10 text-sm" }, { children: _jsxs("a", Object.assign({ href: item.href !== "#" ? `/${item.href}` : undefined, onClick: (e) => {
                                                            if (item.href === "#") {
                                                                if (isMobile) {
                                                                    setOpenMobile(true);
                                                                }
                                                                else {
                                                                    setOpen(true);
                                                                }
                                                            }
                                                            else {
                                                                // Handle left-click navigation normally
                                                                if (!e.ctrlKey &&
                                                                    !e.metaKey &&
                                                                    e.button === 0) {
                                                                    if (isMobile) {
                                                                        setOpenMobile(false);
                                                                    }
                                                                    else {
                                                                        setOpen(false);
                                                                    }
                                                                }
                                                            }
                                                        }, onAuxClick: (e) => {
                                                            // Handle middle-click to open in a new tab
                                                            if (e.button === 1 && item.href !== "#") {
                                                                window.open(`/${item.href}`, "_blank");
                                                            }
                                                        } }, { children: [_jsx(IconComponent, { className: `h-6 w-6 shrink-0 stroke-2 ${isSelected ? "text-wds-blue-1" : ""}` }), _jsx("span", Object.assign({ className: "group-data-[state=collapsed]:hidden text-[15px]" }, { children: item.name })), item.items && (_jsx(ChevronRightIcon, { className: "h-6 w-6 stroke-2 transition-transform duration-200 group-data-[state=collapsed]:hidden group-data-[state=open]/collapsible:rotate-90" }))] })) })) })), _jsx(CollapsibleContent, { children: _jsx(SidebarMenuSub, { children: (_a = item.items) === null || _a === void 0 ? void 0 : _a.map((subItem) => {
                                                        const isSelected = `/${subItem.href.split("?")[0]}` ===
                                                            location.pathname;
                                                        const IconComp = isSelected
                                                            ? subItem.iconSolid
                                                            : subItem.icon;
                                                        return (_jsx(SidebarMenuSubItem, Object.assign({ className: isSelected ? "bg-wds-blue-5" : "" }, { children: _jsx(SidebarMenuSubButton, Object.assign({ className: "cursor-pointer h-10 text-sm" }, { children: _jsxs("a", Object.assign({ className: "flex flex-row justify-between gap-2", href: subItem.href !== "#"
                                                                        ? `/${subItem.href}`
                                                                        : undefined, onClick: () => {
                                                                        if (subItem.href === "#") {
                                                                            return;
                                                                        }
                                                                        if (isMobile) {
                                                                            setOpenMobile(false);
                                                                        }
                                                                        else {
                                                                            setOpen(false);
                                                                        }
                                                                    } }, { children: [_jsx(IconComp, { className: `h-6 w-6 shrink-0 stroke-2 ${isSelected ? "text-wds-blue-1" : ""}` }), _jsx("span", Object.assign({ className: "text-[15px]" }, { children: subItem.name }))] })) })) }), subItem.name));
                                                    }) }) })] }), item.name) }), item.name));
                            }) }) }) }) }), _jsx(SidebarFooter, { children: _jsx(NavBottom, { onLogoutButtonClick: onLogoutButtonClick, expanded: isOpen }) })] })));
}
