import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisVerticalIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { getBrickSetsByType, getBricksForBrickSet } from "../common/endpoints";
import Dropdown from "../common/dropdown";
import { useNavigate } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { Button } from "../components/button";
/**
 * Call Agenda tab in the Call Launch page for calls in the future. Mainly has view playbooks & edit playbooks feature.
 *
 * @param {BrickSet} brickSet - The current brick set object.
 * @param {(brickSet: BrickSet) => void} updateCallTrackerBrickSet - Passes updated brick set object to the parent component if any brick set attributes change.
 */
export default ({ brickSet, updateCallTrackerBrickSet, }) => {
    const [trackerBricksForCall, setTrackerBricksForCall] = useState([]);
    const [allTrackerBrickSets, setAllTrackerBrickSets] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getBrickSetsByType("TRACKER").then(setAllTrackerBrickSets);
        getBricksForBrickSet(brickSet.id).then(setTrackerBricksForCall);
    }, []);
    useEffect(() => {
        getBricksForBrickSet(brickSet.id).then(setTrackerBricksForCall);
    }, [brickSet]);
    const renderTrackerBrickSet = (brickSet) => {
        if (brickSet === null) {
            return (_jsx("div", Object.assign({ className: "p-4 bg-white rounded-lg border border-neutral-200 flex flex-col gap-4 mb-4" }, { children: _jsx("div", Object.assign({ className: "flex justify-between items-center w-full" }, { children: _jsx("div", Object.assign({ className: "text-base font-bold leading-normal" }, { children: "No trackers selected" })) })) })));
        }
        return trackerBricksForCall.map(({ name, id }) => (_jsx("div", Object.assign({ className: "p-4 bg-white rounded-lg border border-neutral-200 flex flex-col gap-4 mb-4" }, { children: _jsxs("div", Object.assign({ className: "flex justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "font-bold leading-snug" }, { children: name })), _jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsxs(Popover, { children: [_jsx(PopoverTrigger, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "text-wds-black hover:text-wds-black rounded-full p-2 leading-snug" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-5 h-5" }) })) }), _jsx(PopoverContent, Object.assign({ side: "bottom", align: "start", sideOffset: 0, className: "w-full h-full py-2 px-0" }, { children: _jsx("div", Object.assign({ className: "flex flex-col" }, { children: _jsxs(Button, Object.assign({ variant: "ghost", className: "text-wds-black font-normal hover:bg-wds-gray-1 hover:text-wds-black rounded-none items-start justify-start", onClick: () => {
                                                navigate(`/research`, {
                                                    state: {
                                                        editingBlockId: id,
                                                        templateId: brickSet.id,
                                                        brickType: "TRACKER",
                                                    },
                                                });
                                            }, style: {
                                                fontFamily: "Lato, sans-serif",
                                                alignItems: "center",
                                                alignSelf: "stretch",
                                            } }, { children: [_jsx(PencilIcon, { className: "w-5 h-5 relative text-primary hover:text-primary-dark mr-2" }), "Edit block configuration"] })) })) }))] }) }))] })) }), id)));
    };
    return (_jsxs("div", Object.assign({ className: "w-full px-8" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center mb-4" }, { children: [_jsx("div", Object.assign({ className: "font-bold text-[18px] leading-6" }, { children: "Trackers" })), _jsx("div", Object.assign({ className: "relative flex items-center" }, { children: _jsx(Dropdown, { options: allTrackerBrickSets.map((brickSet) => ({
                                value: brickSet.id.toString(),
                                label: brickSet.name,
                            })), defaultSelected: brickSet.id.toString(), onOptionSelect: (id) => {
                                updateCallTrackerBrickSet(allTrackerBrickSets.find((brickSet) => brickSet.id === parseInt(id)));
                            }, buttonClasses: "h-8 text-sm" }) }))] })), brickSet && renderTrackerBrickSet(brickSet)] })));
};
