var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HUBSPOT_OBJECT_TYPES, PostCallOutputTarget, SALESFORCE_OBJECT_TYPES, WISER_CRM_OBJECT_TYPES, } from "../types";
// The list of possible update types for each integration.
// The order of the options here define the order in which they are rendered.
const UPDATE_TYPES = new Map([
    [
        PostCallOutputTarget.SALESFORCE,
        [
            {
                label: "Overwrite",
                value: "OVERWRITE",
                applyLambda: (postCallFieldMapItem, onDataItemValueUpdate) => __awaiter(void 0, void 0, void 0, function* () {
                    yield onDataItemValueUpdate(postCallFieldMapItem.valueToUpdate, 
                    /* undoClicked= */ false);
                }),
            },
            {
                label: "Append",
                value: "APPEND",
                applyLambda: (postCallFieldMapItem, onDataItemValueUpdate) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    const today = new Date();
                    yield onDataItemValueUpdate(`${today.toDateString()}\n--\n${postCallFieldMapItem.valueToUpdate}\n\n${(_a = postCallFieldMapItem.currentValue) !== null && _a !== void 0 ? _a : ""}`, 
                    /* undoClicked= */ false);
                }),
            },
        ],
    ],
    [
        PostCallOutputTarget.HUBSPOT,
        [
            {
                label: "Overwrite",
                value: "OVERWRITE",
                applyLambda: (postCallFieldMapItem, onDataItemValueUpdate) => __awaiter(void 0, void 0, void 0, function* () {
                    yield onDataItemValueUpdate(postCallFieldMapItem.valueToUpdate, 
                    /* undoClicked= */ false);
                }),
            },
            {
                label: "Append",
                value: "APPEND",
                applyLambda: (postCallFieldMapItem, onDataItemValueUpdate) => __awaiter(void 0, void 0, void 0, function* () {
                    var _b;
                    const today = new Date();
                    yield onDataItemValueUpdate(`${today.toDateString()}\n--\n${postCallFieldMapItem.valueToUpdate}\n\n${(_b = postCallFieldMapItem.currentValue) !== null && _b !== void 0 ? _b : ""}`, 
                    /* undoClicked= */ false);
                }),
            },
        ],
    ],
    [
        PostCallOutputTarget.WISER_CRM,
        [
            {
                label: "Overwrite",
                value: "OVERWRITE",
                applyLambda: (postCallFieldMapItem, onDataItemValueUpdate) => __awaiter(void 0, void 0, void 0, function* () {
                    yield onDataItemValueUpdate(postCallFieldMapItem.valueToUpdate, 
                    /* undoClicked= */ false);
                }),
            },
            {
                label: "Append",
                value: "APPEND",
                applyLambda: (postCallFieldMapItem, onDataItemValueUpdate) => __awaiter(void 0, void 0, void 0, function* () {
                    var _c;
                    const today = new Date();
                    yield onDataItemValueUpdate(`${today.toDateString()}\n--\n${postCallFieldMapItem.valueToUpdate}\n\n${(_c = postCallFieldMapItem.currentValue) !== null && _c !== void 0 ? _c : ""}`, 
                    /* undoClicked= */ false);
                }),
            },
        ],
    ],
]);
/**
 * The details of the integrations that are available for post-call operations.
 * The key is the name of the integration in paragon.
 */
const INTEGRATION_DETAILS = new Map([
    [
        "Salesforce",
        {
            name: PostCallOutputTarget.SALESFORCE,
            icon_url: "https://storage.googleapis.com/wiser-ai-public2/salesforce_logo_color.png",
        },
    ],
    [
        "Slack",
        {
            name: PostCallOutputTarget.SLACK,
            icon_url: "https://storage.googleapis.com/wiser-ai-public2/slack_logo_color.png",
        },
    ],
    [
        "Wiser CRM",
        {
            name: PostCallOutputTarget.WISER_CRM,
            icon_url: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png",
        },
    ],
    [
        "Gmail",
        {
            name: PostCallOutputTarget.GMAIL,
            icon_url: "https://storage.googleapis.com/wiser-ai-public2/gmail_logo.png",
        },
    ],
    [
        "HubSpot",
        {
            name: PostCallOutputTarget.HUBSPOT,
            icon_url: "https://cdn.useparagon.com/3.9.2/dashboard/public/integrations/hubspot.svg",
        },
    ],
]);
const DEFAULT_FIELD_SCHEMA = {
    type: "Text",
    restrictions: {
        length: 10000,
    },
};
const INTERNAL_MESSAGING_TARGETS = [PostCallOutputTarget.SLACK];
const CRM_DATA_UPDATE_TARGETS = [
    PostCallOutputTarget.SALESFORCE,
    PostCallOutputTarget.WISER_CRM,
    PostCallOutputTarget.HUBSPOT,
];
export { UPDATE_TYPES, SALESFORCE_OBJECT_TYPES, HUBSPOT_OBJECT_TYPES, INTEGRATION_DETAILS, WISER_CRM_OBJECT_TYPES, DEFAULT_FIELD_SCHEMA, INTERNAL_MESSAGING_TARGETS, CRM_DATA_UPDATE_TARGETS, };
