import { CallState } from "../types";
export const SORTABLE_FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "call_type",
        name: "Call type",
        type: "select",
    },
    {
        id: "call_time",
        name: "Call time",
        type: "relative_date",
    },
    {
        id: "post_call_crm_updates_mapped",
        name: "Post call CRM updates mapped",
        type: "number",
    },
    {
        id: "post_call_crm_updates_pushed",
        name: "Post call CRM updates pushed",
        type: "number",
    },
    {
        id: "call_duration_minutes",
        name: "Call duration (minutes)",
        type: "number",
    },
];
export const FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "call_type",
        name: "Call type",
        type: "select",
        options: ["External", "Internal"],
    },
    {
        id: "wiser_joined",
        name: "Wiser joined",
        type: "boolean",
    },
    {
        id: "call_time",
        name: "Call time",
        type: "date",
        options: [
            "Today",
            "Yesterday",
            "Tomorrow",
            "Next week",
            "Last week",
            "Last 2 weeks",
            "Last month",
            "Last year",
        ],
    },
    // TODO - Find a solution to move this to unstructured filters - https://getwiser.atlassian.net/browse/WISER-1788
    // {
    //   id: "transcripts",
    //   name: "Transcripts",
    //   type: "string",
    // },
    {
        id: "account_id",
        name: "Account",
        type: "reference",
    },
    {
        id: "owner_id",
        name: "User",
        type: "reference",
    },
    {
        id: "opportunity_id",
        name: "Opportunity",
        type: "reference",
    },
    {
        id: "call_source",
        name: "Call source",
        type: "select",
        options: [
            "Uploaded from Gong",
            "Zoom",
            "Local Audio",
            "Google Meet",
            "Microsoft Teams Meeting",
        ],
    },
    {
        id: "call_state",
        name: "Call state",
        type: "select",
        options: Object.values(CallState),
    },
    {
        id: "post_call_crm_updates_mapped",
        name: "Post call CRM updates mapped",
        type: "number",
    },
    {
        id: "post_call_crm_updates_pushed",
        name: "Post call CRM updates pushed",
        type: "number",
    },
    {
        id: "call_duration_minutes",
        name: "Call duration (minutes)",
        type: "number",
    },
    {
        id: "manager_ids",
        name: "Manager",
        type: "array_reference",
    },
    {
        id: "department_ids",
        name: "Department",
        type: "array_reference",
    },
    {
        id: "team_ids",
        name: "Team",
        type: "array_reference",
    },
];
