import { useCallback, useMemo } from "react";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../common/query_builder/search_params_utils";
import { updateSearchParams } from "../common/table_utils";
import { accountsFetcher, contactsFetcher, } from "../calls_table/calls_table_fetchers";
import { CrmObjectTypeEnum, } from "../types";
import { templateFetcherWithoutNone } from "../teams/team.helpers";
import { departmentsForFilter, teamsForFilter, tenantUsersForFilter, } from "./object_fetchers";
export function useFilterConfig(FIELDS) {
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            ["contact_id", contactsFetcher],
            ["default_internal_meeting_template_id", templateFetcherWithoutNone],
            ["default_external_meeting_template_id", templateFetcherWithoutNone],
            ["owner_id", tenantUsersForFilter],
            ["manager_ids", tenantUsersForFilter],
            ["team_ids", teamsForFilter],
            ["department_ids", departmentsForFilter],
        ]);
    }, []);
    const filterConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxSubConditions: 5,
            referenceObjectFetcherMap,
        };
    }, [FIELDS]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    return { filterConfig, wiserBackendQueryConverter };
}
export function useInitialFilter(searchParams, wiserBackendQueryConverter) {
    return useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams, wiserBackendQueryConverter]);
}
export function useFilterChange(navigate, searchParams) {
    return useCallback((updatedFilter) => {
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        navigate(`?${updateSearchParams([
            {
                key: "filter",
                value: filterParam,
            },
        ], searchParams)}`);
    }, [navigate, searchParams]);
}
export function useBuildFieldsForFilter(crmFields, fields, table) {
    return useMemo(() => {
        const buildFields = (crmField, objectType) => {
            const field = {
                id: `${objectType.toLowerCase()}_fields__z${crmField.id}`,
                name: crmField.name.startsWith(objectType)
                    ? crmField.name
                    : `${objectType}: ${crmField.name}`,
            };
            switch (crmField.content_type_definition.type) {
                case "text":
                    field.type = "string";
                    break;
                case "number":
                case "boolean":
                    field.type = crmField.content_type_definition.type;
                    break;
                case "date":
                    field.type = "datestring";
                    break;
                case "single_select":
                case "multi_select":
                    field.type = "select";
                    field.options = crmField.content_type_definition.restrictions.picklist_values;
                    break;
                default:
                    return null;
            }
            return field;
        };
        const accountFields = [];
        const opportunityFields = [];
        const contactFields = [];
        crmFields.forEach((crmField) => {
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.ACCOUNT)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.ACCOUNT);
                if (field)
                    accountFields.push(field);
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.OPPORTUNITY)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.OPPORTUNITY);
                if (field)
                    opportunityFields.push(field);
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.CONTACT)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.CONTACT);
                if (field)
                    contactFields.push(field);
            }
        });
        switch (table) {
            case "calls":
                return [...fields, ...accountFields, ...opportunityFields];
            case CrmObjectTypeEnum.ACCOUNT:
                return [...fields, ...accountFields];
            case CrmObjectTypeEnum.OPPORTUNITY:
                return [...fields, ...opportunityFields, ...accountFields];
            case CrmObjectTypeEnum.CONTACT:
            default:
                return [...fields, ...contactFields, ...accountFields];
        }
    }, [crmFields, fields]);
}
