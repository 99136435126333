import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getLiveCallAndNotes } from "../common/endpoints";
import CallDetails from "./call_details";
import CallHeadingV3 from "./call_heading_v3";
import PostCallPage from "../post_call/post_call_v3";
const CallPage = (props) => {
    const { callIdParam } = useParams();
    const callId = Number(callIdParam);
    const queryClient = useQueryClient();
    const { data: callAndNotes, isLoading } = useQuery({
        queryKey: ["liveCall", callId],
        queryFn: () => {
            if (!callId)
                return null;
            return getLiveCallAndNotes(callId);
        },
    });
    const call = callAndNotes === null || callAndNotes === void 0 ? void 0 : callAndNotes.call;
    const isCallEnded = useMemo(() => {
        if (!call)
            return false;
        const nowTime = new Date().getTime();
        const callEndTime = new Date(call.call_end_time).getTime();
        return call.call_end_time && nowTime > callEndTime;
    }, [call]);
    if (isLoading || !call) {
        return null;
    }
    if (isCallEnded) {
        return (_jsx(PostCallPage, { onClose: props.onClose, updateSelectedCall: props.updateSelectedCall }));
    }
    const updateSelectedCall = (updatedCallAndNotes) => {
        queryClient.setQueryData(["liveCall", updatedCallAndNotes.call.id], updatedCallAndNotes);
        if (props.updateSelectedCall) {
            props.updateSelectedCall(call);
        }
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col h-screen w-full font-['Lato']" }, { children: [_jsx(CallHeadingV3, { call: call, onClose: props.onClose, updateSelectedCall: (updatedCall) => {
                    updateSelectedCall({ call: updatedCall, notes: callAndNotes === null || callAndNotes === void 0 ? void 0 : callAndNotes.notes });
                } }), _jsx(CallDetails, { call: call, notes: callAndNotes === null || callAndNotes === void 0 ? void 0 : callAndNotes.notes, updateSelectedCall: updateSelectedCall, fetchUpdatedCalls: () => {
                    queryClient.invalidateQueries("liveCalls");
                }, showChatModal: false, showChatBox: true })] })));
};
export default CallPage;
