var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { departmentsFetcher, teamsFetcher, usersFetcher, } from "../calls_table/calls_table_fetchers";
export const tenantUsersForFilter = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const { results, next_cursor } = yield usersFetcher(userQuery, ids, cursor, queryClient);
    // We fetch by ids to restore the filter values in the UI after the user
    // saves the filter. Essentially if the user applied the filter:
    // User in [a, b, c] and saves, the only values in the query that get
    // saved are the ids of a, b and c. And hence to render the name (labels)
    // of those ids in the UI when the user revisits the page, we first fetch
    // the Users by ids using this API.
    // For self, we only need to add it in the result if the user saved the filter
    // with -99 (special id for self).
    if (!ids || ids.includes(-99)) {
        results.unshift({
            value: "-99",
            label: "Self",
        });
    }
    return {
        results: results.filter((result) => result.label.toLowerCase().includes(userQuery.toLowerCase())),
        next_cursor,
    };
});
export const teamsForFilter = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const { results, next_cursor } = yield teamsFetcher(userQuery, ids, cursor, queryClient);
    if (!ids || ids.includes(-99)) {
        results.unshift({
            value: "-99",
            label: "My Team",
        });
    }
    return {
        results: results.filter((result) => result.label.toLowerCase().includes(userQuery.toLowerCase())),
        next_cursor,
    };
});
export const departmentsForFilter = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const { results, next_cursor } = yield departmentsFetcher(userQuery, ids, cursor, queryClient);
    if (!ids || ids.includes(-99)) {
        results.unshift({
            value: "-99",
            label: "My Department",
        });
    }
    return {
        results: results.filter((result) => result.label.toLowerCase().includes(userQuery.toLowerCase())),
        next_cursor,
    };
});
