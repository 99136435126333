import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "./button";
import { ViewColumnsIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "./command";
import classNames from "../class_names";
import { Checkbox } from "./checkbox";
/**
 * Updated component using the Command component. This allows for searching and filtering.
 *  * @example
 * ```
 * <DataTableViewOptions table={table} />
 * ```
 */
export function DataTableViewOptions({ table, columnIdMap, }) {
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", className: classNames("h-8 text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6", "hover:bg-gray-200 rounded-lg", table.getVisibleLeafColumns().length > 0
                        ? "text-wds-blue-3 border-wds-blue-3 font-bold"
                        : "") }, { children: [_jsx(ViewColumnsIcon, { className: "mr-2 h-4 w-4" }), "Columns", table.getVisibleLeafColumns().length > 0 && (_jsx("div", Object.assign({ className: "ml-2 bg-wds-blue-3 text-white rounded-full pl-2 pr-2" }, { children: table.getVisibleLeafColumns().length })))] })) })), _jsx(PopoverContent, Object.assign({ className: "w-[12.5rem] p-0", align: "start" }, { children: _jsxs(Command, { children: [_jsx(CommandInput, { placeholder: "Filter" }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsx(CommandGroup, { children: table
                                        .getAllColumns()
                                        .sort((a, b) => {
                                        var _a, _b;
                                        // Sort by visibility first (visible columns come first)
                                        const aVisible = a.getIsVisible();
                                        const bVisible = b.getIsVisible();
                                        if (aVisible !== bVisible) {
                                            return bVisible ? 1 : -1;
                                        }
                                        // Then sort by name/header
                                        const aName = typeof a.columnDef.header === "string"
                                            ? a.columnDef.header
                                            : ((_a = columnIdMap === null || columnIdMap === void 0 ? void 0 : columnIdMap[a.id]) !== null && _a !== void 0 ? _a : a.id);
                                        const bName = typeof b.columnDef.header === "string"
                                            ? b.columnDef.header
                                            : ((_b = columnIdMap === null || columnIdMap === void 0 ? void 0 : columnIdMap[b.id]) !== null && _b !== void 0 ? _b : b.id);
                                        return aName.localeCompare(bName);
                                    })
                                        .map((column) => {
                                        var _a;
                                        const isSelected = column.getIsVisible();
                                        const columnName = typeof column.columnDef.header === "string"
                                            ? column.columnDef.header
                                            : ((_a = columnIdMap === null || columnIdMap === void 0 ? void 0 : columnIdMap[column.id]) !== null && _a !== void 0 ? _a : column.id);
                                        return (_jsxs(CommandItem, Object.assign({ onSelect: () => {
                                                column.toggleVisibility(!isSelected);
                                            }, className: "gap-2", value: columnName }, { children: [_jsx(Checkbox, { checked: isSelected, id: column.id, disabled: !column.getCanHide(), className: "shrink-0" }), _jsx("span", { children: columnName })] }), column.id));
                                    }) })] })] }) }))] }));
}
