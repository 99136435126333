import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CrmObjectTypeEnum } from "../types";
import classNames from "../class_names";
import CallCrmDetailsPopover, { NoCrmObjectMappedPopoverPanel, classesForPopover, classesForPopoverSvg, } from "./call_crm_details_popover";
import { PencilIcon } from "@heroicons/react/24/outline";
import CallCrmOpportunityModal from "./call_crm_opportunity_modal";
import { useMemo, useState } from "react";
import { useAppIntegrationData } from "./use_app_integration_data";
import { getExternalCrmObjectLink } from "../crm/common/external_crm_link";
import { getConnectedCrmLogo } from "./crm_logo";
import { Link } from "react-router-dom";
import { Button } from "../components/button";
import { getOpportunityLabel } from "./crm_utils";
const OpportunityDetails = ({ tenant, call, setCallCrmOpportunityModalOpen, }) => {
    const { appIntegrationData } = useAppIntegrationData(tenant.id);
    const opportunityLabel = getOpportunityLabel(tenant.connected_crm);
    const externalCrmObjectLink = useMemo(() => {
        var _a;
        if (!tenant.connected_crm || !((_a = call.opportunity) === null || _a === void 0 ? void 0 : _a.external_id)) {
            return undefined;
        }
        return getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], CrmObjectTypeEnum.OPPORTUNITY, call.opportunity.external_id);
    }, [appIntegrationData, tenant, call]);
    const renderEditButton = () => {
        return (_jsx("button", Object.assign({ onClick: (e) => {
                setCallCrmOpportunityModalOpen(true);
                e.stopPropagation();
            }, className: "flex w-8 h-8 justify-center items-center rounded-full" }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) })));
    };
    return (_jsx(_Fragment, { children: call.opportunity ? (_jsxs("div", Object.assign({ className: "flex flex-col gap-2 max-w-sm" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-base font-medium text-black" }, { children: call.opportunity.opportunity_name })), renderEditButton()] })), externalCrmObjectLink && (_jsxs("div", Object.assign({ className: "flex items-center gap-3 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("img", { className: "h-4 w-4", src: getConnectedCrmLogo(tenant.connected_crm) }) })), _jsx(Link, Object.assign({ to: externalCrmObjectLink, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "text-wds-blue-3 h-auto p-0 font-medium" }, { children: `Open in ${tenant.connected_crm}` })) }))] })))] }))) : (_jsx(NoCrmObjectMappedPopoverPanel, { title: `No ${opportunityLabel} mapped`, editButtonClickHandler: () => setCallCrmOpportunityModalOpen(true) })) }));
};
const OpportunityPopover = (props) => {
    // Boolean to find if the opportunity exists in user's CRM.
    // If the user is connected to an external CRM,
    // we check if the external_id of the opportunity is populated.
    // If the user is not connected to external CRM, presence of an opportunity is
    // necessary and sufficient condition.
    const opportunityExistsInCrm = !!props.call.opportunity &&
        (!props.tenant.connected_crm || !!props.call.opportunity.external_id);
    const [callCrmOpportunityModalOpen, setCallCrmOpportunityModalOpen] = useState(false);
    const opportunityLabel = getOpportunityLabel(props.tenant.connected_crm);
    return (_jsxs(_Fragment, { children: [_jsx(CallCrmDetailsPopover, { button: _jsxs("div", Object.assign({ className: classNames("flex py-[2px] px-2 gap-2 items-center rounded-lg border", classesForPopover(opportunityExistsInCrm, props.variant), props.className) }, { children: [_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", className: classesForPopoverSvg(opportunityExistsInCrm, props.variant) }, { children: _jsx("g", Object.assign({ id: "Crown" }, { children: _jsx("path", { id: "Crown_2", fillRule: "evenodd", clipRule: "evenodd", d: "M8.68538 1.6954C8.56501 1.42455 8.29642 1.25 8.00002 1.25C7.70363 1.25 7.43504 1.42455 7.31467 1.6954L4.9923 6.92072L2.3859 5.35688C2.13509 5.2064 1.81969 5.21545 1.57793 5.38005C1.33616 5.54466 1.21215 5.8348 1.26023 6.1233L2.4543 13.2877C2.59493 14.1315 3.32502 14.75 4.18048 14.75H11.8196C12.675 14.75 13.4051 14.1315 13.5458 13.2877L14.7398 6.1233C14.7879 5.8348 14.6639 5.54466 14.4221 5.38005C14.1804 5.21545 13.865 5.2064 13.6142 5.35688L11.0077 6.92072L8.68538 1.6954ZM6.01872 8.3046L8.00002 3.84666L9.98133 8.3046C10.0686 8.50093 10.2358 8.65041 10.4407 8.71514C10.6456 8.77988 10.8683 8.75365 11.0526 8.64312L12.9932 7.47872L12.0662 13.0411C12.0461 13.1616 11.9418 13.25 11.8196 13.25H4.18048C4.05828 13.25 3.95398 13.1616 3.93389 13.0411L3.00682 7.47872L4.94749 8.64312C5.13171 8.75365 5.35449 8.77988 5.55935 8.71514C5.7642 8.65041 5.93146 8.50093 6.01872 8.3046ZM5.33336 11.25C4.91914 11.25 4.58336 11.5858 4.58336 12C4.58336 12.4142 4.91914 12.75 5.33336 12.75H10.6667C11.0809 12.75 11.4167 12.4142 11.4167 12C11.4167 11.5858 11.0809 11.25 10.6667 11.25H5.33336Z" }) })) })), _jsx("div", Object.assign({ className: "font-normal leading-snug truncate text-ellipsis" }, { children: props.call.opportunity
                                ? props.call.opportunity.opportunity_name
                                : `No ${opportunityLabel}` }))] })), panel: _jsx(OpportunityDetails, { tenant: props.tenant, call: props.call, setCallCrmOpportunityModalOpen: setCallCrmOpportunityModalOpen }), stopPropagation: props.stopPropagation }), callCrmOpportunityModalOpen && (_jsx(CallCrmOpportunityModal, { tenant: props.tenant, call: props.call, open: callCrmOpportunityModalOpen, setOpen: setCallCrmOpportunityModalOpen, updateSelectedCall: props.updateSelectedCall }))] }));
};
export default OpportunityPopover;
