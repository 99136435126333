var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowDownCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./components/button";
import { useEffect, useState } from "react";
import { Input } from "./components/input";
import { AsyncSearchMultiSelect, } from "./components/async_search_multi_select";
import { createTag, tagsDataFetcher } from "./common/endpoints";
import useFeatureFlagEnabled from "./use_feature_flag_enabled";
import { useMutation } from "react-query";
const TimeInput = ({ msecs, updateMsecs, validateMsecs, }) => {
    const convertMillisecondsToTime = (msecs) => {
        const seconds = Math.floor(msecs / 1000);
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return { minutes, seconds: secs };
    };
    const convertTimeToMilliseconds = (minutes, seconds) => (minutes * 60 + seconds) * 1000;
    const handleInputChange = (value, setState) => {
        const sanitizedValue = value
            .replace(/[^0-9]/g, "")
            .padStart(2, "0")
            .slice(-2);
        setState(sanitizedValue);
    };
    const { minutes, seconds } = convertMillisecondsToTime(msecs);
    const [minutesDisplay, setMinutesDisplay] = useState(minutes.toString().padStart(2, "0"));
    const [secondsDisplay, setSecondsDisplay] = useState(seconds.toString().padStart(2, "0"));
    const handleBlur = () => {
        const newMsecs = convertTimeToMilliseconds(parseInt(minutesDisplay, 10), parseInt(secondsDisplay, 10));
        if (validateMsecs(newMsecs)) {
            updateMsecs(newMsecs);
        }
        else {
            const { minutes, seconds } = convertMillisecondsToTime(msecs);
            setMinutesDisplay(minutes.toString().padStart(2, "0"));
            setSecondsDisplay(seconds.toString().padStart(2, "0"));
        }
    };
    useEffect(() => {
        const { minutes, seconds } = convertMillisecondsToTime(msecs);
        setMinutesDisplay(minutes.toString().padStart(2, "0"));
        setSecondsDisplay(seconds.toString().padStart(2, "0"));
    }, [msecs]);
    return (_jsxs("div", Object.assign({ className: "h-8 px-2 bg-wds-gray-1 rounded-lg border border-wds-gray-3 flex items-center" }, { children: [_jsx("input", { type: "text", value: minutesDisplay, onChange: (e) => handleInputChange(e.target.value, setMinutesDisplay), onBlur: handleBlur, className: "w-12 bg-transparent text-right leading-tight border-none focus:outline-none focus:ring-0" }), _jsx("span", Object.assign({ className: "px-1" }, { children: ":" })), _jsx("input", { type: "text", value: secondsDisplay, onChange: (e) => handleInputChange(e.target.value, setSecondsDisplay), onBlur: handleBlur, className: "w-12 bg-transparent leading-tight border-none focus:outline-none focus:ring-0" })] })));
};
const ClipVideoPopoverContent = (props) => {
    const { startTimeMsecs, endTimeMsecs, setStartTimeMsecs, setEndTimeMsecs, validateStartTimeMsecs, validateEndTimeMsecs, onSave, onCancel, } = props;
    const [saving, setSaving] = useState(false);
    const [clipTitle, setClipTitle] = useState("");
    const [tags, setTags] = useState([]);
    const tagsFeatureEnabled = useFeatureFlagEnabled("CLIP_TAGS");
    const createTagMutation = useMutation({
        mutationFn: createTag,
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "px-4 py-2 flex flex-col gap-1 w-full" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 leading-none" }, { children: "Clip title" })), _jsx(Input, { value: clipTitle, onChange: (e) => setClipTitle(e.target.value), className: "w-full" })] })), _jsxs("div", Object.assign({ className: "px-4 py-2 flex flex-col gap-1 w-full" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 leading-none" }, { children: "Time of clip" })), _jsxs("div", Object.assign({ className: "flex items-center gap-1" }, { children: [_jsx(TimeInput, { msecs: startTimeMsecs, updateMsecs: setStartTimeMsecs, validateMsecs: validateStartTimeMsecs }), _jsx("span", Object.assign({ className: "leading-tight" }, { children: "-" })), _jsx(TimeInput, { msecs: endTimeMsecs, updateMsecs: setEndTimeMsecs, validateMsecs: validateEndTimeMsecs })] }))] })), tagsFeatureEnabled && (_jsx("div", Object.assign({ className: "px-4 py-2 flex flex-col gap-1 w-full" }, { children: _jsx(AsyncSearchMultiSelect, { dataFetcher: (query, _ids, _cursor, queryClient) => {
                        // queryClient always exists, typing is wrong
                        return queryClient.fetchQuery({
                            queryKey: ["getTags", query],
                            queryFn: () => tagsDataFetcher(query),
                        });
                    }, onChange: (value) => setTags(value.map((tag) => tag.value)), selectedOptions: tags.map((tag) => ({
                        value: tag,
                        label: tag,
                    })), placeholder: "Add tags", className: "w-full", onCreate: (newOptionLabel) => __awaiter(void 0, void 0, void 0, function* () {
                        yield createTagMutation.mutateAsync(newOptionLabel);
                        setTags([...tags, newOptionLabel]);
                    }) }) }))), _jsxs("div", Object.assign({ className: "flex items-center gap-2 px-4 py-2 w-full" }, { children: [_jsxs(Button, Object.assign({ variant: "secondary", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setSaving(true);
                            yield onSave(clipTitle, tags);
                            setSaving(false);
                        }), disabled: saving }, { children: [_jsx(ArrowDownCircleIcon, { className: "w-5 h-5" }), saving ? "Saving... may take up to a minute" : "Save clip"] })), _jsxs(Button, Object.assign({ variant: "secondary", onClick: onCancel, disabled: saving }, { children: [_jsx(XMarkIcon, { className: "w-5 h-5" }), "Cancel"] }))] }))] }));
};
export default ClipVideoPopoverContent;
