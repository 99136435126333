var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "../class_names";
import { CrmObjectTypeEnum, } from "../types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useEffect, useMemo, useState } from "react";
import Dropdown from "../common/dropdown";
import { WISER_CRM_OBJECT_TYPES } from "./data_processing_config";
import FieldSchemaRenderer from "./field_schema_renderer";
import { useNavigate } from "react-router-dom";
import { convertWiserCrmFieldSchemaToPostCallOutputFieldSchema } from "./utils";
import { SearchableDropdown } from "../common/searchable_dropdown";
import { useQuery, useQueryClient } from "react-query";
import { getCrmAccount, getCrmContact, getCrmOpportunity, queryCrmObjects, } from "../common/endpoints";
import { AsyncSearchSelect } from "../components/async_search_select";
export const WiserCrmTargetItem = ({ targetDetails, crmFields, }) => {
    var _a;
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const fetchCrmObjectName = () => __awaiter(void 0, void 0, void 0, function* () {
        switch (targetDetails.objectType) {
            case "Account":
                const crmAccount = yield queryClient.fetchQuery({
                    queryFn: () => getCrmAccount(targetDetails.objectId),
                });
                return crmAccount.name;
            case "Contact":
                const crmContact = yield queryClient.fetchQuery({
                    queryFn: () => getCrmContact(targetDetails.objectId),
                });
                return crmContact.name;
            case "Opportunity":
                const crmOpportunity = yield queryClient.fetchQuery({
                    queryFn: () => getCrmOpportunity(targetDetails.objectId),
                });
                return crmOpportunity.name;
        }
    });
    const { data: crmObjectName } = useQuery({
        queryKey: ["crmObject", targetDetails.objectType, targetDetails.objectId],
        queryFn: fetchCrmObjectName,
    });
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: [_jsxs("button", Object.assign({ className: classNames("flex items-center self-stretch gap-2", "group", "text-xs font-medium text-gray-600", "hover:outline-none hover:cursor-pointer hover:text-blue-600"), onClick: () => {
                            navigate("/crm/home/");
                        } }, { children: [crmObjectName !== null && crmObjectName !== void 0 ? crmObjectName : `Unknown ${targetDetails.objectType}`, _jsx(ArrowTopRightOnSquareIcon, { className: "w-4 h-4 opacity-0 group-hover:opacity-100" })] })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-black" }, { children: crmFields
                            ? (_a = crmFields[targetDetails.objectType].find((field) => field.id === targetDetails.fieldId)) === null || _a === void 0 ? void 0 : _a.label
                            : "Unknown field" }))] }))] }));
};
export const WiserCrmDestinationSelector = ({ call, selectedTarget, crmFields, onTargetDetailsChange, }) => {
    var _a, _b, _c, _d;
    const [selectedObjectType, setSelectedObjectType] = useState((_a = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectType) !== null && _a !== void 0 ? _a : CrmObjectTypeEnum.OPPORTUNITY);
    const [selectedObject, setSelectedObject] = useState({
        objectId: (_b = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectId) !== null && _b !== void 0 ? _b : 0,
        objectName: (_c = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectName) !== null && _c !== void 0 ? _c : "Unknown object",
    });
    const [selectedFieldId, setSelectedFieldId] = useState((_d = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.fieldId) !== null && _d !== void 0 ? _d : 0);
    // This is required because we don't store the name of the CRM object
    // along with the post-call output target details.
    const queryClient = useQueryClient();
    const fetchCrmObjectName = () => __awaiter(void 0, void 0, void 0, function* () {
        switch (selectedObjectType) {
            case "Account":
                const crmAccount = yield queryClient.fetchQuery({
                    queryFn: () => getCrmAccount(selectedObject.objectId),
                });
                return crmAccount.name;
            case "Contact":
                const crmContact = yield queryClient.fetchQuery({
                    queryFn: () => getCrmContact(selectedObject.objectId),
                });
                return crmContact.name;
            case "Opportunity":
                const crmOpportunity = yield queryClient.fetchQuery({
                    queryFn: () => getCrmOpportunity(selectedObject.objectId),
                });
                return crmOpportunity.name;
        }
    });
    useQuery({
        queryKey: ["crmObject", selectedObjectType, selectedObject.objectId],
        queryFn: fetchCrmObjectName,
        onSuccess: (crmObjectName) => {
            setSelectedObject((prev) => {
                return {
                    objectId: prev.objectId,
                    objectName: crmObjectName,
                };
            });
        },
    });
    useEffect(() => {
        var _a;
        if ((selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectType) === selectedObjectType) {
            setSelectedObject({
                objectId: selectedTarget.objectId,
                objectName: (_a = selectedTarget.objectName) !== null && _a !== void 0 ? _a : "Unknown object",
            });
            setSelectedFieldId(selectedTarget.fieldId);
            return;
        }
        if (crmFields[selectedObjectType].length > 0) {
            setSelectedFieldId(crmFields[selectedObjectType][0].id);
        }
    }, [selectedObjectType, call]);
    const selectedCrmField = useMemo(() => {
        return crmFields[selectedObjectType].find((field) => field.id === selectedFieldId);
    }, [selectedFieldId, crmFields, selectedObjectType]);
    const validTargetDetails = () => {
        return selectedObject.objectId !== 0 && selectedFieldId !== 0;
    };
    useEffect(() => {
        if (!validTargetDetails()) {
            return;
        }
        onTargetDetailsChange({
            objectType: selectedObjectType,
            objectId: selectedObject.objectId,
            fieldId: selectedFieldId,
        });
    }, [selectedObjectType, selectedObject, selectedFieldId]);
    // This method is used to continuously fetch Wiser CRM objects for the
    // selectedObjectType based on the user query and cursor.
    const wiserCrmObjectsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const crmQueryResponse = yield queryClient.fetchQuery({
            queryKey: ["wiserCrmObjects", selectedObjectType],
            queryFn: () => queryCrmObjects(selectedObjectType, 
            /* fieldToQuery= */ "name", userQuery, cursor),
        });
        return {
            results: crmQueryResponse.results.map((obj) => {
                return { label: obj.name, value: obj.id };
            }),
            next_cursor: crmQueryResponse.next_cursor,
        };
    });
    return (_jsxs("div", Object.assign({ className: "w-96 flex flex-col flex-grow items-start self-stretch gap-4" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-base" }, { children: "Select a destination" })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Object" })), _jsx(Dropdown, { options: WISER_CRM_OBJECT_TYPES.map((obj) => ({
                            value: obj,
                            label: obj,
                        })), defaultSelected: selectedObjectType, onOptionSelect: (option) => {
                            setSelectedObjectType(option);
                        }, buttonClasses: "bg-white rounded-lg" })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: `${selectedObjectType} name` })), _jsx(AsyncSearchSelect, { id: selectedObjectType, dataFetcher: wiserCrmObjectsFetcher, selectedOption: selectedObject && selectedObject.objectId !== 0
                            ? {
                                value: `${selectedObject.objectId}`,
                                label: selectedObject.objectName,
                            }
                            : undefined, onSelect: (option) => {
                            if (!option)
                                return;
                            // The Dropdown component only has support for string values, hence we convert
                            // the selectedId back to a number here.
                            setSelectedObject({
                                objectId: parseInt(option.value),
                                objectName: option.label,
                            });
                        }, placeholder: `Search ${selectedObjectType}`, buttonClasses: "bg-white rounded-lg" })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Field" })), _jsx(SearchableDropdown, { options: crmFields[selectedObjectType]
                            .map((field) => ({
                            value: `${field.id}`,
                            label: field.label,
                        }))
                            .sort((a, b) => a.label.localeCompare(b.label)), selectedOption: crmFields[selectedObjectType].find((field) => field.id === selectedFieldId)
                            ? {
                                value: `${selectedFieldId}`,
                                label: crmFields[selectedObjectType].find((field) => field.id === selectedFieldId).label,
                            }
                            : undefined, onSelect: (option) => {
                            if (!option)
                                return;
                            // The Dropdown component only has support for string values, hence we convert
                            // the selectedId back to a number here.
                            setSelectedFieldId(parseInt(option.value));
                        }, placeholder: "Select CRM field", buttonClasses: "bg-white rounded-lg" }), selectedCrmField && (_jsx(FieldSchemaRenderer, { id: `${selectedCrmField.id}`, fieldSchema: convertWiserCrmFieldSchemaToPostCallOutputFieldSchema(selectedCrmField.schema) }))] }))] })));
};
