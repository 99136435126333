var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from "react";
import { getZoomTestIntegrationData, paragonIntegrationsUpdateCallback, setZoomTestAccessCode, } from "../common/endpoints";
import useParagonAuth from "../use_paragon_auth";
import { integrationListingContent } from "./constants";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import useCalendarIntegrations from "./use_calendar_integrations";
import useGongIntegrations from "./use_gong_integrations";
import useGmailIntegrations from "./use_gmail_integrations";
/**
 * Fetches & returns the data needed to load all the integration tiles for the integrations page
 * Make sure paragon & non paragon integrations load together so we can show them at once & show a single spinner while things are loading
 * Even though data is coming from different places, this hook gives an illusion that it is coming from a single place.
 */
const useIntegrations = () => {
    const [integrations, setIntegrations] = useState([]);
    const [zoomTestIntegrationData, setZoomTestIntegrationData] = useState(null);
    const zoomIntegrationTestEnabled = useFeatureFlagEnabled("ZOOM_INTEGRATION_TEST");
    const gongSyncEnabled = useFeatureFlagEnabled("GONG_SYNC");
    const { integrations: calendarIntegrationData, loaded: calendarDataLoaded } = useCalendarIntegrations();
    const { integrations: gmailIntegrationData, loaded: gmailDataLoaded } = useGmailIntegrations();
    const { user, paragon, paragonUserLoaded } = useParagonAuth();
    const { integrations: gongIntegrationData, loaded: gongDataLoaded } = useGongIntegrations();
    useEffect(() => {
        // HACK to auto-correct integration statuses in BE everytime the
        // user lands on integrations page in the app.
        // Sometimes the integration update callbacks are missed from paragon
        // because of which BE doesn't get to know about the latest integration
        // connection status.
        if (user) {
            paragonIntegrationsUpdateCallback(user);
        }
    }, [user]);
    const [zoomDataLoaded, setZoomDataLoaded] = useState(false);
    const getConnectionStatusForParagonIntegration = (integration) => {
        var _a;
        if (!user || !user.authenticated) {
            return "Not connected";
        }
        const { enabled, credentialStatus } = ((_a = user === null || user === void 0 ? void 0 : user.integrations) === null || _a === void 0 ? void 0 : _a[integration.type]) || {};
        if (!enabled) {
            return "Not connected";
        }
        if (credentialStatus !== "VALID") {
            return "Needs reconnection";
        }
        return "Connected";
    };
    useEffect(() => {
        if (!zoomIntegrationTestEnabled) {
            setZoomDataLoaded(true);
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code) {
            // Then we just got back here after authorizing with zoom and need to consume the provided code.
            setZoomTestAccessCode(code).then(() => {
                // Clears the code= param.
                window.location.href =
                    window.location.origin + window.location.pathname;
            });
        }
        fetchZoomTestIntegrationData();
    }, [zoomIntegrationTestEnabled]);
    const fetchZoomTestIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield getZoomTestIntegrationData();
        setZoomTestIntegrationData(data);
        setZoomDataLoaded(true);
    });
    const connectZoomIntegrationTestApp = () => __awaiter(void 0, void 0, void 0, function* () {
        // This sends the user to zoom, with a redirect url back to this page, but with an added code=<access code> query param.
        const redirect_uri = window.location.origin + window.location.pathname;
        const zoomAuthUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=6eXI0ZLtR8K3MNlRFiaMOg&redirect_uri=" +
            redirect_uri;
        window.location.href = zoomAuthUrl;
    });
    useEffect(() => {
        const everythingLoaded = paragonUserLoaded &&
            zoomDataLoaded &&
            calendarDataLoaded &&
            gmailDataLoaded &&
            gongDataLoaded;
        if (!everythingLoaded) {
            return;
        }
        const paragonIntegrations = paragon === null || paragon === void 0 ? void 0 : paragon.getIntegrationMetadata();
        const formattedIntegrations = [
            ...calendarIntegrationData,
            ...gmailIntegrationData,
            ...(paragonIntegrations
                ? paragonIntegrations.map((integration) => ({
                    name: integration.name,
                    icon: integration.icon,
                    handleConnect: () => __awaiter(void 0, void 0, void 0, function* () { return paragon.installIntegration(integration.type); }),
                    handleDisconnect: () => __awaiter(void 0, void 0, void 0, function* () { return paragon.uninstallIntegration(integration.type); }),
                    status: getConnectionStatusForParagonIntegration(integration),
                    listingContent: integrationListingContent[integration.name],
                }))
                : []),
            ...(zoomTestIntegrationData && zoomIntegrationTestEnabled
                ? [
                    {
                        name: "Zoom Integration",
                        icon: "https://storage.googleapis.com/wiser-ai-public2/zoom-meetings-rgb.png",
                        status: zoomTestIntegrationData.status,
                        handleConnect: connectZoomIntegrationTestApp,
                        handleDisconnect: () => __awaiter(void 0, void 0, void 0, function* () { }),
                    },
                ]
                : []),
            ...(gongSyncEnabled ? gongIntegrationData : []),
        ];
        setIntegrations(formattedIntegrations);
    }, [
        user,
        paragon,
        calendarIntegrationData,
        gmailIntegrationData,
        zoomTestIntegrationData,
        paragonUserLoaded,
        zoomDataLoaded,
        calendarDataLoaded,
        zoomIntegrationTestEnabled,
        gongDataLoaded,
    ]);
    return {
        integrations,
        loaded: zoomDataLoaded &&
            calendarDataLoaded &&
            gmailIntegrationData &&
            paragonUserLoaded &&
            gongDataLoaded,
    };
};
export default useIntegrations;
