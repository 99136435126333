var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { CrmObjectTypeEnum, } from "../types";
import CallCrmObjectBaseModal, { CallCrmObjectLinkForm, CrmInputField, EditableCrmField, } from "./call_crm_object_base_modal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createCrmLead, fetchLeadCrmObject, getCrmFields, getLiveCallById, setCallLeadIds, updateCrmLead, } from "./endpoints";
import { AsyncSearchSelect, } from "../components/async_search_select";
import { accountsFetcher, contactsFetcher, leadsFetcher, opportunitiesFetcher, } from "../calls_table/calls_table_fetchers";
import { getAccountLabel, getOpportunityLabel } from "./crm_utils";
export const CallCrmLeadModal = ({ tenant, call, lead, open, setOpen, updateSelectedCall, setSelectedLeadId, }) => {
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFields", tenant.id],
        queryFn: getCrmFields,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const leadExistsInCrm = !tenant.connected_crm || !!(lead === null || lead === void 0 ? void 0 : lead.external_id);
    const requiredCrmFields = useMemo(() => {
        if (crmFieldsLoading || crmFieldsError)
            return [];
        if (!crmFields)
            return [];
        return crmFields.filter((crmField) => crmField.applicable_objects.includes(CrmObjectTypeEnum.LEAD) &&
            !crmField.nullable);
    }, [crmFieldsLoading, crmFieldsError, crmFields]);
    const crmFieldValues = useMemo(() => {
        const cValues = {};
        requiredCrmFields.map((crmField) => {
            var _a, _b;
            if ((_a = lead === null || lead === void 0 ? void 0 : lead.lead_fields) === null || _a === void 0 ? void 0 : _a[crmField.id]) {
                cValues[crmField.id] = (_b = lead.lead_fields) === null || _b === void 0 ? void 0 : _b[crmField.id];
            }
        });
        return cValues;
    }, [lead, requiredCrmFields]);
    // This is used to retrieve the lead when you switch from new to existing lead
    const [selectedLead, setSelectedLead] = useState();
    const getLeadData = useCallback(() => {
        if (selectedLead)
            return selectedLead;
        if (!lead)
            return undefined;
        return Object.assign(Object.assign({}, lead), { lead_fields: crmFieldValues });
    }, [lead, crmFieldValues]);
    const getBaseLeadData = useCallback(() => {
        return {
            id: 0,
            tenant_id: tenant.id,
            timestamp: new Date(),
            lead_fields: crmFieldValues,
        };
    }, [lead, crmFieldValues]);
    const [updatedLeadData, setUpdatedLeadData] = useState(getLeadData());
    const [isSaving, setIsSaving] = useState(false);
    const [leadSelector, setLeadSelector] = useState((updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.id) ? "EXISTING" : "NEW");
    const [saveErrorMsg, setSaveErrorMsg] = useState(null);
    const fetchLeadMutation = useMutation({
        mutationFn: ({ leadId }) => fetchLeadCrmObject(leadId),
        onSuccess: (props) => {
            setUpdatedLeadData((prev) => {
                return Object.assign(Object.assign(Object.assign({}, prev), props), { id: props.id, tenant_id: tenant.id, timestamp: new Date(), lead_fields: crmFieldValues });
            });
            setSelectedLead(Object.assign(Object.assign({}, props), { lead_fields: crmFieldValues, tenant_id: tenant.id, 
                // Hack: to get typing to work, this timestamp is irrelevant and not displayed in the UI
                timestamp: new Date() }));
        },
    });
    const queryClient = useQueryClient();
    const leadUpdateMutation = useMutation({
        mutationFn: ({ leadId, leadData, }) => {
            var _a;
            return updateCrmLead(leadId, leadData.name, leadData.email, leadData.company_name, leadData.website, leadData.account_id, leadData.contact_id, leadData.opportunity_id, undefined, (_a = leadData.lead_fields) !== null && _a !== void 0 ? _a : {});
        },
        onSettled: (data) => {
            if (data && data.type === "error") {
                setSaveErrorMsg(data.error);
            }
            else {
                setSaveErrorMsg(null);
            }
            setUpdatedLeadData(data);
        },
    });
    const leadCreateMutation = useMutation({
        mutationFn: ({ lead }) => createCrmLead(lead.name || "", lead.email || "", lead.lead_fields || {}, lead.company_name, lead.website, lead.account_id, lead.contact_id, lead.opportunity_id),
        onSettled: (data) => {
            if (data && data.type === "error") {
                setSaveErrorMsg(data.error);
            }
            else {
                setSaveErrorMsg(null);
            }
            setUpdatedLeadData(data);
        },
    });
    const defaultLeadFields = [
        {
            id: 0,
            fieldLabel: "Email",
            fieldValue: updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.email,
            placeholder: "Email of the lead",
            onFieldValueChange: (value) => {
                setUpdatedLeadData((prev) => {
                    if (!prev)
                        return Object.assign({ email: value }, getBaseLeadData());
                    return Object.assign(Object.assign({}, prev), { email: value });
                });
            },
            // Disabling the input for email as changing the email
            // creates a new contact object in Wiser which will then need
            // to be added as a participant to the call.
            inputDisabled: !!(updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.id),
        },
        {
            id: 1,
            fieldLabel: "Name",
            fieldValue: updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.name,
            placeholder: "Name of the lead",
            onFieldValueChange: (value) => {
                setUpdatedLeadData((prev) => {
                    if (!prev)
                        return Object.assign({ name: value }, getBaseLeadData());
                    return Object.assign(Object.assign({}, prev), { name: value });
                });
            },
        },
        {
            id: 2,
            fieldLabel: "Company name",
            fieldValue: updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.company_name,
            placeholder: "Company name of the lead",
            onFieldValueChange: (value) => {
                setUpdatedLeadData((prev) => {
                    if (!prev)
                        return Object.assign(Object.assign({}, getBaseLeadData()), { company_name: value });
                    return Object.assign(Object.assign({}, prev), { company_name: value });
                });
            },
        },
        {
            id: 3,
            fieldLabel: "Website",
            fieldValue: updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.website,
            placeholder: "Website of the lead",
            onFieldValueChange: (value) => {
                setUpdatedLeadData((prev) => {
                    if (!prev)
                        return Object.assign(Object.assign({}, getBaseLeadData()), { website: value });
                    return Object.assign(Object.assign({}, prev), { website: value });
                });
            },
        },
    ];
    const renderCrmLeadForm = () => {
        var _a, _b, _c;
        return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 items-start" }, { children: [defaultLeadFields.map((field) => (_jsx(CrmInputField, { fieldId: field.id, fieldLabel: field.fieldLabel, fieldValue: field.fieldValue, placeholder: field.placeholder, onValueChange: (newValue) => {
                        setSaveErrorMsg(null);
                        field.onFieldValueChange(newValue);
                    }, inputDisabled: field.inputDisabled }, field.fieldLabel))), requiredCrmFields
                    .sort((field1, field2) => field1.name.localeCompare(field2.name))
                    .map((crmField) => (_jsx(CrmInputField, { fieldId: crmField.id, fieldSchema: crmField.content_type_definition, fieldLabel: crmField.name, fieldValue: (updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.lead_fields)
                        ? updatedLeadData.lead_fields[crmField.id]
                        : undefined, onValueChange: (value) => {
                        setSaveErrorMsg(null);
                        setUpdatedLeadData((prev) => {
                            if (!prev)
                                return undefined;
                            return Object.assign(Object.assign({}, prev), { lead_fields: Object.assign(Object.assign({}, prev === null || prev === void 0 ? void 0 : prev.lead_fields), { [crmField.id]: value }) });
                        });
                    } }, crmField.id))), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium capitalize" }, { children: getAccountLabel(tenant.connected_crm) })), _jsx(AsyncSearchSelect, { dataFetcher: accountsFetcher, onSelect: (option) => {
                                if (!option)
                                    return;
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { account_id: Number(option.value), account_name: option.label });
                                });
                            }, placeholder: `Linked ${getAccountLabel(tenant.connected_crm)} for this lead`, selectedOption: (updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.account_id)
                                ? {
                                    value: updatedLeadData.account_id.toString(),
                                    label: (_a = updatedLeadData.account_name) !== null && _a !== void 0 ? _a : (updatedLeadData.company_name || ""),
                                }
                                : undefined })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: "Contact" })), _jsx(AsyncSearchSelect, { dataFetcher: contactsFetcher, onSelect: (option) => {
                                if (!option)
                                    return;
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { contact_id: Number(option.value), contact_name: option.label });
                                });
                            }, placeholder: "Linked contact for this lead", selectedOption: (updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.contact_id)
                                ? {
                                    value: updatedLeadData.contact_id.toString(),
                                    label: (_b = updatedLeadData.contact_name) !== null && _b !== void 0 ? _b : "",
                                }
                                : undefined })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium capitalize" }, { children: getOpportunityLabel(tenant.connected_crm) })), _jsx(AsyncSearchSelect, { dataFetcher: opportunitiesFetcher, onSelect: (option) => {
                                if (!option)
                                    return;
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { opportunity_id: Number(option.value), opportunity_name: option.label });
                                });
                            }, placeholder: `Linked ${getOpportunityLabel(tenant.connected_crm)} for this lead`, selectedOption: (updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.opportunity_id)
                                ? {
                                    value: updatedLeadData.opportunity_id.toString(),
                                    label: (_c = updatedLeadData.opportunity_name) !== null && _c !== void 0 ? _c : "",
                                }
                                : undefined })] }))] })));
    };
    const renderCrmLeadExistingForm = () => {
        var _a, _b, _c, _d, _e;
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs font-medium" }, { children: "Lead" })), _jsx(AsyncSearchSelect, { dataFetcher: leadsFetcher, onSelect: (option) => {
                                if (!option)
                                    return;
                                // No need to await here, the mutate onSuccess handler
                                // will update the state.
                                fetchLeadMutation.mutateAsync({ leadId: option.value });
                            }, placeholder: "No lead mapped", selectedOption: updatedLeadData
                                ? {
                                    label: (_b = (_a = updatedLeadData.name) !== null && _a !== void 0 ? _a : updatedLeadData.email) !== null && _b !== void 0 ? _b : "",
                                    value: updatedLeadData.id.toString(),
                                }
                                : undefined })] })), fetchLeadMutation.isLoading && _jsx("span", { children: "Syncing lead..." }), !fetchLeadMutation.isLoading && updatedLeadData && (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsx(EditableCrmField, { fieldLabel: "Lead name", fieldValue: updatedLeadData.name || "", onValueChange: (newValue) => {
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { name: newValue });
                                });
                            } }), _jsx(EditableCrmField, { fieldLabel: "Email", fieldValue: (_c = updatedLeadData.email) !== null && _c !== void 0 ? _c : "", onValueChange: (newValue) => {
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { email: newValue });
                                });
                            } }), _jsx(EditableCrmField, { fieldLabel: "Website", fieldValue: (_d = updatedLeadData.website) !== null && _d !== void 0 ? _d : "", onValueChange: (newValue) => {
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { website: newValue });
                                });
                            } }), _jsx(EditableCrmField, { fieldLabel: "Company name", fieldValue: (_e = updatedLeadData.company_name) !== null && _e !== void 0 ? _e : "", onValueChange: (newValue) => {
                                setUpdatedLeadData((prev) => {
                                    if (!prev)
                                        return undefined;
                                    return Object.assign(Object.assign({}, prev), { company_name: newValue });
                                });
                            } })] })))] }));
    };
    const renderForm = () => {
        return (_jsx(CallCrmObjectLinkForm, { objectSelector: leadSelector, setObjectSelector: (option) => {
                setLeadSelector(option);
                if (option === "NEW") {
                    setUpdatedLeadData(undefined);
                }
                else {
                    setUpdatedLeadData(getLeadData());
                }
            }, existingObjectForm: renderCrmLeadExistingForm(), newObjectForm: renderCrmLeadForm() }));
    };
    const enableSubmit = useMemo(() => {
        const isFieldValid = (field) => !!field && field.length > 0;
        const allRequiredFieldsFilled = (leadData) => {
            if (!leadData) {
                return false;
            }
            if (!tenant.connected_crm && !isFieldValid(leadData.name)) {
                return false;
            }
            if (!isFieldValid(leadData.email))
                return false;
            if (requiredCrmFields.length === 0) {
                return true;
            }
            if (!leadData.lead_fields) {
                return false;
            }
            return requiredCrmFields.every((crmField) => isFieldValid(leadData.lead_fields[crmField.id]));
        };
        return allRequiredFieldsFilled(updatedLeadData);
    }, [updatedLeadData, requiredCrmFields]);
    if (crmFieldsLoading || crmFieldsError || !crmFields) {
        // No loading state/error state UI yet.
        return null;
    }
    return (_jsx(CallCrmObjectBaseModal, { tenant: tenant, title: leadExistsInCrm ? "Edit lead" : "Create new lead", description: leadExistsInCrm
            ? "You can edit the lead for this attendee in your connected CRM."
            : "You can create a new lead for this attendee in your connected CRM.", open: open, setOpen: setOpen, submitCallback: (_) => __awaiter(void 0, void 0, void 0, function* () {
            setIsSaving(true);
            setSaveErrorMsg(null);
            const updatedLead = leadExistsInCrm && (updatedLeadData === null || updatedLeadData === void 0 ? void 0 : updatedLeadData.id)
                ? yield leadUpdateMutation.mutateAsync({
                    leadId: updatedLeadData.id,
                    leadData: updatedLeadData,
                })
                : yield leadCreateMutation.mutateAsync({
                    lead: updatedLeadData,
                });
            if (updatedLead.type === "error") {
                // No need to close the modal since we display error message in the UI.
                setIsSaving(false);
                return;
            }
            if (!call.lead_ids ||
                call.lead_ids.length === 0 ||
                !call.lead_ids.includes(updatedLead.id)) {
                // If the call is not mapped to an account, map it to the
                // account that this contact was created for.
                yield setCallLeadIds(call.id, [updatedLead.id]);
            }
            const updatedCall = yield queryClient.fetchQuery({
                queryFn: () => getLiveCallById(call.id),
            });
            updateSelectedCall(updatedCall);
            setSelectedLeadId(updatedLead.id);
            setIsSaving(false);
            setOpen(false);
        }), disableSubmit: !enableSubmit || isSaving || fetchLeadMutation.isLoading, child: renderForm(), isSaving: isSaving, error: saveErrorMsg }));
};
