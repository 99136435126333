var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { getGongIntegrationData, setGongIntegration, disconnectGongIntegration, } from "../common/endpoints";
import { integrationListingContent } from "./constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Form, FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage, } from "../components/form";
import { Input } from "../components/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
const GongFormSchema = z.object({
    base_url: z.string().url("Please enter a valid URL"),
    key: z.string().min(1, "Please enter a valid API key"),
    secret: z.string().min(1, "Please enter a valid secret"),
});
/**
 * Fetches & returns the data needed to load Gong integration data on first user login and integrations page
 */
const useGongIntegrations = (onConnect) => {
    const queryClient = useQueryClient();
    const { data: gongIntegrationData, isLoading: isGongDataLoading, isError: gongDataError, } = useQuery("gongIntegrationData", getGongIntegrationData);
    const disconnectGongMutation = useMutation({
        mutationFn: disconnectGongIntegration,
        onSuccess: () => {
            // Set Gong disconnected
            queryClient.setQueryData("gongIntegrationData", {
                status: "Not connected",
            });
        },
    });
    const connect = () => __awaiter(void 0, void 0, void 0, function* () {
        yield setGongIntegration({
            status: "Connected",
            provider_data: formValuesRef.current,
        });
        if (onConnect)
            onConnect();
        queryClient.invalidateQueries("gongIntegrationData");
    });
    const formValuesRef = useRef({
        base_url: "",
        key: "",
        secret: "",
    });
    useEffect(() => {
        if (gongIntegrationData) {
            formValuesRef.current = gongIntegrationData.provider_data;
        }
    }, [gongIntegrationData]);
    const GongForm = () => {
        const form = useForm({
            resolver: zodResolver(GongFormSchema),
            defaultValues: formValuesRef.current,
            mode: "onChange",
        });
        // I don't like calling this endpoint twice, but I'm not sure how else to get the data
        // AFTER it loads.
        const { data: gongIntegrationData, isLoading: isGongDataLoading, isError: gongDataError, } = useQuery("gongIntegrationData", getGongIntegrationData);
        useEffect(() => {
            if (gongIntegrationData && gongIntegrationData.status === "Connected") {
                form.setValue("base_url", gongIntegrationData.provider_data.base_url);
                form.setValue("key", gongIntegrationData.provider_data.key);
                form.setValue("secret", gongIntegrationData.provider_data.secret);
            }
        }, [gongIntegrationData]);
        // Set the form values on change:
        useEffect(() => {
            formValuesRef.current = form.getValues();
        }, [form.getValues()]);
        return (_jsx("div", Object.assign({ className: "pb-4" }, { children: _jsx(Form, Object.assign({}, form, { children: _jsxs("form", { children: [_jsx(FormField, { control: form.control, name: "base_url", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Gong Base URL" }), _jsx(FormDescription, { children: "A url like https://us-1234.api.gong.io" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({}, field)) }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "key", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Gong API Key" }), _jsx(FormDescription, { children: "e.g. 6SD8DSFDSF:eyJhbABCDEFG123" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({}, field)) }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "secret", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Gong Secret" }), _jsx(FormDescription, { children: "e.g. eyJlabCD123.8abcd123EFG" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({}, field)) }), _jsx(FormMessage, {})] })) })] }) })) })));
    };
    const formattedIntegrations = [
        {
            name: "Gong",
            icon: "https://storage.googleapis.com/wiser-ai-public2/gong_logo.ico",
            status: (gongIntegrationData === null || gongIntegrationData === void 0 ? void 0 : gongIntegrationData.status) || "Not connected",
            handleConnect: connect,
            handleDisconnect: disconnectGongMutation.mutateAsync,
            listingContent: integrationListingContent["Gong"],
            children: _jsx(GongForm, {}),
        },
    ];
    if (isGongDataLoading || gongDataError) {
        return {
            integrations: [],
            loaded: !isGongDataLoading,
        };
    }
    return {
        integrations: formattedIntegrations,
        loaded: !isGongDataLoading,
    };
};
export default useGongIntegrations;
