var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { createBrick, setBrickLinksToTarget, updateBrick, } from "../common/endpoints";
import { useMutation } from "react-query";
import CreateOrEditBriefing from "../pre_call_prep/create_or_edit_briefing";
import { Button } from "../components/button";
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, } from "../components/drawer_dialog";
import { Separator } from "../components/separator";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tabs, TabsList, TabsTrigger } from "../components/tabs";
import BrickPreviews from "./brick_previews";
import { TabsContent } from "@radix-ui/react-tabs";
import DeliveryTargetSelection from "../bricks/delivery_target_selection";
import AdvancedPromptSettings from "../bricks/advanced_prompt_settings";
import classNames from "../class_names";
import { AdvancedSettingsIcon, DestinationsIcon, PencilEditIcon, } from "../icons/editor_icons";
const TYPE_TO_DELIVERY_TARGETS = new Map([
    [
        "ACCOUNT",
        [
            {
                id: "AccountUI",
                name: "Account Page",
            },
            {
                id: "AccountSummary",
                name: "Account Summary (only latest will be used)",
            },
        ],
    ],
    [
        "CALL",
        [
            {
                id: "Slack",
                name: "Slack",
            },
            {
                id: "Email",
                name: "Email",
            },
        ],
    ],
    ["CALL_OVERVIEW", []],
    [
        "CONTACT",
        [
            {
                id: "ContactEmailDrafts",
                name: "Contact Email Drafts",
            },
        ],
    ],
]);
const sourceBrickTypeMap = {
    ACCOUNT: ["ACCOUNT", "TRACKER"],
    CALL: ["CALL", "ACCOUNT", "TRACKER"],
    CONTACT: ["CONTACT", "ACCOUNT"],
    MEETING_TEMPLATE_ASSIGNMENT: ["MEETING_TEMPLATE_ASSIGNMENT"],
    CALL_OVERVIEW: ["CALL_OVERVIEW"],
    CALL_KEYPOINTS: ["CALL_KEYPOINTS"],
    ACCOUNT_PREVIEW: ["ACCOUNT_PREVIEW"],
    CALL_PREVIEW: ["CALL_PREVIEW"],
    MEETING_TEMPLATE_ASSIGNMENT_PREVIEW: ["MEETING_TEMPLATE_ASSIGNMENT_PREVIEW"],
    CALL_OVERVIEW_PREVIEW: ["CALL_OVERVIEW_PREVIEW"],
    CALL_KEYPOINTS_PREVIEW: ["CALL_KEYPOINTS_PREVIEW"],
    TRACKER: ["TRACKER"],
};
const BrickEditDialog = ({ creatingBrick, setCreatingBrick, selectedBrickId, selectedType, selectedTypeLabel, allBricks, brickLinks, linkAvailability, queryClient, }) => {
    const [tempBrickForPreviewId, setTempBrickForPreviewId] = useState(null);
    const [newBrickPrompt, setNewBrickPrompt] = useState("");
    const [newBrickName, setNewBrickName] = useState("");
    const [scoringCriteria, setScoringCriteria] = useState("");
    const [selectedDataSources, setSelectedDataSources] = useState(new Set());
    const [selectedDeliveryTargets, setSelectedDeliveryTargets] = useState(new Set());
    const [selectedSourceBricks, setSelectedSourceBricks] = useState([]);
    const [selectedModel, setSelectedModel] = useState("OPENAI_FLAGSHIP");
    const [selectedWebDataModel, setSelectedWebDataModel] = useState("SONAR");
    const [suppressCitations, setSuppressCitations] = useState(false);
    const lastSelectedBrickId = useRef(null);
    useEffect(() => {
        if (brickLinks !== undefined && selectedBrickId !== null) {
            setSelectedSourceBricks(brickLinks
                .filter((link) => link.target_brick_id === selectedBrickId)
                .map((link) => allBricks === null || allBricks === void 0 ? void 0 : allBricks.find((brick) => brick.id === link.source_brick_id))
                // While this shouldn't actually filter anything out, Typescript
                // requires it for the types to compile -- since it's possible (from a compiler
                // point of view) that the line above (allBricks?.find) could return undefined.
                // We also have to tell the compiler that the output will be a Brick, as it can't
                // infer that it can no longer be undefined.
                .filter((brick) => brick !== undefined));
        }
    }, [brickLinks, selectedBrickId]);
    const availableSourceBricks = useMemo(() => {
        if (linkAvailability === undefined || allBricks === undefined) {
            return [];
        }
        const available = linkAvailability.find((availabilityForTarget) => {
            return availabilityForTarget.target_brick_id === selectedBrickId;
        });
        return allBricks
            .map((brick) => brick.id)
            .filter((brickId) => {
            return (available === undefined ||
                !available.unavailable_source_brick_ids.includes(brickId));
        });
    }, [linkAvailability, allBricks, selectedBrickId]);
    const existingBrickNames = useMemo(() => {
        var _a;
        return new Set((_a = allBricks === null || allBricks === void 0 ? void 0 : allBricks.filter((brick) => brick.id !== selectedBrickId).map((brick) => brick.name)) !== null && _a !== void 0 ? _a : []);
    }, [allBricks, selectedBrickId]);
    const clearBrickDetails = () => {
        setNewBrickName("");
        setNewBrickPrompt("");
        setSelectedDataSources(new Set());
        setSelectedDeliveryTargets(new Set());
        setSelectedSourceBricks([]);
        setPreviewOrEditTabSelection("create_or_edit");
        setSelectedWebDataModel("SONAR");
        setSuppressCitations(false);
        setSelectedModel("OPENAI_FLAGSHIP");
        setScoringCriteria("");
    };
    useEffect(() => {
        var _a;
        const brickChanged = lastSelectedBrickId.current !== selectedBrickId;
        lastSelectedBrickId.current = selectedBrickId;
        if (selectedBrickId === null || allBricks === undefined) {
            if (brickChanged) {
                clearBrickDetails();
            }
            return;
        }
        const selectedBrickList = allBricks.filter((brick) => brick.id === selectedBrickId);
        if (selectedBrickList.length === 0) {
            clearBrickDetails();
            return;
        }
        const selectedBrick = selectedBrickList[0];
        setNewBrickName(selectedBrick.name);
        setNewBrickPrompt(selectedBrick.prompt);
        setSelectedDataSources(new Set(selectedBrick.data_sources));
        setSelectedDeliveryTargets(new Set(selectedBrick.delivery_targets));
        setSelectedWebDataModel(selectedBrick.web_data_model);
        setSuppressCitations(selectedBrick.suppress_citations);
        setSelectedModel(selectedBrick.model);
        setScoringCriteria((_a = selectedBrick.scoring_criteria) !== null && _a !== void 0 ? _a : "");
        // Note, the links are handled in a separate useEffect, since they depend
        // on brickLinks rather than allBricks.
    }, [selectedBrickId, allBricks]);
    const [isSaving, setIsSaving] = useState(false);
    const [previewOrEditTabSelection, setPreviewOrEditTabSelection] = useState("create_or_edit");
    useEffect(() => {
        // Clear all the settings when the modal closes.
        if (!creatingBrick) {
            clearBrickDetails();
        }
    }, [creatingBrick]);
    const potentialSourceBricks = allBricks
        .filter((brick) => sourceBrickTypeMap[selectedType].includes(brick.research_block_type))
        .filter((brick) => availableSourceBricks.includes(brick.id));
    const setBrickLinksMutation = useMutation({
        mutationFn: setBrickLinksToTarget,
        onSuccess: (response) => {
            queryClient.setQueryData(["all_bricks"], (oldData) => {
                var _a;
                return {
                    bricks: (_a = oldData === null || oldData === void 0 ? void 0 : oldData.bricks) !== null && _a !== void 0 ? _a : [],
                    brick_links: response.brick_links,
                    link_availability: response.link_availability,
                };
            });
        },
    });
    const createBrickMutation = useMutation({
        mutationFn: createBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            const prevBricks = allBricks !== null && allBricks !== void 0 ? allBricks : [];
            queryClient.setQueryData(["all_bricks"], {
                bricks: [...prevBricks, updatedBrick],
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
        }),
    });
    const updateBrickMutation = useMutation({
        mutationFn: updateBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            queryClient.setQueryData(["all_bricks"], {
                bricks: allBricks === null || allBricks === void 0 ? void 0 : allBricks.map((b) => b.id === updatedBrick.id ? updatedBrick : b),
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
        }),
    });
    const tabs = [
        {
            label: (_jsxs(_Fragment, { children: [_jsx(PencilEditIcon, { className: "h-4 w-4 mr-2.5" }), " Edit Prompt"] })),
            value: "create_or_edit",
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(DestinationsIcon, { className: "h-4 w-4 mr-2.5" }), " Prompt Destinations"] })),
            value: "destinations",
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(AdvancedSettingsIcon, { className: "h-4 w-4 mr-2.5" }), " Advanced Prompt Settings"] })),
            value: "advanced",
        },
        { label: "Preview", value: "preview" },
    ];
    const contextType = useMemo(() => {
        if (selectedType.includes("ACCOUNT")) {
            return "account";
        }
        else if (selectedType.includes("CALL")) {
            return "call";
        }
        else if (selectedType.includes("TRACKER")) {
            return "call";
        }
        else if (selectedType.includes("CONTACT")) {
            return "contact";
        }
        return "account";
    }, [selectedType]);
    return (_jsx(Drawer, Object.assign({ open: creatingBrick, onOpenChange: setCreatingBrick }, { children: _jsx(DrawerContent, Object.assign({ className: classNames(previewOrEditTabSelection === "preview" ? "" : "lg:max-w-[50%]", "sm:max-w-[80%] h-full p-8 max-h-screen overflow-y-auto overflow-x-hidden") }, { children: _jsxs("div", Object.assign({ className: "flex flex-col h-full" }, { children: [_jsxs(DrawerHeader, Object.assign({ className: "flex-shrink-0" }, { children: [_jsx(DrawerTitle, Object.assign({ className: "text-[32px] font-normal text-wds-black" }, { children: "Prompt Editor" })), _jsxs(Tabs, Object.assign({ defaultValue: "create_or_edit", className: "w-full pb-0.5 pt-4", onValueChange: setPreviewOrEditTabSelection, value: previewOrEditTabSelection, variant: "underline" }, { children: [_jsx(TabsList, Object.assign({ className: "border-none gap-x-6 mb-8 flex-shrink-0" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, variant: "underline" }, { children: tab.label }), tab.value))) })), _jsxs("div", Object.assign({ className: "overflow-y-auto flex-1 min-h-0" }, { children: [_jsx(TabsContent, Object.assign({ value: "create_or_edit" }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: selectedType, name: newBrickName, setName: setNewBrickName, prompt: newBrickPrompt, setPrompt: setNewBrickPrompt, selectedDataSources: selectedDataSources, setSelectedDataSources: setSelectedDataSources, isGalleryBrick: !!selectedBrickId &&
                                                        allBricks.some((brick) => brick.id === selectedBrickId &&
                                                            brick.gallery_brick_id), potentialSourceBricks: potentialSourceBricks, selectedSourceBricks: selectedSourceBricks, selectSourceBricks: setSelectedSourceBricks, selectedModel: selectedModel, setSelectedModel: setSelectedModel, existingBrickNames: existingBrickNames }) })), _jsx(TabsContent, Object.assign({ value: "preview" }, { children: _jsx(BrickPreviews, { newPrompt: newBrickPrompt, brickId: selectedBrickId !== null && selectedBrickId !== void 0 ? selectedBrickId : tempBrickForPreviewId, brickName: newBrickName, contextType: contextType, selectedDataSources: Array.from(selectedDataSources), selectedSourceBricks: selectedSourceBricks, selectedModel: selectedModel, selectedWebDataModel: selectedWebDataModel, suppressCitations: suppressCitations, scoringCriteria: scoringCriteria }) })), _jsx(TabsContent, Object.assign({ value: "destinations" }, { children: _jsx(DeliveryTargetSelection, { deliveryTargets: selectedDeliveryTargets, setDeliveryTargets: setSelectedDeliveryTargets, deliveryTargetOptions: TYPE_TO_DELIVERY_TARGETS.get(selectedType) }) })), _jsx(TabsContent, Object.assign({ value: "advanced" }, { children: _jsx(AdvancedPromptSettings, { selectedModel: selectedModel, setSelectedModel: setSelectedModel, selectedWebDataModel: selectedDataSources.has("WebData")
                                                        ? selectedWebDataModel
                                                        : null, setSelectedWebDataModel: setSelectedWebDataModel, scoringCriteria: scoringCriteria, setScoringCriteria: setScoringCriteria, suppressCitations: suppressCitations, setSuppressCitations: setSuppressCitations }) }))] }))] }))] })), _jsxs(DrawerFooter, Object.assign({ className: "flex justify-end items-center sm:flex-col flex-shrink-0 mt-auto" }, { children: [_jsx(Separator, { orientation: "horizontal", className: "-mx-8 my-8" }), _jsx("div", Object.assign({ className: "flex flex-row w-full justify-between items-center" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full sm:flex-row sm:justify-between" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", className: "text-destructive font-semibold", onClick: () => setCreatingBrick(false) }, { children: "Cancel" })), _jsxs("div", Object.assign({ className: "flex flex-row gap-2" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "light", className: "rounded-lg", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        if (!selectedBrickId) {
                                                            // If the brick does not exist, we make a preview version
                                                            // TODO: I think this causes us to update bricks and flush the
                                                            // current data
                                                            const resp = yield createBrickMutation.mutateAsync({
                                                                name: newBrickName,
                                                                dataSources: [...selectedDataSources],
                                                                prompt: newBrickPrompt,
                                                                deliveryTargets: [...selectedDeliveryTargets],
                                                                researchBlockType: selectedType,
                                                                preview: true,
                                                                model: selectedModel,
                                                                webDataModel: selectedWebDataModel,
                                                                suppressCitations: suppressCitations,
                                                                scoringCriteria: scoringCriteria,
                                                            });
                                                            setTempBrickForPreviewId(resp.id);
                                                        }
                                                        setPreviewOrEditTabSelection("preview");
                                                    }) }, { children: "Preview Output" })), _jsx(Button, Object.assign({ type: "button", variant: "black", className: "rounded-lg", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        if (selectedBrickId) {
                                                            yield updateBrickMutation.mutateAsync({
                                                                id: selectedBrickId,
                                                                name: newBrickName,
                                                                dataSources: [...selectedDataSources],
                                                                prompt: newBrickPrompt,
                                                                deliveryTargets: [...selectedDeliveryTargets],
                                                                model: selectedModel,
                                                                webDataModel: selectedWebDataModel,
                                                                suppressCitations: suppressCitations,
                                                                scoringCriteria: scoringCriteria,
                                                            });
                                                        }
                                                        else {
                                                            const resp = yield createBrickMutation.mutateAsync({
                                                                name: newBrickName,
                                                                dataSources: [...selectedDataSources],
                                                                prompt: newBrickPrompt,
                                                                deliveryTargets: [...selectedDeliveryTargets],
                                                                researchBlockType: selectedType,
                                                                model: selectedModel,
                                                                webDataModel: selectedWebDataModel,
                                                                suppressCitations: suppressCitations,
                                                                scoringCriteria: scoringCriteria,
                                                            });
                                                        }
                                                        setCreatingBrick(false);
                                                    }), disabled: newBrickName === "" ||
                                                        newBrickPrompt === "" ||
                                                        existingBrickNames.has(newBrickName) }, { children: isSaving ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin" })) : ("Save") }))] }))] })) }))] }))] })) })) })));
};
export default BrickEditDialog;
