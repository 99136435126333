var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import BaseTable2 from "./base_table2";
import { deletePreCallPrepBlock, getAllBricks, setBrickOrdering, getActiveTenant, } from "./common/endpoints";
import { useQuery, useQueryClient } from "react-query";
import ItemDetailsDrawer from "./components/item_details_drawer";
import { NoteLoaderFrame } from "./common/loaders";
import { Tabs, TabsList, TabsTrigger } from "./components/tabs";
import classNames from "./class_names";
import useFeatureFlagEnabled from "./use_feature_flag_enabled";
import BrickEditDialog from "./bricks/brick_edit_dialog";
import { INTEGRATION_DETAILS } from "./post_call/data_processing_config";
import { formatToLocalISOString } from "./strings";
import { getConnectedCrmLogo } from "./common/crm_logo";
import WiserLogo from "./icons/wiser_logo";
import { useNavigate } from "react-router-dom";
import { Badge } from "./components/badge";
const DELIVERY_TARGET_LABELS = new Map([
    ["AccountUI", "Account Custom Insight"],
    ["WiserLiveCall", "Meeting Research"],
    ["ContactEmailDrafts", "Contact Email Draft"],
    ["AccountSummary", "Account Summary"],
]);
const BrickTable = () => {
    var _a, _b;
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: allBricksResponse, isLoading: bricksLoading, isError: bricksError, } = useQuery(["all_bricks"], getAllBricks, {
        refetchOnWindowFocus: false,
    });
    const allBricks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.bricks;
    const brickLinks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.brick_links;
    const linkAvailability = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.link_availability;
    const brickIdToSourceNames = useMemo(() => {
        var _a;
        const targetToSourceNames = new Map();
        for (const link of brickLinks !== null && brickLinks !== void 0 ? brickLinks : []) {
            const sourceName = (_a = allBricks === null || allBricks === void 0 ? void 0 : allBricks.find((brick) => brick.id === link.source_brick_id)) === null || _a === void 0 ? void 0 : _a.name;
            if (sourceName) {
                const existingNames = targetToSourceNames.get(link.target_brick_id) || [];
                targetToSourceNames.set(link.target_brick_id, [
                    ...existingNames,
                    sourceName,
                ]);
            }
        }
        return targetToSourceNames;
    }, [allBricks, brickLinks]);
    const [selectedBrickId, setSelectedBrickId] = useState(null);
    const [creatingBrick, setCreatingBrick] = useState(false);
    const contactOutreachEnabled = useFeatureFlagEnabled("CONTACT_OUTREACH");
    const tabs = useMemo(() => {
        const _tabs = [
            { label: "Account prompts", value: "ACCOUNT" },
            { label: "Pre-call prep prompts", value: "CALL" },
            { label: "Post-call tracker prompts", value: "TRACKER" },
        ];
        if (contactOutreachEnabled) {
            _tabs.push({ label: "Contact Prompts", value: "CONTACT" });
        }
        return _tabs;
    }, [contactOutreachEnabled]);
    const [selectedType, setSelectedType] = useState(tabs[0].value);
    useEffect(() => {
        setSelectedBrickId(null);
        setCreatingBrick(false);
    }, [selectedType]);
    const currentTypeBricks = useMemo(() => {
        if (selectedType === null || allBricks === undefined) {
            return [];
        }
        return allBricks.filter((brick) => brick.research_block_type === selectedType);
    }, [selectedType, allBricks]);
    const clearBrickDetails = () => {
        setSelectedBrickId(null);
    };
    const sourceNameToName = (data_source) => {
        if (data_source.toLowerCase().includes("salesforce")) {
            return "Salesforce";
        }
        if (data_source.toLowerCase().includes("web")) {
            return "Web";
        }
        if (data_source.toLowerCase().includes("linkedin")) {
            return "LinkedIn";
        }
        switch (data_source) {
            case "CompanyProfile":
                return "LinkedIn";
            case "ContactProfiles":
                return "LinkedIn";
            default:
                return "Wiser CRM";
        }
    };
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const makeIcons = (data_sources, source_bricks) => {
        var _a;
        const allSourceNames = [...source_bricks, ...data_sources];
        const srcs = allSourceNames.map((data_source) => {
            var _a;
            const canonicalName = sourceNameToName(data_source);
            return {
                url: canonicalName.toLowerCase().includes("linkedin")
                    ? "https://storage.cloud.google.com/wiser-ai-public2/linkedin-logo-enable.png"
                    : canonicalName.toLowerCase().includes("web")
                        ? "https://storage.cloud.google.com/wiser-ai-public2/perplexity-color.svg"
                        : canonicalName.toLowerCase().includes("salesforce") && activeTenant
                            ? getConnectedCrmLogo(activeTenant.connected_crm)
                            : (_a = INTEGRATION_DETAILS.get(canonicalName)) === null || _a === void 0 ? void 0 : _a.icon_url,
                detailedName: data_source,
            };
        });
        const srcToDetailedNameList = new Map();
        for (const src of srcs) {
            const existing = srcToDetailedNameList.get(src.url) || [];
            existing.push(src.detailedName.replace("Salesforce", (_a = activeTenant === null || activeTenant === void 0 ? void 0 : activeTenant.connected_crm) !== null && _a !== void 0 ? _a : ""));
            srcToDetailedNameList.set(src.url, existing);
        }
        return (_jsx("div", Object.assign({ className: "flex flex-row gap-2" }, { children: Array.from(new Set(srcs.map((s) => s.url))).map((url) => (_jsx("div", Object.assign({ title: srcToDetailedNameList.get(url).join(", ") }, { children: _jsx("img", { src: url, alt: srcToDetailedNameList.get(url).join(", "), className: "w-6 h-6 p-1 border border-wds-gray-3 rounded-sm" }) }), url))) })));
    };
    const promptDetails = useMemo(() => {
        if (selectedBrickId === null || allBricks === undefined) {
            return [];
        }
        const selectedBrickList = allBricks.filter((brick) => brick.id === selectedBrickId);
        if (selectedBrickList.length === 0) {
            return [];
        }
        const selectedBrick = selectedBrickList[0];
        const caseFixedType = selectedBrick.research_block_type.charAt(0).toUpperCase() +
            selectedBrick.research_block_type.slice(1).toLowerCase();
        return [
            { title: "Prompt Name", value: selectedBrick.name },
            {
                title: "Prompt Type",
                value: caseFixedType,
            },
            { title: "Prompt Text", value: selectedBrick.prompt },
            {
                title: "Deliver To",
                value: selectedBrick.delivery_targets
                    .map((deliveryTarget) => { var _a; return (_a = DELIVERY_TARGET_LABELS.get(deliveryTarget)) !== null && _a !== void 0 ? _a : deliveryTarget; })
                    .join(", "),
            },
        ];
    }, [selectedBrickId, allBricks]);
    useEffect(() => {
        if (creatingBrick)
            return;
        clearBrickDetails();
    }, [creatingBrick]);
    // UNCOMMENT FOR TESTING
    // useEffect(() => {
    //   if (currentTypeBricks.length > 0) {
    //     setSelectedBrickId(currentTypeBricks[0].id);
    //     setCreatingBrick(true);
    //   }
    // }, [currentTypeBricks]);
    const allBricksRef = useRef(allBricks);
    useEffect(() => {
        allBricksRef.current = allBricks;
    }, [allBricks]);
    if (bricksLoading || bricksError || allBricks === undefined) {
        return _jsx(NoteLoaderFrame, {});
    }
    const brickTableColumns = [
        { header: "Prompt Name", accessorKey: "name" },
        {
            header: "Managed By",
            accessorKey: "gallery_brick_id",
            cell: ({ row }) => {
                const text = row.original.gallery_brick_id ? "Wiser" : "Customer";
                return (_jsxs(Badge, Object.assign({ variant: "light", className: classNames(text === "Customer"
                        ? "text-[#8F4800] bg-[#8F4800]/[0.08] border-[#8F4800]/[0.12]"
                        : "", "hover:bg-inherit, hover:text-inherit, hover:border-inherit") }, { children: ["\u2022\u00A0\u00A0", text] })));
            },
        },
        {
            header: "Prompt Usage",
            accessorKey: "output_count",
            cell: ({ row }) => (_jsxs(_Fragment, { children: [row.original.output_count, " Times"] })),
        },
        {
            header: "Data Sources",
            accessorKey: "data_source_icons",
            cell: ({ row }) => {
                var _a;
                return makeIcons(row.original.data_sources, (_a = brickIdToSourceNames.get(row.original.id)) !== null && _a !== void 0 ? _a : []);
            },
        },
        {
            header: "Containing Sets",
            accessorKey: "brick_set_names",
            cell: ({ row }) => {
                var _a;
                return (_jsx("div", Object.assign({ className: "flex flex-col" }, { children: (_a = row.original.brick_set_names) === null || _a === void 0 ? void 0 : _a.map((name, index) => (_jsx("span", { children: name }, index))) })));
            },
        },
        {
            header: "Last Updated",
            accessorKey: "timestamp",
            cell: ({ row }) => (_jsx(_Fragment, { children: row.original.timestamp
                    ? formatToLocalISOString(new Date(row.original.timestamp))
                    : "" })),
        },
    ];
    const selectedTypeLabel = (_b = (_a = tabs.find((tab) => tab.value === selectedType)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : "Custom Prompts";
    return (_jsxs("div", Object.assign({ className: classNames("flex flex-col p-8 text-wds-black", promptDetails.length === 0 ? "w-full" : "w-3/4") }, { children: [_jsx("div", Object.assign({ className: "text-[32px] font-medium leading-8" }, { children: "Custom Prompt List" })), _jsx(Tabs, Object.assign({ defaultValue: tabs[0].value, onValueChange: (value) => {
                    setSelectedType(value);
                }, className: "border-b border-wds-gray-3 pb-0.5 pt-4" }, { children: _jsx(TabsList, Object.assign({ className: "border-none gap-x-6" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, variant: "underline" }, { children: tab.label }), tab.value))) })) })), _jsx(BaseTable2, { title: selectedTypeLabel, rows: currentTypeBricks, columns: brickTableColumns, addCallback: () => {
                    clearBrickDetails();
                    setCreatingBrick(true);
                }, expandCallback: (row) => {
                    setSelectedBrickId(row.id);
                }, reorderRows: (rows) => {
                    for (let i = 0; i < rows.length; i++) {
                        console.log("REORDER: ", rows[i].name);
                    }
                    setBrickOrdering(rows.map((brick) => brick.id)).then((bricks) => {
                        queryClient.setQueryData(["all_bricks"], {
                            bricks,
                            brick_links: brickLinks,
                            link_availability: linkAvailability,
                        });
                    });
                }, rowName: "Prompt", additionalActions: [
                    {
                        label: (_jsxs("div", Object.assign({ className: "text-wds-black flex items-center gap-x-2" }, { children: ["Prompts Gallery", _jsx(WiserLogo, { className: "w-6 h-6" })] }))),
                        callback: () => {
                            navigate("/brick_gallery");
                        },
                    },
                ] }), creatingBrick && (_jsx(BrickEditDialog, { creatingBrick: creatingBrick, setCreatingBrick: setCreatingBrick, selectedBrickId: selectedBrickId, selectedType: selectedType, selectedTypeLabel: selectedTypeLabel, allBricks: allBricks, brickLinks: brickLinks !== null && brickLinks !== void 0 ? brickLinks : [], linkAvailability: linkAvailability !== null && linkAvailability !== void 0 ? linkAvailability : [], queryClient: queryClient })), promptDetails.length > 0 && !creatingBrick && (_jsx(ItemDetailsDrawer, { title: "Prompt details", details: promptDetails, isOpen: promptDetails.length > 0 && !creatingBrick, onClose: () => {
                    setSelectedBrickId(null);
                }, deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (selectedBrickId === null) {
                        return;
                    }
                    yield deletePreCallPrepBlock(selectedBrickId);
                    setSelectedBrickId(null);
                    queryClient.invalidateQueries("all_bricks");
                }), editCallback: () => {
                    setCreatingBrick(true);
                }, itemName: "Prompt" }))] })));
};
export default BrickTable;
