import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircleLightIcon } from "../icons/editor_icons";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import classNames from "../class_names";
import { useState, useRef, useEffect } from "react";
const colorToHeaderText = {
    red: (_jsxs("div", Object.assign({ className: "flex flex-row gap-2.5 text-[#8B0C01] bg-[#FF3B3014] flex-shrink" }, { children: [_jsx("div", { children: "Warning" }), _jsx("div", { children: "\u00B7" }), _jsx("div", { children: "1/3" })] }))),
    yellow: (_jsxs("div", Object.assign({ className: "flex flex-row gap-2.5 text-[#A66A16] bg-[#FF950014] flex-shrink" }, { children: [_jsx("div", { children: "At risk" }), _jsx("div", { children: "\u00B7" }), _jsx("div", { children: "2/3" })] }))),
    green: (_jsxs("div", Object.assign({ className: "flex flex-row gap-2.5 text-[#067522] bg-[#34C75914] flex-shrink" }, { children: [_jsx("div", { children: "Looks good" }), _jsx("div", { children: "\u00B7" }), _jsx("div", { children: "3/3" })] }))),
    none: (_jsx("div", Object.assign({ className: "flex flex-row gap-2.5 text-[#6B7280] bg-[#9CA3AF14] flex-shrink" }, { children: _jsx("div", { children: "No score" }) }))),
};
const colorToBorderColor = {
    red: "border-l-[#FF3B30]",
    yellow: "border-l-[#FF9500]",
    green: "border-l-[#34C759]",
    none: "border-l-[#9CA3AF]",
};
const colorToIconColor = {
    red: "text-[#FF3B30]",
    yellow: "text-[#FF9500]",
    green: "text-[#34C759]",
    none: "text-[#9CA3AF]",
};
export const BrickScoreDisplay = ({ scoring_color, rationale, }) => {
    const [open, setOpen] = useState(false);
    const triggerRef = useRef(null);
    useEffect(() => {
        // Close the popover when the trigger is not in view
        const observer = new IntersectionObserver(([entry]) => {
            if (!entry.isIntersecting && open) {
                setOpen(false);
            }
        });
        if (triggerRef.current) {
            observer.observe(triggerRef.current);
        }
        return () => {
            if (triggerRef.current) {
                observer.unobserve(triggerRef.current);
            }
        };
    }, [open]);
    return (_jsxs(Popover, Object.assign({ open: open, onOpenChange: setOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsx("button", Object.assign({ ref: triggerRef, className: "flex items-center" }, { children: _jsx(CircleLightIcon, { className: classNames("w-4 h-4", colorToIconColor[scoring_color]) }) })) })), _jsx(PopoverContent, Object.assign({ className: classNames("border-l-4 px-4.5 py-2.5 max-w-[320px]", colorToBorderColor[scoring_color]), side: "top" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsx("div", Object.assign({ className: classNames("font-medium") }, { children: colorToHeaderText[scoring_color] })), _jsx("div", Object.assign({ className: "text-sm" }, { children: rationale }))] })) }))] })));
};
