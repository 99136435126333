var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { getSlackChannelsForPostCall } from "../common/endpoints";
import { AsyncSearchSelect } from "../components/async_search_select";
export const SlackTargetItem = ({ targetDetails, }) => {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/slack_logo_color.png", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col" }, { children: [_jsx("span", Object.assign({ className: "text-xs font-medium text-gray-600" }, { children: (_a = targetDetails.channelName) !== null && _a !== void 0 ? _a : "Unknown channel" })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-black" }, { children: "Slack Channel" }))] }))] }));
};
export const SlackDestinationSelector = ({ selectedTarget, onTargetDetailsChange, }) => {
    var _a, _b;
    const [selectedChannelDetails, setSelectedChannelDetails] = useState({
        channelId: (_a = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.channelId) !== null && _a !== void 0 ? _a : "No channel selected",
        channelName: selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.channelName,
    });
    const validTargetDetails = () => {
        return selectedChannelDetails.channelId !== "No channel selected";
    };
    useEffect(() => {
        if (!validTargetDetails()) {
            return;
        }
        onTargetDetailsChange(selectedChannelDetails);
    }, [selectedChannelDetails]);
    const queryClient = useQueryClient();
    const slackChannelsFetcher = (userQuery, ids, _cursor) => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: Update this when we add support for searching slack channels by name
        // and paginate the results.
        const queryResponse = yield queryClient.fetchQuery({
            queryKey: ["slackChannels"],
            queryFn: getSlackChannelsForPostCall,
            // To not refetch on every user query
            staleTime: Infinity,
        });
        return {
            results: [
                {
                    name: "Send to internal meeting participants",
                    id: "internal_participants",
                },
                ...queryResponse.private,
                ...queryResponse.public,
            ]
                .filter((channel) => channel.name.toLowerCase().includes(userQuery.toLowerCase()))
                .slice(0, 200)
                .map((obj) => {
                return { label: obj.name, value: obj.id };
            }),
        };
    });
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "w-80 flex flex-col flex-grow items-start self-stretch gap-4" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-base" }, { children: "Select a destination" })), _jsxs("div", Object.assign({ className: "flex flex-grow flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Destination" })), _jsx(AsyncSearchSelect, { dataFetcher: slackChannelsFetcher, selectedOption: selectedChannelDetails
                                ? {
                                    value: selectedChannelDetails.channelId,
                                    label: (_b = selectedChannelDetails.channelName) !== null && _b !== void 0 ? _b : "",
                                }
                                : undefined, onSelect: (option) => {
                                if (!option)
                                    return;
                                setSelectedChannelDetails(Object.assign(Object.assign({}, selectedChannelDetails), { channelId: option.value, channelName: option.label }));
                            }, buttonClasses: "bg-white rounded-lg" })] }))] })) }));
};
