var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import * as endpoints from "../common/endpoints";
import { Switch } from "@headlessui/react";
import classNames from "../class_names";
import Dropdown from "../common/dropdown";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { getAllPreCallPrepTemplates } from "../common/endpoints";
import UserSettingsSection from "./user_settings_section";
import BaseModal from "../base_modal";
import LiveCardTextEditor from "../live_card_rich_text";
import { useMutation, useQuery, useQueryClient } from "react-query";
import WaitingSpinner from "../waiting_spinner";
import { PencilIcon } from "@heroicons/react/24/outline";
import { CenterScreen } from "./styled_components";
import { SearchableDropdown } from "../common/searchable_dropdown";
const UserSettings = () => {
    var _a;
    const [editingNotes, setEditingNotes] = useState(false);
    const [temporaryNotes, setTemporaryNotes] = useState("");
    const [editingBotName, setEditingBotName] = useState(false);
    const [customBotName, setCustomBotName] = useState("WiserAI");
    const defaultTimezone = Intl
        .DateTimeFormat()
        .resolvedOptions().timeZone;
    const [timezone, setTimezone] = useState(defaultTimezone);
    const queryClient = useQueryClient();
    const autojoinOptions = [
        {
            name: "Never record automatically",
            internal: false,
            external: false,
        },
        {
            name: "External meetings only",
            internal: false,
            external: true,
        },
        {
            name: "Internal meetings only",
            internal: true,
            external: false,
        },
        {
            name: "All meetings",
            internal: true,
            external: true,
        },
    ];
    const { data: userProfile, isError: userProfileError, isLoading: userProfileLoading, } = useQuery({
        queryKey: ["getOrCreateUserProfile"],
        queryFn: endpoints.getOrCreateUserProfile,
    });
    const { data: preCallTemplates, isError: preCallTemplateError, isLoading: preCallTemplateLoading, } = useQuery({
        queryKey: ["getAllPreCallPrepTemplates"],
        queryFn: getAllPreCallPrepTemplates,
    });
    useEffect(() => {
        if (userProfile) {
            setCustomBotName(userProfile.custom_bot_name);
            setTimezone(userProfile.timezone || defaultTimezone);
        }
    }, [userProfile]);
    const templateOptions = preCallTemplates === null || preCallTemplates === void 0 ? void 0 : preCallTemplates.map((template) => ({
        label: template.name,
        value: template.id.toString(),
    }));
    const renderToggle = (checked, onChange, label) => {
        return (_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Switch, Object.assign({ checked: checked, onChange: onChange, className: classNames(checked ? "bg-blue-500" : "bg-gray-200", "relative inline-flex items-center h-6 rounded-full w-11 flex-shrink-0 transition-colors") }, { children: _jsx("span", { className: `${checked ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform` }) })), _jsx("span", Object.assign({ className: classNames(checked ? "font-bold" : "", "flex-shrink") }, { children: label }))] })));
    };
    const renderManualNoteTemplate = () => {
        if (userProfile) {
            return (_jsxs("div", Object.assign({ className: "flex justify-between items-center w-full" }, { children: [_jsx("div", { className: `flex-1 px-4 ${userProfile.default_note_template.includes("ql-ui") ? "ql-editor" : ""}`, dangerouslySetInnerHTML: {
                            __html: userProfile.default_note_template,
                        } }), _jsx("button", Object.assign({ className: "px-4 py-2 bg-white flex items-center hover:bg-gray-100", onClick: () => setEditingNotes(true) }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-blue-600" }) }))] })));
        }
        return null;
    };
    const updateUserProfileMutation = useMutation({
        mutationFn: endpoints.updateUserProfile,
        onSuccess: (newUserProfile) => {
            // Set value
            queryClient.setQueryData(["getOrCreateUserProfile"], newUserProfile);
        },
    });
    let externalAutojoinEnabled = false;
    let internalAutojoinEnabled = false;
    if (userProfile) {
        externalAutojoinEnabled = userProfile.autojoin_external_meetings;
        internalAutojoinEnabled = userProfile.autojoin_internal_meetings;
    }
    const showNoBotOption = useFeatureFlagEnabled("ZOOM_CLOUD_RECORDING");
    if (userProfileLoading || preCallTemplateLoading) {
        return (_jsx(CenterScreen, { children: _jsx(WaitingSpinner, { text: "Loading..." }) }));
    }
    if (userProfileError || preCallTemplateError) {
        return (_jsx(CenterScreen, { children: _jsx("p", { children: "Error loading userProfile or pre-call templates" }) }));
    }
    const timezoneOptions = Intl
        .supportedValuesOf("timeZone")
        .map((timezone) => ({
        label: timezone,
        value: timezone,
    }));
    return (_jsx("div", Object.assign({ className: "flex bg-inherit" }, { children: userProfile && (_jsxs("div", Object.assign({ className: "flex flex-col mx-auto" }, { children: [_jsxs("div", Object.assign({ className: "mt-8 mb-8 m-auto inline-flex flex-col items-start p-6 gap-6 rounded-lg border-solid border-gray-300 bg-white lg:w-[720px] w-[480px]" }, { children: [_jsx("span", Object.assign({ className: "font-black text-lg" }, { children: "Pre-call briefing" })), _jsx("div", { className: "w-full border-b border-gray-300" }), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-4" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "font-bold" }, { children: "Destinations" })), _jsx("span", Object.assign({ className: "font-normal" }, { children: "Enable or disable pre-call briefing information to be sent to certain places" }))] })), _jsx("div", Object.assign({ className: "flex flex-col gap-2" }, { children: renderToggle(userProfile.precall_slack_enabled, (checked) => {
                                        updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { precall_slack_enabled: checked }));
                                    }, "Slack") }))] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-4" }, { children: [_jsx("span", Object.assign({ className: "font-bold" }, { children: "Delivery timing" })), _jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsx("span", Object.assign({ className: "font-medium text-gray-500" }, { children: "Deliver pre-call briefings" })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-1" }, { children: [_jsx("input", { type: "number", className: "flex items-center w-20 h-9 px-3 py-2 border border-solid border-gray-300 rounded-lg", value: userProfile.precall_notification_duration.value.toString(), onChange: (e) => {
                                                                let value = parseInt(e.target.value);
                                                                if (isNaN(value)) {
                                                                    value = 0;
                                                                }
                                                                updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { precall_notification_duration: Object.assign(Object.assign({}, userProfile.precall_notification_duration), { value }) }));
                                                            } }), _jsx("div", Object.assign({ className: "w-40 h-5" }, { children: _jsx(Dropdown, { options: [
                                                                    {
                                                                        label: "minutes",
                                                                        value: "minutes",
                                                                    },
                                                                    {
                                                                        label: "hours",
                                                                        value: "hours",
                                                                    },
                                                                    {
                                                                        label: "days",
                                                                        value: "days",
                                                                    },
                                                                ], defaultSelected: userProfile.precall_notification_duration.unit, onOptionSelect: (option) => {
                                                                    updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { precall_notification_duration: Object.assign(Object.assign({}, userProfile.precall_notification_duration), { unit: option }) }));
                                                                }, buttonClasses: "hover:none bg-white border border-solid border-gray-300 rounded-lg" }) }))] })), "before the call starts"] }))] }))] }))] })), _jsx(UserSettingsSection, { title: "Auto-join settings", description: "Automatically join meetings", children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-4" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: _jsx("span", Object.assign({ className: "font-bold" }, { children: "Which meetings would you like Wiser to record automatically?" })) })), _jsx("div", Object.assign({ className: "flex flex-col gap-2" }, { children: autojoinOptions.map((option) => (_jsxs("div", { children: [_jsx("input", { type: "checkbox", className: "rounded-lg mx-2", checked: option.external === externalAutojoinEnabled &&
                                                option.internal === internalAutojoinEnabled, onChange: () => {
                                                updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { autojoin_external_meetings: option.external, autojoin_internal_meetings: option.internal }));
                                            } }), option.name] }, option.name))) }))] })) }), _jsx(UserSettingsSection, { title: "Meeting Bot settings", description: "The Wiser bot appears in your meetings as a participant", children: _jsxs("div", Object.assign({ className: "self-stretch flex flex-col justify-start items-start gap-1" }, { children: [_jsx("div", Object.assign({ className: "flex justify-start items-start gap-1" }, { children: _jsx("div", Object.assign({ className: "text-neutral-500 text-xs font-medium leading-none" }, { children: "Bot name" })) })), _jsxs("div", Object.assign({ className: "flex justify-between items-center w-full" }, { children: [_jsx("div", Object.assign({ className: "flex-1 px-4" }, { children: (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.custom_bot_name) !== null && _a !== void 0 ? _a : "WiserAI" })), _jsx("button", Object.assign({ className: "px-4 py-2 bg-white flex items-center hover:bg-gray-100", onClick: () => setEditingBotName(true) }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-blue-600" }) }))] })), _jsx("div", Object.assign({ className: "flex justify-start items-start gap-1" }, { children: showNoBotOption &&
                                    renderToggle(userProfile.no_live_bot, (checked) => {
                                        updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { no_live_bot: checked }));
                                    }, "Wiser will not join your meetings as a bot, and instead will import the Zoom recording once your meeting has ended. Note, videos will be delayed ~1 hour after your meeting end time if you select this option and the bot will still join meetings hosted by external organizations.") })), _jsx("div", { className: "flex justify-between items-center w-full" }), editingBotName && (_jsx(BaseModal, Object.assign({ title: "Edit Bot Name", description: "This is the name of the bot that meeting participants will see.", fields: [], open: editingBotName, setOpen: (open) => {
                                    if (!open) {
                                        setEditingBotName(false);
                                    }
                                }, submitCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                                    // closes the modal
                                    setEditingBotName(false);
                                    updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { custom_bot_name: customBotName }));
                                }), submitText: "Save" }, { children: _jsx("input", { type: "text", placeholder: "WiserAI", className: "self-stretch px-4 py-3 bg-white rounded-lg border border-neutral-200 w-full", onChange: (e) => setCustomBotName(e.target.value), value: customBotName }) })))] })) }), _jsx(UserSettingsSection, { title: "Default call notes", description: "Set your default call notes template for calls with prospects", children: _jsxs(_Fragment, { children: [renderManualNoteTemplate(), editingNotes && (_jsx(BaseModal, Object.assign({ title: "Edit Default Notes", description: "You can use template variables like {call.call_time}, {call.name}, {call.description} and {call.call_type}  to dynamically generate notes with detailed call information.", fields: [], open: editingNotes, setOpen: (open) => {
                                    if (!open) {
                                        setEditingNotes(false);
                                    }
                                }, submitCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                                    // close the modal
                                    setEditingNotes(false);
                                    updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { default_note_template: temporaryNotes }));
                                }), submitText: "Save" }, { children: _jsx(LiveCardTextEditor, { content: userProfile.default_note_template, setContent: (html) => {
                                        setTemporaryNotes(html);
                                    } }) })))] }) }), _jsx(UserSettingsSection, { title: "Timezone Settings", description: "Set your preferred timezone", children: _jsxs("div", Object.assign({ className: "self-stretch flex flex-col justify-start items-start gap-1" }, { children: [_jsx("div", Object.assign({ className: "flex justify-start items-start gap-1" }, { children: _jsx("div", Object.assign({ className: "text-neutral-500 text-xs font-medium leading-none" }, { children: "Timezone" })) })), _jsx(SearchableDropdown, { options: timezoneOptions, selectedOption: {
                                    value: timezone,
                                    label: timezone,
                                }, onSelect: (option) => {
                                    if (option.value) {
                                        setTimezone(option.value);
                                        updateUserProfileMutation.mutate(Object.assign(Object.assign({}, userProfile), { timezone: option.value }));
                                    }
                                } })] })) })] }))) })));
};
export default UserSettings;
