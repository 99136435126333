var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "../class_names";
import { formatSecondsToMinSec } from "../strings";
import ClipShareButton from "../clip_share_button";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "../components/dropdown_menu";
import { useState } from "react";
import DeleteModal from "../delete_modal";
import { downloadCallRecordingClip } from "../common/endpoints";
const ClipOptions = ({ deleteCallRecordingClip, downloadCallRecordingClip, }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { children: _jsx(EllipsisVerticalIcon, { className: "w-5 h-5" }) }), _jsxs(DropdownMenuContent, { children: [_jsx(DropdownMenuItem, Object.assign({ onClick: () => setDeleteModalOpen(true) }, { children: "Delete" })), _jsx(DropdownMenuItem, Object.assign({ onClick: downloadCallRecordingClip }, { children: "Download" }))] })] }), _jsx(DeleteModal, { deleteModal: deleteModalOpen, setShowDeleteModal: setDeleteModalOpen, title: "Are you sure?", description: "Delete this clip.", deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield deleteCallRecordingClip();
                    setDeleteModalOpen(false);
                }) })] }));
};
const Clip = ({ clip, selected, setSelectedClipId, deleteCallRecordingClip, downloadCallRecordingClip, }) => {
    return (_jsx("div", Object.assign({ className: classNames("p-4 w-full rounded-lg border flex flex-col gap-4 cursor-pointer hover:bg-wds-gray-2", selected ? "border-wds-blue-3" : "border-wds-gray-4"), onClick: () => setSelectedClipId(clip.id) }, { children: _jsxs("div", Object.assign({ className: "flex items-center gap-4" }, { children: [_jsx("div", Object.assign({ className: "h-10 w-20 rounded-sm flex justify-center items-center" }, { children: _jsx("img", { className: "w-20 h-11", src: clip.thumbnail }) })), _jsx("div", Object.assign({ className: "flex flex-col gap-1 flex-grow" }, { children: clip.clip_title ? (_jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsx("div", Object.assign({ className: "text-black font-bold" }, { children: clip.clip_title })), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-xs" }, { children: [formatSecondsToMinSec(clip.start_time_seconds * 1000), " -", " ", formatSecondsToMinSec(clip.end_time_seconds * 1000)] }))] }))) : (_jsxs("div", Object.assign({ className: "text-wds-gray-5" }, { children: [formatSecondsToMinSec(clip.start_time_seconds * 1000), " -", " ", formatSecondsToMinSec(clip.end_time_seconds * 1000)] }))) })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(ClipShareButton, { clipId: clip.id, callId: clip.call_id }), _jsx(ClipOptions, { deleteCallRecordingClip: deleteCallRecordingClip, downloadCallRecordingClip: downloadCallRecordingClip })] }))] })) })));
};
const Clips = ({ loading, clips, setSelectedClipId, selectedClipId, deleteCallRecordingClip, }) => {
    if (loading) {
        return _jsx("div", { children: "Loading..." });
    }
    if (!clips || clips.length === 0) {
        return _jsx("div", { children: "No clips found" });
    }
    return (_jsx("div", Object.assign({ className: "flex flex-col gap-4" }, { children: clips.map((clip) => (_jsx("div", { children: _jsx(Clip, { clip: clip, selected: selectedClipId === clip.id, setSelectedClipId: setSelectedClipId, deleteCallRecordingClip: () => deleteCallRecordingClip(clip.id), downloadCallRecordingClip: () => __awaiter(void 0, void 0, void 0, function* () {
                    const signedUrl = yield downloadCallRecordingClip(clip.id);
                    const link = document.createElement("a");
                    link.href = signedUrl;
                    link.download = "clip.mp4";
                    link.click();
                }) }) }, clip.id))) })));
};
export default Clips;
