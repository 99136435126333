var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { AccountOverviewBlock } from "./account_overview_block";
import { AccountCallsBlock } from "./account_calls_block";
import { useQuery, useQueryClient } from "react-query";
import { getAccountResearchBlockOutputs, getAllPreCallPrepBlocks, executeBricks, getBrickSetsByType, getPreCallPrepBlocksForTemplate, } from "../../common/endpoints";
import { AccountBrickOutputCollapsedCard } from "./v2/account_brick_collapsed_card";
import { AccountContactsTableBlock } from "./v2/contacts_table_block";
export const STATIC_ACCOUNT_BLOCK_IDS = {
    ACCOUNT_OVERVIEW: "account-overview",
    ACCOUNT_CALLS: "account-calls",
    ACCOUNT_CONTACTS: "account-contacts",
    ACCOUNT_DETAILS: "account-details",
};
// This hook manages everything related to account blocks:
// both static: (Account Overview, Account Details, Calls, Contacts)
// as well as dynamic research blocks that the user adds/creates.
export const useAccountResearchBlocks = ({ account, }) => {
    const queryClient = useQueryClient();
    const { data: allResearchBlocks } = useQuery({
        queryKey: ["researchBlocks"],
        queryFn: getAllPreCallPrepBlocks,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: accountResearchBlockOutputs } = useQuery({
        queryKey: ["researchBlockOutputs", account === null || account === void 0 ? void 0 : account.id],
        queryFn: () => {
            if (!account)
                return undefined;
            return getAccountResearchBlockOutputs(account.id);
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: !!account,
    });
    const { data: accountBrickSets } = useQuery({
        queryKey: ["accountBrickSets"],
        queryFn: () => getBrickSetsByType("ACCOUNT"),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: accountBrickSetsWithBricks } = useQuery({
        queryKey: [
            "getBricksInSet",
            (accountBrickSets !== null && accountBrickSets !== void 0 ? accountBrickSets : []).map((brickSet) => brickSet.id),
        ],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            if (!accountBrickSets) {
                return undefined;
            }
            const promises = accountBrickSets.map((brickSet) => __awaiter(void 0, void 0, void 0, function* () {
                const bricks = yield getPreCallPrepBlocksForTemplate(brickSet.id);
                bricks.sort(({ order: aOrder = 0 }, { order: bOrder = 0 }) => aOrder - bOrder);
                return Object.assign(Object.assign({}, brickSet), { blocks: bricks });
            }));
            return yield Promise.all(promises);
        }),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: !!accountBrickSets,
    });
    const applyUpdatedResearchBlockOutput = (accountResearchBlockOutput) => {
        queryClient.setQueryData(["researchBlockOutputs", account === null || account === void 0 ? void 0 : account.id], (prev) => {
            if (accountResearchBlockOutput.account_id !== (account === null || account === void 0 ? void 0 : account.id)) {
                console.error("Mismatch in account id");
                return prev || [];
            }
            if (!prev) {
                return [accountResearchBlockOutput];
            }
            if (prev.find((output) => output.id === accountResearchBlockOutput.id)) {
                return prev.map((output) => output.id === accountResearchBlockOutput.id
                    ? accountResearchBlockOutput
                    : output);
            }
            let updatedBlocks = [...prev, accountResearchBlockOutput];
            updatedBlocks.sort((a, b) => a.order - b.order);
            return updatedBlocks;
        });
        setGenerationInProgressBlockIds((prev) => {
            const newInProgressBlockIds = new Set(prev);
            newInProgressBlockIds.delete(accountResearchBlockOutput.research_block_id);
            return newInProgressBlockIds;
        });
    };
    const [generationInProgressBlockIds, setGenerationInProgressBlockIds] = useState(new Set());
    const [triggeredAllBlockOutputGeneration, setTriggeredAllBlockOutputGeneration,] = useState(false);
    const [highlightedBlockIds, setHighlightedBlockIds] = useState(new Set());
    useEffect(() => {
        // This hook is responsible for generating research block outputs
        // once for all the available research blocks when the user
        // lands on this page.
        // We do not have a concept of default templates for account
        // research blocks yet, and hence we generate all the outputs as
        // these are light-weight as compared to calls.
        // However, this should be re-visited once we add template support
        // for account research blocks as well.
        if (triggeredAllBlockOutputGeneration) {
            // We have already triggered this hook
            console.log("Already triggered running all block output generation");
            return;
        }
        if (!account || !allResearchBlocks) {
            // Required data is not fetched from the BE yet
            return;
        }
        setTriggeredAllBlockOutputGeneration(true);
        const accountResearchBlocks = allResearchBlocks.filter((block) => block.research_block_type === "ACCOUNT");
        const accountResearchBlockIds = accountResearchBlocks.map((block) => block.id);
        setGenerationInProgressBlockIds(new Set(accountResearchBlockIds));
        executeBricks(accountResearchBlockIds, applyUpdatedResearchBlockOutput, undefined, account.id);
    }, [account, allResearchBlocks]);
    // This ref is used to manipulate the scrolling of the blocks when
    // the user "jumps to" a different block using the dropdown.
    // Why ref instead of state?
    // This is the preferred way of such DOM manipulation in react
    // Details: https://react.dev/learn/manipulating-the-dom-with-refs
    const blocksRef = useRef(new Map());
    const getBlockRefMap = () => {
        return blocksRef.current;
    };
    // This is invoked when React mounts/unmounts each block.
    // Essentially we're setting the corresponding div ref in the map for
    // each account block when it is rendered, and unsetting it when it is
    // cleared from the DOM.
    const blocksRefCallback = (blockId, blockDivContainer) => {
        const blocksRefMap = getBlockRefMap();
        if (blockDivContainer) {
            blocksRefMap.set(blockId, blockDivContainer);
        }
        else {
            blocksRefMap.delete(blockId);
        }
    };
    const scrollToBlock = (blockId) => {
        const blocksRefMap = getBlockRefMap();
        if (blocksRefMap.has(blockId)) {
            blocksRefMap.get(blockId).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            setHighlightedBlockIds((prev) => {
                const newSet = new Set(prev);
                newSet.add(blockId);
                return newSet;
            });
            setTimeout(() => {
                setHighlightedBlockIds((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(blockId);
                    return newSet;
                });
            }, 2000);
        }
    };
    const generateResearchBlockOutput = (accountId, researchBlockId, forceRefresh) => {
        setGenerationInProgressBlockIds((prev) => {
            const newInProgressBlockIds = new Set(prev);
            newInProgressBlockIds.add(researchBlockId);
            return newInProgressBlockIds;
        });
        return executeBricks([researchBlockId], applyUpdatedResearchBlockOutput, undefined, accountId, forceRefresh);
    };
    const summaryBlockContent = accountResearchBlockOutputs === null || accountResearchBlockOutputs === void 0 ? void 0 : accountResearchBlockOutputs.filter((output) => output.delivery_targets.includes("AccountSummary"));
    // The order of the blocks here determines the order in which they
    // will be rendered in the UI.
    const accountBlocks = useMemo(() => {
        var _a, _b, _c;
        if (!account)
            return [];
        const staticBlocks = [
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW,
                label: "Overview",
                renderer: (props) => {
                    var _a, _b;
                    return (_jsx(AccountOverviewBlock, Object.assign({ account: account, brick: ((_a = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _a === void 0 ? void 0 : _a.research_block_id)
                            ? allResearchBlocks === null || allResearchBlocks === void 0 ? void 0 : allResearchBlocks.find((brick) => { var _a; return brick.id === ((_a = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _a === void 0 ? void 0 : _a.research_block_id); })
                            : undefined, accountSummaryBlock: summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0], generateResearchBlockOutput: generateResearchBlockOutput, outputGenerationInProgress: ((_b = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _b === void 0 ? void 0 : _b.research_block_id)
                            ? generationInProgressBlockIds.has(summaryBlockContent[0].research_block_id)
                            : false, scrollToBlock: scrollToBlock, onReadMoreClick: () => { }, highlight: highlightedBlockIds.has(STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW) }, props), STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW));
                },
                brick: allResearchBlocks === null || allResearchBlocks === void 0 ? void 0 : allResearchBlocks.find((brick) => { var _a; return brick.id === ((_a = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _a === void 0 ? void 0 : _a.research_block_id); }),
                accountResearchBlockOutput: summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0],
                regenerateOutput: () => __awaiter(void 0, void 0, void 0, function* () {
                    var _d;
                    if (!((_d = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _d === void 0 ? void 0 : _d.research_block_id)) {
                        return;
                    }
                    yield generateResearchBlockOutput(account.id, summaryBlockContent[0].research_block_id, 
                    /*forceRefresh=*/ true);
                }),
                outputGenerationInProgress: Boolean(((_a = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _a === void 0 ? void 0 : _a.research_block_id) &&
                    generationInProgressBlockIds.has((_b = summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0]) === null || _b === void 0 ? void 0 : _b.research_block_id)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS,
                label: "Calls",
                renderer: (_props) => (_jsx(AccountCallsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS)),
                regenerateOutput: () => __awaiter(void 0, void 0, void 0, function* () { }),
                outputGenerationInProgress: false,
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CONTACTS,
                label: "Contacts",
                renderer: (props) => (_jsx(AccountContactsTableBlock, Object.assign({ account: account, allResearchBlocks: allResearchBlocks !== null && allResearchBlocks !== void 0 ? allResearchBlocks : [], onContactDraftEmailClick: (_) => { }, onContactMoreDetailsClick: (_) => { } }, props), STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CONTACTS)),
                regenerateOutput: () => __awaiter(void 0, void 0, void 0, function* () { }),
                outputGenerationInProgress: false,
            },
        ];
        if (!allResearchBlocks) {
            return [...staticBlocks];
        }
        const orderedBrickIdsFromBrickSets = (_c = accountBrickSetsWithBricks === null || accountBrickSetsWithBricks === void 0 ? void 0 : accountBrickSetsWithBricks.sort(({ order: aOrder = 0 }, { order: bOrder = 0 }) => aOrder - bOrder).flatMap((brickSet) => brickSet.blocks.map((brick) => brick.id))) !== null && _c !== void 0 ? _c : [];
        const researchBlocks = (accountResearchBlockOutputs === null || accountResearchBlockOutputs === void 0 ? void 0 : accountResearchBlockOutputs.filter((output) => {
            return output.delivery_targets.includes("AccountUI");
        }).sort((a, b) => {
            const aIndex = orderedBrickIdsFromBrickSets.indexOf(a.research_block_id);
            const bIndex = orderedBrickIdsFromBrickSets.indexOf(b.research_block_id);
            // If the brick is not present in the brickset, it should be rendered last
            if (aIndex === -1) {
                return 1;
            }
            if (bIndex === -1) {
                return -1;
            }
            return aIndex - bIndex;
        }).map((output) => {
            const researchBlock = allResearchBlocks.find((block) => block.id === output.research_block_id);
            return {
                id: `${output.id}`,
                label: researchBlock.name,
                brick: researchBlock,
                accountResearchBlockOutput: output,
                regenerateOutput: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield generateResearchBlockOutput(account.id, researchBlock.id, 
                    /*forceRefresh=*/ true);
                }),
                outputGenerationInProgress: generationInProgressBlockIds.has(output.research_block_id),
                renderer: (props) => (_jsx(AccountBrickOutputCollapsedCard, Object.assign({ account: account, brick: researchBlock, accountResearchBlockOutput: output, generateResearchBlockOutput: generateResearchBlockOutput, outputGenerationInProgress: generationInProgressBlockIds.has(output.research_block_id), onReadMoreClick: () => { }, highlight: highlightedBlockIds.has(`${output.id}`) }, props), `${output.id}`)),
            };
        })) || [];
        return [...staticBlocks, ...researchBlocks];
    }, [
        account,
        allResearchBlocks,
        accountResearchBlockOutputs,
        generationInProgressBlockIds,
        highlightedBlockIds,
        accountBrickSetsWithBricks,
    ]);
    return {
        staticBlocksCount: 4,
        accountBlocks,
        scrollToBlock,
        blocksRefCallback,
        accountBrickSets,
        accountBrickSetsWithBricks,
        allBricks: allResearchBlocks,
        setHighlightedBlockIds,
    };
};
