var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import BaseModal from "./base_modal";
import BaseTable2 from "./base_table2";
import { createMeetingTemplate, createPreCallPrepBlock, deleteMeetingTemplate, getAllPreCallPrepBlocks, getAllPreCallPrepTemplates, getAllTrackerBrickSets, getMeetingTemplates, getPlaybooks, updateMeetingTemplate, updatePreCallPrepBlock, } from "./common/endpoints";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CenterScreen } from "./settings/styled_components";
import WaitingSpinner from "./waiting_spinner";
import { useLocation } from "react-router-dom";
import CreateOrEditBriefing from "./pre_call_prep/create_or_edit_briefing";
import { Button } from "./components/button";
const MeetingTemplateTable = () => {
    var _a, _b, _c, _d, _e;
    const location = useLocation();
    const queryClient = useQueryClient();
    const [templateSelectionBlockOpen, setTemplateSelectionBlockOpen] = useState(false);
    const [templateSelectionPrompt, setTemplateSelectionPrompt] = useState("");
    const [selectedDataSources, setSelectedDataSources] = useState(new Set());
    const { data: templates, isLoading: templatesLoading, isError: templatesError, } = useQuery(["meeting_templates"], getMeetingTemplates, {
        refetchOnWindowFocus: false,
    });
    const { data: playbooks, isLoading: playbooksLoading, isError: playbooksError, } = useQuery(["playbooks"], () => getPlaybooks(), {
        refetchOnWindowFocus: false,
    });
    const { data: preCallTemplates, isLoading: preCallTemplatesLoading, isError: preCallTemplatesError, } = useQuery(["pre_call_templates"], () => getAllPreCallPrepTemplates(), {
        refetchOnWindowFocus: false,
    });
    const { data: trackerBrickSets, isLoading: trackerBrickSetsLoading, isError: trackerBrickSetsError, } = useQuery(["tracker_brick_sets"], () => getAllTrackerBrickSets(), {
        refetchOnWindowFocus: false,
    });
    const { data: selectTemplateBlock } = useQuery(["select_template_block"], () => __awaiter(void 0, void 0, void 0, function* () {
        let allBlocks = yield getAllPreCallPrepBlocks();
        const selectionBlocks = allBlocks.filter((block) => block.research_block_type === "MEETING_TEMPLATE_ASSIGNMENT");
        if (selectionBlocks.length > 0) {
            return selectionBlocks[0];
        }
        return null;
    }), { refetchOnWindowFocus: false });
    useEffect(() => {
        if (selectTemplateBlock) {
            setTemplateSelectionPrompt(selectTemplateBlock.prompt);
            setSelectedDataSources(new Set(selectTemplateBlock.data_sources));
        }
    }, [selectTemplateBlock]);
    const [EditTemplateModalOpen, setEditTemplateModalOpen] = useState(false);
    const templateCreate = useMutation({
        mutationFn: createMeetingTemplate,
        onSuccess: (template) => {
            queryClient.setQueryData(["meeting_templates"], (oldData) => {
                return oldData ? [...oldData, template] : [template];
            });
            setEditTemplateModalOpen(false);
        },
    });
    const templateUpdate = useMutation({
        mutationFn: updateMeetingTemplate,
        onSuccess: (template) => {
            queryClient.setQueryData(["meeting_templates"], (oldData) => {
                return oldData
                    ? oldData.map((t) => (t.id === template.id ? template : t))
                    : [template];
            });
            setEditTemplateModalOpen(false);
        },
    });
    const saveTemplateSelectionBlock = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectTemplateBlock) {
            yield updatePreCallPrepBlock(selectTemplateBlock.id, "Template Selection Rules", Array.from(selectedDataSources), templateSelectionPrompt, [], "OPENAI_FLAGSHIP");
        }
        else {
            yield createPreCallPrepBlock("Template Selection Rules", Array.from(selectedDataSources), templateSelectionPrompt, [], "MEETING_TEMPLATE_ASSIGNMENT");
        }
        yield queryClient.invalidateQueries(["select_template_block"]);
        setTemplateSelectionBlockOpen(false);
    });
    const [selectedTemplateId, setSelectedTemplateId] = useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.templateId) !== null && _b !== void 0 ? _b : null);
    if (templatesLoading ||
        templatesError ||
        templates === undefined ||
        playbooksLoading ||
        playbooksError ||
        playbooks === undefined ||
        preCallTemplatesLoading ||
        trackerBrickSetsLoading ||
        trackerBrickSetsError ||
        trackerBrickSets === undefined ||
        preCallTemplatesError ||
        preCallTemplates === undefined) {
        return (_jsx(CenterScreen, { children: _jsx(WaitingSpinner, { text: "Loading..." }) }));
    }
    const selectedTemplate = templates.find((template) => template.id === selectedTemplateId);
    const setModalOpen = (open) => {
        setEditTemplateModalOpen(open);
        if (!open) {
            setSelectedTemplateId(null);
        }
    };
    const meetingTemplateColumns = [
        { header: "Template Name", accessorKey: "name" },
        { header: "Description", accessorKey: "description" },
        { header: "Tracker Set", accessorKey: "tracker_brick_set_name" },
        { header: "Research Set", accessorKey: "pre_call_template_name" },
        {
            header: "CRM Meeting Type",
            accessorKey: "meeting_type_for_crm",
        },
    ];
    const meetingTemplateFields = [
        {
            name: "Name",
            type: "text",
            value: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.name) || "",
            required: true,
            placeholder: "Enter a name for the meeting template",
        },
        {
            name: "Description",
            type: "text",
            value: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.description) || "",
            required: true,
            placeholder: "Enter a description for the meeting template",
        },
        {
            name: "Description for LLM",
            type: "text",
            value: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.description_for_llm) || "",
            required: true,
            placeholder: "Enter a description for the LLM; can be the same as description.",
        },
        {
            name: "Playbook",
            type: "select",
            value: ((_c = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.playbook_id) !== null && _c !== void 0 ? _c : playbooks.length > 0)
                ? playbooks[0].id
                : "",
            required: true,
            options: playbooks.map((playbook) => ({
                label: playbook.name,
                value: playbook.id,
            })),
        },
        {
            name: "Tracker Set",
            type: "select",
            value: ((_d = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.tracker_brick_set_id) !== null && _d !== void 0 ? _d : trackerBrickSets.length > 0)
                ? trackerBrickSets[0].id
                : "",
            required: true,
            options: trackerBrickSets.map((brickSet) => ({
                label: brickSet.name,
                value: brickSet.id,
            })),
        },
        {
            name: "Research Set",
            type: "select",
            value: ((_e = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.pre_call_template_id) !== null && _e !== void 0 ? _e : preCallTemplates.length > 0)
                ? preCallTemplates[0].id
                : "",
            required: true,
            options: preCallTemplates.map((template) => ({
                label: template.name,
                value: template.id,
            })),
        },
        {
            name: "Meeting type for CRM",
            type: "text",
            value: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.meeting_type_for_crm) || "",
            required: false,
            placeholder: "Enter the meeting type to be pushed to CRM",
        },
    ];
    return (_jsxs("div", Object.assign({ className: "p-8" }, { children: [_jsx(BaseTable2, { title: "Meeting Templates", rows: templates, columns: meetingTemplateColumns, addCallback: () => {
                    setEditTemplateModalOpen(true);
                }, expandCallback: (row) => {
                    setSelectedTemplateId(row.id);
                    setEditTemplateModalOpen(true);
                }, additionalActions: [
                    {
                        label: "Set Template Selection Prompt",
                        callback: () => {
                            setTemplateSelectionBlockOpen(true);
                        },
                    },
                ] }), _jsx(BaseModal, Object.assign({ open: templateSelectionBlockOpen, setOpen: setTemplateSelectionBlockOpen, title: "Set Template Selection Prompt", description: "This prompt controls how the AI selects a template for a meeting.", fields: [], submitCallback: saveTemplateSelectionBlock, submitText: "Save", disableSubmit: false }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: "ACCOUNT", name: "Template Selection Rules", setName: () => { }, prompt: templateSelectionPrompt, setPrompt: setTemplateSelectionPrompt, selectedDataSources: selectedDataSources, setSelectedDataSources: setSelectedDataSources, isGalleryBrick: !!(selectTemplateBlock === null || selectTemplateBlock === void 0 ? void 0 : selectTemplateBlock.gallery_brick_id) }) })), _jsxs(BaseModal, Object.assign({ title: selectedTemplate ? "Edit Template" : "Add Template", description: "A meeting template describes a common type of meeting, like an internal meeting, discovery call, or demo call.", fields: meetingTemplateFields, open: EditTemplateModalOpen, setOpen: setModalOpen, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    const name = fields[0].value;
                    const description = fields[1].value;
                    const description_for_llm = fields[2].value;
                    const playbook_id = fields[3].value;
                    const tracker_brick_set_id = fields[4].value;
                    const pre_call_template_id = fields[5].value;
                    const meeting_type_for_crm = fields[6].value;
                    if (selectedTemplate) {
                        yield templateUpdate.mutateAsync({
                            meeting_template_id: selectedTemplate.id,
                            pre_call_template_id,
                            playbook_id,
                            tracker_brick_set_id,
                            name,
                            description,
                            description_for_llm,
                            meeting_type_for_crm,
                        });
                    }
                    else {
                        yield templateCreate.mutateAsync({
                            pre_call_template_id,
                            tracker_brick_set_id,
                            playbook_id,
                            name,
                            description,
                            description_for_llm,
                            meeting_type_for_crm,
                        });
                    }
                    setSelectedTemplateId(null);
                }), submitText: selectedTemplate ? "Update Template" : "Create Template" }, { children: [" ", selectedTemplate && (_jsx(Button, Object.assign({ variant: "destructive", onClick: () => {
                            if (selectedTemplate) {
                                deleteMeetingTemplate(selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id).then(() => {
                                    queryClient.invalidateQueries(["meeting_templates"]);
                                    setEditTemplateModalOpen(false);
                                });
                            }
                        } }, { children: "Delete Template" })))] }))] })));
};
export default MeetingTemplateTable;
