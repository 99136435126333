var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PostCallOutputTarget, } from "../types";
import { useEffect, useMemo, useRef, useState } from "react";
import * as endpoints from "../common/endpoints";
import { isTenantConnectedToExternalCrm } from "./utils";
import useIntegrations from "./use_integrations";
import FieldMappingCard from "./field_mapping_card";
import { FieldMappingCardSummary } from "./field_mapping_card";
import usePostCallOutputs from "./use_post_call_outputs";
import * as actions from "./actions";
import { CRM_DATA_UPDATE_TARGETS, INTERNAL_MESSAGING_TARGETS, } from "./data_processing_config";
import amplitudeInstance from "../amplitude";
import { useStreamSocket, } from "../common/stream_socket";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
const DataProcessingTab = (props) => {
    const executeTrackerBricksNativelyEnabled = useFeatureFlagEnabled("EXECUTE_TRACKER_BRICKS_NATIVELY");
    const { postCallOutputMap, updatePostCallOutputInState, fetchPostCallOutput, } = usePostCallOutputs({
        callId: props.call.id,
    });
    // This set is used to identify post-call output items that are getting regenerated
    const [postCallOutputsRegenerationIdsSet, setPostCallOutputsRegenerationIdsSet,] = useState(new Set());
    const [connectedIntegrations, setConnectedIntegrations] = useState([]);
    const [integrationsData, setIntegrationsData] = useState([]);
    const [undoStack, setUndoStack] = useState(new Map());
    const { integrations, loaded } = useIntegrations();
    const provenanceRecords = useMemo(() => {
        const provenances = new Map();
        for (const noteSection of props.callNotes) {
            for (const note of noteSection.notes) {
                if (!note.provenances)
                    continue;
                for (const provenance of note.provenances) {
                    provenances.set(provenance.id, provenance);
                }
            }
        }
        return Array.from(provenances.values());
    }, [props.callNotes]);
    // This state is used to render the loading state on the field mapping card
    // while the item is being updated or processed.
    // The key is the post call output item id and the value is a string indicating
    // the status of the item.
    const [itemLoadingStatus, setItemLoadingStatus] = useState(new Map());
    // Ref for getting the latest state value during playbook item updates received via polling
    const postCallOutputMapRef = useRef(postCallOutputMap);
    useEffect(() => {
        postCallOutputMapRef.current = postCallOutputMap;
    }, [postCallOutputMap]);
    const streamSocket = useStreamSocket();
    useEffect(() => {
        streamSocket.addListener("post_call_created_from_notes", handlePostCallRegenerated);
        streamSocket.connectToStreamSocket(props.call.id);
    }, []);
    const handlePostCallRegenerated = (message) => {
        if (!message.post_call_created_from_notes ||
            !message.post_call_created_from_notes.stage_content ||
            !message.post_call_created_from_notes.stage_content.sourcePlaybookItemId) {
            return;
        }
        const playbookItemId = message.post_call_created_from_notes.stage_content.sourcePlaybookItemId;
        Array.from(postCallOutputMapRef.current.entries()).forEach(([itemId, { item }]) => {
            var _a;
            if (((_a = item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.id) === playbookItemId) {
                fetchPostCallOutput(itemId).then(() => {
                    updateItemLoadingStatus(itemId, "LOADED");
                    setPostCallOutputsRegenerationIdsSet((prev) => {
                        const updatedIdsSet = new Set(prev);
                        updatedIdsSet.delete(itemId);
                        return updatedIdsSet;
                    });
                });
            }
        });
    };
    const updateUndoStack = (itemId, value) => {
        setUndoStack((prev) => {
            const stack = prev.get(itemId) || [];
            return new Map(prev.set(itemId, [...stack, value]));
        });
    };
    const updateItemLoadingStatus = (postCallOutputId, status) => {
        setItemLoadingStatus((prev) => {
            const updatedMap = new Map(prev);
            updatedMap.set(postCallOutputId, status);
            return updatedMap;
        });
    };
    useEffect(() => {
        if (!loaded) {
            setConnectedIntegrations([]);
        }
        setConnectedIntegrations(integrations);
    }, [integrations, loaded]);
    useEffect(() => {
        setItemLoadingStatus((prev) => {
            const updatedMap = new Map(prev);
            Array.from(postCallOutputMap.keys()).forEach((itemId) => {
                if (!updatedMap.has(itemId)) {
                    updatedMap.set(itemId, "LOADED");
                }
            });
            return updatedMap;
        });
    }, [postCallOutputMap]);
    const postCallOutputs = useMemo(() => {
        return Array.from(postCallOutputMap.values());
    }, [postCallOutputMap]);
    const postCallOutputItemIdsToRender = useMemo(() => {
        return postCallOutputs.map(({ item }) => item.id);
    }, [postCallOutputs]);
    const onSendItemClick = (itemId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        updateItemLoadingStatus(itemId, "SYNCING");
        const postCallOutputToExecute = postCallOutputMap.get(itemId)
            .item;
        const updatePostCallOutputResponse = yield endpoints.executePostCallOutput(postCallOutputToExecute.id, (_b = (_a = postCallOutputToExecute.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputToExecute.proposedValue) !== null && _b !== void 0 ? _b : "");
        if (updatePostCallOutputResponse.status === "FAILURE") {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updatePostCallOutputInState(updatePostCallOutputResponse.postCallOutput);
        updateItemLoadingStatus(itemId, "LOADED");
    });
    const onDataItemValueUpdate = (itemId, value, undoClicked) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const postCallOutputToUpdate = postCallOutputMap.get(itemId).item;
        const updatedDataItem = Object.assign(Object.assign({}, postCallOutputToUpdate), { state: "PENDING", valueToUpdate: value });
        updateItemLoadingStatus(itemId, "UPDATING");
        const updatePostCallOutputPromise = endpoints.updatePostCallOutput(updatedDataItem.id, updatedDataItem);
        // We update the items in state before waiting for the promise to resolve
        // becase we want to render the new state as soon as the user clicks on the
        // "Undo" button or save the edits.
        if (!undoClicked) {
            updateUndoStack(itemId, (_d = (_c = postCallOutputToUpdate.valueToUpdate) !== null && _c !== void 0 ? _c : postCallOutputToUpdate.proposedValue) !== null && _d !== void 0 ? _d : "");
        }
        updatePostCallOutputInState(updatedDataItem);
        const updatePostCallOutputResponse = yield updatePostCallOutputPromise;
        if (updatePostCallOutputResponse.status === "FAILURE") {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updateItemLoadingStatus(itemId, "LOADED");
    });
    const onDataItemTargetUpdate = (itemId, target, targetDetails, executePostCallAfterNMinutes, updateType) => __awaiter(void 0, void 0, void 0, function* () {
        const dataItem = postCallOutputMap.get(itemId)
            .item;
        const updatedDataItem = Object.assign(Object.assign({}, dataItem), { fieldMap: Object.assign(Object.assign({}, dataItem.fieldMap), { target,
                targetDetails,
                executePostCallAfterNMinutes,
                updateType }), target: target, targetDetails: targetDetails, valueToUpdate: undefined });
        updateItemLoadingStatus(itemId, "UPDATING");
        const response = yield endpoints.updatePostCallOutput(updatedDataItem.id, updatedDataItem);
        updatePostCallOutputInState(Object.assign(Object.assign({}, updatedDataItem), response.postCallOutput));
        if (response.status === "FAILURE" && response.error) {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updateItemLoadingStatus(itemId, "LOADED");
    });
    useEffect(() => {
        if (connectedIntegrations.length === 0) {
            return;
        }
        const connectedIntegrationTargets = connectedIntegrations.map((i) => i.name);
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.SALESFORCE)) {
            actions.fetchSalesforceIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.HUBSPOT)) {
            actions.fetchHubspotIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
        if (!isTenantConnectedToExternalCrm(connectedIntegrations)) {
            // Wiser CRM is automatically connected if the tenant is not using any external CRM.
            actions.fetchWiserCrmIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
    }, [connectedIntegrations]);
    const handlePostCallBrickRegenerated = (brickId) => {
        // All the outputs come from the post call output here.
        Array.from(postCallOutputMapRef.current.entries()).forEach(([itemId, { item }]) => {
            var _a;
            if (((_a = item.fieldMap.sourceBrick) === null || _a === void 0 ? void 0 : _a.id) === brickId) {
                fetchPostCallOutput(itemId).then(() => {
                    updateItemLoadingStatus(itemId, "LOADED");
                    setPostCallOutputsRegenerationIdsSet((prev) => {
                        const updatedIdsSet = new Set(prev);
                        updatedIdsSet.delete(itemId);
                        return updatedIdsSet;
                    });
                });
            }
        });
    };
    const onPostCallOutputRegenerateBrickClicked = (itemId) => {
        if (!postCallOutputMap.has(itemId)) {
            return;
        }
        if (!postCallOutputMap.get(itemId).item.fieldMap.sourceBrick) {
            return;
        }
        updateItemLoadingStatus(itemId, "UPDATING");
        setPostCallOutputsRegenerationIdsSet((prev) => {
            const updatedIdsSet = new Set(prev);
            updatedIdsSet.add(itemId);
            return updatedIdsSet;
        });
        const sourceBrickId = postCallOutputMap.get(itemId).item.fieldMap.sourceBrick.id;
        endpoints.executeBricks([sourceBrickId], () => {
            // Outputs here will come from the post call output.
            handlePostCallBrickRegenerated(sourceBrickId);
        }, props.call.id, undefined /* accountId */, true /* forceRefresh */);
    };
    const onPostCallOutputRegenerateClicked = (itemId) => {
        var _a;
        if (!postCallOutputMap.has(itemId)) {
            return;
        }
        if (!postCallOutputMap.get(itemId).item.fieldMap.sourcePlaybookItem) {
            return;
        }
        updateItemLoadingStatus(itemId, "UPDATING");
        setPostCallOutputsRegenerationIdsSet((prev) => {
            const updatedIdsSet = new Set(prev);
            updatedIdsSet.add(itemId);
            return updatedIdsSet;
        });
        streamSocket.send(JSON.stringify({
            type: "register_user_trigger",
            trigger_type: "GPT4_NOTE_GENERATION",
            playbook_item_id: (_a = postCallOutputMap.get(itemId).item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.id,
        }));
    };
    const renderSection = (sectionTitle, postCallItemIds, showTrackerNotes) => {
        if (postCallItemIds.length === 0) {
            return null;
        }
        return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "self-stretch font-bold text-base" }, { children: sectionTitle })), postCallItemIds.map((itemId) => (_jsx("div", Object.assign({ className: "self-stretch" }, { children: postCallOutputMap.has(itemId) &&
                        itemLoadingStatus.has(itemId) &&
                        postCallOutputMap.get(itemId).isLoaded ? (_jsx(FieldMappingCard, { postCallOutputItem: postCallOutputMap.get(itemId).item, undoStack: undoStack.get(itemId) || [], call: props.call, itemLoadingStatus: itemLoadingStatus.get(itemId), onSendClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            amplitudeInstance.track("Click Send Post-Call Item", {
                                targetType: postCallOutputMap.get(itemId).item.target,
                            });
                            yield onSendItemClick(itemId);
                        }), onDataItemValueUpdate: (value, undoClicked) => __awaiter(void 0, void 0, void 0, function* () {
                            yield onDataItemValueUpdate(itemId, value, undoClicked);
                        }), onDataItemTargetUpdate: (target, targetDetails, executePostCallAfterNMinutes, updateType) => __awaiter(void 0, void 0, void 0, function* () {
                            yield onDataItemTargetUpdate(itemId, target, targetDetails, executePostCallAfterNMinutes, updateType);
                        }), toggleDataItemEditOpen: (value) => {
                            if (value) {
                                updateItemLoadingStatus(itemId, "USER_EDITING");
                            }
                            else {
                                updateItemLoadingStatus(itemId, "LOADED");
                            }
                        }, postCallIntegrations: connectedIntegrations, integrationsData: integrationsData, showTrackerNotes: showTrackerNotes, executePostCallAfterNMinutes: postCallOutputMap.get(itemId).item.fieldMap
                            .executePostCallAfterNMinutes, postCallOutputRegenerationInProgress: !!postCallOutputsRegenerationIdsSet.has(itemId), onPostCallOutputRegenerateClicked: executeTrackerBricksNativelyEnabled
                            ? () => {
                                onPostCallOutputRegenerateBrickClicked(itemId);
                            }
                            : () => onPostCallOutputRegenerateClicked(itemId), provenanceRecords: provenanceRecords }, itemId)) : (_jsx(FieldMappingCardSummary, { postCallOutputItem: postCallOutputMap.get(itemId).item }, itemId)) }), itemId)))] })));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col w-full gap-6 items-start" }, { children: [renderSection("Follow-up email", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => {
                var _a;
                return ((_a = postCallOutputMap.get(itemId).item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.call_stage) === "FOLLOW_UP_EMAIL";
            }), 
            /* showTrackerNotes= */ false), renderSection("Internal messaging", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => INTERNAL_MESSAGING_TARGETS.includes(postCallOutputMap.get(itemId).item.target)), 
            /* showTrackerNotes= */ false), renderSection("Push meeting to CRM", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => postCallOutputMap.get(itemId).item.target ===
                PostCallOutputTarget.CRM_EVENT), 
            /* showTrackerNotes= */ false), renderSection("Update CRM data", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter(
            // Follow-up emails currently have no target associated (will eventually
            // get GMAIL as the target), but we don't want to render them in the un-mapped
            // list here because it is already rendered at the top.
            (itemId) => {
                var _a;
                return ((_a = postCallOutputMap.get(itemId).item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.call_stage) !== "FOLLOW_UP_EMAIL";
            })
                .filter((itemId) => !postCallOutputMap.get(itemId).item.target ||
                CRM_DATA_UPDATE_TARGETS.includes(postCallOutputMap.get(itemId).item.target)), 
            /* showTrackerNotes= */ true)] })));
};
export default DataProcessingTab;
