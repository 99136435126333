var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CrmObjectTypeEnum, } from "../types";
import Request from "./request";
import { getConvertedJsonQuery } from "./query_builder/json_query_converter";
const ignore404 = (error) => {
    var _a;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
        return true;
    }
    return false;
};
export const getTenants = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_tenants`);
    return response;
});
export const setActiveTenant = (tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/api/set_active_tenant`, {
        tenant_id: tenantId,
    });
    return response;
});
export const saveNote = (callId, playbookItemId, notes) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/call_nav/save_note`, {
        call_id: callId,
        playbook_item_id: playbookItemId,
        notes,
    });
});
export const getCallTranscript = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_call_transcript`, {
        call_id: callId,
    });
    return response;
});
export const createLiveCall = (name) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/create_live_call`, {
        name: name,
        zoom_meeting_id: null,
        zoom_passcode: null,
        call_time: new Date().toISOString(),
    });
    return response.id;
});
export const updateLiveCall = (liveCall) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/api/update_live_call`, liveCall);
    return response.id;
});
export const updateCallType = (ids, callType) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/api/update_call_type`, {
        ids,
        call_type: callType,
    });
});
export const rebuildPostCall = (ids) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/api/rebuild_post_call`, {
        ids,
    });
});
export const setCallAccountAndOpportunity = (callId, accountId, opportunityId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/api/set_call_account_and_opportunity`, {
        id: callId,
        account_id: accountId,
        opportunity_id: opportunityId,
    });
});
export const setCallLeadIds = (callId, leadIds) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/api/set_call_lead_ids`, {
        id: callId,
        lead_ids: leadIds,
    });
});
export const getAllOpportunitiesForAccount = (accountId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_all_opportunities_for_account`, {
        account_id: accountId,
    });
    return response.opportunities;
});
export const getPlaybooks = (tenant_id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_playbooks`, tenant_id
        ? {
            tenant_id,
        }
        : null);
    return response.playbooks;
});
export const getPlaybooksPaginated = (page, pageSize) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_playbooks_paginated", {
        page,
        page_size: pageSize,
    });
    return response;
});
export const fetchAllPlaybooksPaginated = (page_size) => __awaiter(void 0, void 0, void 0, function* () {
    const firstPage = yield getPlaybooksPaginated(/* page= */ 1, page_size);
    const totalPages = Math.floor((firstPage.total + page_size - 1) / page_size);
    let promises = [];
    for (let page = 1; page <= totalPages; page++) {
        promises.push(getPlaybooksPaginated(/* page= */ page, page_size));
    }
    const respones = yield Promise.all(promises);
    return respones
        .flat()
        .map((resp) => resp.data)
        .flat();
});
export const getPlaybook = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_playbook`, {
        playbook_id: id,
    }, undefined, { customErrorHandler: ignore404 });
    return response;
});
export const getPlaybookForLiveCall = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/get_playbook_for_live_call`, {
        call_id: callId,
    });
    return response.playbook;
});
export const getTrackerBrickSetForLiveCall = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_brick_sets_for_live_call`, {
        call_id: callId,
    });
    return response.TrackerBrickSet;
});
export const removePlaybookGroupFromLiveCall = (callId, playbookItemGroupId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/remove_group_from_live_call`, {
        call_id: callId,
        playbook_item_group_id: playbookItemGroupId,
    });
    return response;
});
export const addPlaybookGroupToLiveCall = (callId, playbookItemGroupId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/add_playbook_group_to_live_call`, {
        call_id: callId,
        playbook_item_group_id: playbookItemGroupId,
    });
    return response;
});
export const getPlaybookItemGroups = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_playbook_item_groups`);
    return response.playbook_item_groups;
});
export const reorderPlaybookGroupsInLiveCall = (callId, order) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/reorder_playbook_groups_in_live_call`, {
        call_id: callId,
        order,
    });
    return response.playbook;
});
export const exportTenant = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/export_tenant`);
    return response;
});
export const importTenant = (url, replaceExisting) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/import_tenant`, {
        url,
        replace_existing: replaceExisting,
    });
    return response;
});
export const reorderPlaybookGoals = (playbookId, order) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/reorder_playbook_groups`, {
        playbook_id: playbookId,
        order,
    });
    return response;
});
export const setCallTrackerBrickSet = (callId, tracker_brick_set_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post(`/call_nav/set_call_tracker_brick_set`, {
        call_id: callId,
        tracker_brick_set_id: tracker_brick_set_id,
    });
});
export const setCallPlaybook = (callId, playbookId) => __awaiter(void 0, void 0, void 0, function* () {
    let response;
    if (playbookId === null) {
        response = yield Request.post(`/call_nav/unset_call_playbook`, {
            call_id: callId,
        });
    }
    else {
        response = yield Request.post(`/call_nav/set_call_playbook`, {
            call_id: callId,
            playbook_id: playbookId,
        });
    }
    return response;
});
export const removeGroupFromPlaybook = (playbookId, goalId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/call_nav/remove_group_from_playbook`, {
        playbook_id: playbookId,
        playbook_item_group_id: goalId,
    });
});
export const getLiveCallsByDate = (date, tz_offset, attach_call_summaries = false) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_live_calls_by_date", {
        date,
        tz_offset,
        attach_call_summaries,
    });
    return response;
});
export const tagsDataFetcher = (query) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_tags", {
        query,
    });
    return {
        results: response.results.map((tag) => ({
            value: tag,
            label: tag,
        })),
    };
});
export const createTag = (name) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/api/create_tag", {
        name,
    });
});
export const getLiveCall = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/get_live_call", {
        call_id: callId,
    });
});
export const getLiveCallWithDebugInfo = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/get_live_call", {
        call_id: callId,
        include_debug_info: true,
    });
});
export const fetchParticipantsAutocomplete = (query) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/fetch_participants_autocomplete", {
        query,
    });
    return response.contacts;
});
export const createContactFromEmail = (email) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/api/create_contact_from_email", {
        email,
    });
});
export const getChatHistory = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_chat_history", {
        call_id: callId,
    });
});
export const syncWithCalendar = (date, tz_offset) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/sync_with_calendar`, { date, tz_offset }, undefined, {
        customErrorHandler: (error) => {
            // just log the error & then silently ignore it
            console.error("Error from sync_with_calendar:" + error);
            return true;
        },
    });
    return response;
});
export const authorizeForGmailRead = (callbackAfterAuthorization) => {
    // Try to get the oauth2 authorization & retry.
    let client_id = "692199444018-jte35kjqe5oa4ud36cgj18saldoe57cj.apps.googleusercontent.com";
    if (window.location.href.includes("localhost")) {
        client_id =
            "692199444018-le4focqbjc850bplhf3fban4pg72enih.apps.googleusercontent.com";
    }
    const client = google.accounts.oauth2.initCodeClient({
        client_id,
        scope: "https://www.googleapis.com/auth/gmail.readonly",
        callback: (gmail_authz_response) => {
            // send the auth2 code to backend, which will fetch access token, then call syncWithCalendar again.
            Request.post("/call_nav/set_gmail_authz_code", {
                gmail_authz_code: gmail_authz_response.code,
            }).then(callbackAfterAuthorization);
        },
        // We use the hint to avoid an extra account picker for the user to click on.
        ux_mode: "popup",
        error_callback: (error) => {
            console.error("Error from initTokenClient:" + error);
        },
    });
    client.requestCode();
};
export const authorizeForCalendarRead = (callbackAfterAuthorization) => {
    // Try to get the oauth2 authorization & retry.
    let client_id = "692199444018-jte35kjqe5oa4ud36cgj18saldoe57cj.apps.googleusercontent.com";
    if (window.location.href.includes("localhost")) {
        client_id =
            "692199444018-le4focqbjc850bplhf3fban4pg72enih.apps.googleusercontent.com";
    }
    const client = google.accounts.oauth2.initCodeClient({
        client_id,
        scope: "https://www.googleapis.com/auth/calendar.readonly",
        callback: (calendar_authz_response) => {
            // send the auth2 code to backend, which will fetch access token, then call syncWithCalendar again.
            Request.post("/call_nav/set_authz_code", {
                google_calendar_authz_code: calendar_authz_response.code,
            }).then(callbackAfterAuthorization);
        },
        // We use the hint to avoid an extra account picker for the user to click on.
        ux_mode: "popup",
        error_callback: (error) => {
            console.error("Error from initTokenClient:" + error);
        },
    });
    client.requestCode();
};
export const getCalendarIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_calendar_integration_data`);
    return response;
});
export const getGmailIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_gmail_integration_data`);
    return response;
});
export const removeGmailAuth = () => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/api/remove_gmail_auth");
});
export const removeCalendarAuth = () => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/api/remove_calendar_auth");
});
export const getBestCardFromStringInput = (inputString) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/call_nav/get_best_card_from_string_input`, {
        inputString: inputString,
    });
    return response.bestCard;
});
export const getStreamReduceLogs = (msecBack) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_stream_reduce_logs`, {
        msec_back: msecBack,
    });
    return response;
});
export const getCardNames = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_live_card_names`);
    return response.live_cards;
});
export const getEnabledFeatures = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_enabled_features`);
    return response.enabledFeatures;
});
export const getFeatureFlagData = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_feature_flag_data`);
    return response;
});
export const getFeatureFlags = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_feature_flags");
});
export const toggleFeatureEnabledForAll = (name) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/api/toggle_feature_enabled_for_all`, {
        name,
    });
    return response;
});
export const toggleFeatureForUser = (name) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/api/toggle_feature_for_user`, { name });
    return response;
});
export const toggleFeatureForTenant = (name, tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post(`/api/toggle_feature_for_tenant`, {
        name,
        tenant_id: tenantId,
    });
    return response;
});
export const createEvalExample = (dataset, callId, timestamp, expectation, exampleType) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/api/create_eval_example`, {
        dataset: dataset,
        callId: callId,
        timestamp: timestamp,
        expectation: expectation,
        exampleType: exampleType,
    });
});
export const deleteEvalExample = (callId, exampleId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post(`/api/delete_eval_example`, {
        callId: callId,
        exampleId: exampleId,
    });
});
export const getEvalExamples = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_eval_examples`, {
        callId: callId,
    });
    return response.examples;
});
export const getHeapStats = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_heap_stats`);
    return response;
});
export const getThreadStats = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/api/get_thread_stats`);
    return response;
});
export const retrainAllPlaybooks = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/retrain_all_playbooks");
});
export const retrainAllCards = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/retrain_all_cards");
});
export const getTrainingStatus = (entity) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_training_status", { entity });
});
export const getLiveCardsPaginated = (page, pageSize) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_live_cards_paginated", {
        page,
        page_size: pageSize,
    });
    return response;
});
export const getLiveCards = (additional_ids) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/call_nav/get_live_cards", {
        live_card_ids: additional_ids,
    });
});
export const getLiveCard = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return getLiveCards([id]).then((data) => {
        return data.live_cards[0];
    });
});
export const proposeLiveCard = (prompt, suggest_trigger_phrases) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/call_nav/propose_live_card", {
        prompt: prompt,
        suggest_trigger_phrases: suggest_trigger_phrases,
    });
});
export const createVerbalTrigger = (intent_id, text) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/call_nav/create_verbal_trigger", {
        intent_id: intent_id,
        text: text,
    });
});
export const fetchAllCardsPaginated = (page_size) => __awaiter(void 0, void 0, void 0, function* () {
    const firstPage = yield getLiveCardsPaginated(/* page= */ 1, page_size);
    const totalPages = Math.floor((firstPage.total + page_size - 1) / page_size);
    let promises = [];
    for (let page = 1; page <= totalPages; page++) {
        promises.push(getLiveCardsPaginated(/* page= */ page, page_size));
    }
    const respones = yield Promise.all(promises);
    return respones
        .flat()
        .map((resp) => resp.data)
        .flat();
});
export const batchCreatePlaybook = (playbook) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/batch_create_playbook", { playbook });
});
export const batchCreateCards = (cards) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/batch_create_cards", { live_cards: cards });
});
export const recordCardEvent = (card, callId, eventType) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/record_card_event", {
        call_id: callId,
        intent_id: card.intent,
        event_type: eventType,
    });
});
export const setZoomTestAccessCode = (accessCode) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/zoom_test_oauth", { code: accessCode });
});
export const getZoomTestIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get(`/call_nav/get_zoom_test_integration_status`);
    return response;
});
export const restartActiveCall = (wipeoutCallData) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/restart_active_call", {
        wipeout_call_data: wipeoutCallData,
    });
});
export const getParagonUserToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_paragon_user_token");
    return response.paragon_jwt;
});
export const getPreCallPrepTemplateForCall = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_pre_call_prep_template_for_call", {
        call_id: callId,
    });
    return response.template;
});
export const reorderPreCallPrepBlocksInCall = (callId, order) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/reorder_pre_call_prep_blocks_in_call", {
        call_id: callId,
        order,
    });
});
export const updatePreCallPrepTemplateForCall = (callId, templateId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/update_pre_call_prep_template_for_call", {
        call_id: callId,
        pre_call_prep_template_id: templateId,
    });
});
export const updateAllPreCallPrepTemplateForCall = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/update_all_pre_call_prep_template_for_call", {
        call_id: callId,
    });
});
export const getLiveCallById = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/get_live_call", {
        call_id: callId,
    });
    return response.call;
});
export const getLiveCallAndNotes = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/get_live_call", {
        call_id: callId,
    });
    return response;
});
export const deleteLiveCard = (cardId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/delete_live_card", { live_card_id: cardId });
});
export const getPostCallOutput = (postCallOutputId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_post_call_output", {
        post_call_output_id: postCallOutputId,
    });
    return response;
});
export const createInitialPostCallOutputs = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/create_initial_post_call_outputs", {
        call_id: callId,
    });
    return response.postCallOutputs;
});
export const executePostCallOutput = (postCallOutputId, valueToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/execute_post_call_output", {
        post_call_output_id: postCallOutputId,
        value_to_update: valueToUpdate,
    });
    return response;
});
export const updatePostCallOutput = (postCallOutputId, updatedItem) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/update_post_call_output", {
        post_call_output_id: postCallOutputId,
        state: updatedItem.state,
        field_map: {
            target: updatedItem.fieldMap.target,
            target_details: updatedItem.fieldMap.targetDetails,
            execute_post_call_after_n_minutes: updatedItem.fieldMap.executePostCallAfterNMinutes,
            update_type: updatedItem.fieldMap.updateType,
        },
        override_target: updatedItem.target,
        override_target_details: updatedItem.targetDetails,
        value_to_update: updatedItem.valueToUpdate,
    });
    return response;
});
export const getSfdcFieldsForPostCall = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_sfdc_field_names_for_field_mapping");
    return response;
});
export const getSlackChannelsForPostCall = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_slack_channels_for_field_mapping");
    return response;
});
export const createPreCallPrepBlock = (name, dataSources, prompt, deliveryTargets, researchBlockType, templateId, scoringCriteria) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/create_pre_call_prep_block", {
        template_id: templateId,
        name,
        data_sources: dataSources,
        prompt,
        delivery_targets: deliveryTargets,
        research_block_type: researchBlockType,
        scoring_criteria: scoringCriteria,
    });
    return response;
});
export const createBrick = ({ name, dataSources, prompt, deliveryTargets, researchBlockType, model, webDataModel, suppressCitations, preview, scoringCriteria, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/call_nav/create_pre_call_prep_block", {
        name,
        data_sources: dataSources,
        prompt,
        delivery_targets: deliveryTargets,
        research_block_type: researchBlockType,
        model,
        web_data_model: webDataModel,
        suppress_citations: suppressCitations,
        preview: preview !== null && preview !== void 0 ? preview : false,
        scoring_criteria: scoringCriteria,
    });
});
export const updatePreCallPrepBlock = (id, name, dataSources, prompt, deliveryTargets, model, scoringCriteria, webDataModel, suppressCitations) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/call_nav/update_pre_call_prep_block", {
        id,
        name,
        data_sources: dataSources,
        prompt,
        delivery_targets: deliveryTargets,
        model,
        scoring_criteria: scoringCriteria,
        web_data_model: webDataModel,
        suppress_citations: suppressCitations,
    });
});
export const updateBrick = ({ id, name, dataSources, prompt, deliveryTargets, model, scoringCriteria, webDataModel, suppressCitations, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/call_nav/update_pre_call_prep_block", {
        id,
        name,
        data_sources: dataSources,
        prompt,
        delivery_targets: deliveryTargets,
        model,
        scoring_criteria: scoringCriteria,
        web_data_model: webDataModel,
        suppress_citations: suppressCitations,
    });
});
export const setBrickLinksToTarget = ({ targetBrickId, sourceBrickIds, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/call_nav/set_brick_links_to_target", {
        target_brick_id: targetBrickId,
        source_brick_ids: sourceBrickIds,
    });
});
export const removePreCallPrepBlockFromCall = (callId, blockId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/remove_pre_call_prep_block_from_call", {
        call_id: callId,
        pre_call_prep_block_id: blockId,
    });
});
export const addPreCallPrepBlockToCall = (callId, blockId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/add_pre_call_prep_block_to_call", {
        call_id: callId,
        pre_call_prep_block_id: blockId,
    });
});
export const getAllPreCallPrepTemplates = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_brick_sets_by_type", {
        brick_set_type: "CALL",
    });
    return response;
});
export const getAllTrackerBrickSets = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_brick_sets_by_type", {
        brick_set_type: "TRACKER",
    });
    return response;
});
export const getAllBrickSets = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_all_brick_sets");
    return response;
});
export const getBrickSetsByType = (brickSetType) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_brick_sets_by_type", {
        brick_set_type: brickSetType,
    });
    return response;
});
export const getAllBricks = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/call_nav/get_all_bricks");
});
export const getBricksByType = (brickType) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_bricks_by_type", {
        brick_type: brickType,
    });
    return response.bricks;
});
export const getAllPreCallPrepBlocks = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_all_pre_call_prep_blocks");
    return response;
});
export const addPreCallPrepBlockToTemplate = (templateId, blockId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/add_pre_call_prep_block_to_template", {
        template_id: templateId,
        block_id: blockId,
    });
});
export const createPreCallPrepTemplate = (name, brick_type) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/create_pre_call_prep_template", {
        name,
        brick_type,
    });
    return response;
});
export const deletePreCallPrepTemplate = (id) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/delete_pre_call_prep_template", { id });
});
export const getPreCallPrepBlocks = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_pre_call_prep_blocks");
    return response;
});
export const getPreCallPrepOutput = (callId, blockId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_pre_call_prep_output", {
        call_id: callId,
        block_id: blockId,
    });
    return response;
});
export const deletePreCallPrepBlock = (id) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/delete_pre_call_prep_block", {
        id,
    });
});
export const executePreCallPrepBlock = (callId, blockId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/call_nav/execute_pre_call_prep_block", {
        call_id: callId,
        block_id: blockId,
    });
});
export const reorderPreCallPrepBlocks = (templateId, order) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/reorder_pre_call_prep_blocks", {
        template_id: templateId,
        order: order,
    });
    return response;
});
export const updatePreCallPrepOutput = (outputId, html) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/update_pre_call_prep_output", {
        output_id: outputId,
        html: html,
    });
    return response;
});
export const updatePreCallPrepTemplate = (id, name) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/update_pre_call_prep_template", {
        id,
        name,
    });
});
export const getPreCallPrepBlocksForTemplate = (templateId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_pre_call_prep_blocks_for_template", {
        template_id: templateId,
    });
    return response;
});
export const getBricksForBrickSet = (brickId) => __awaiter(void 0, void 0, void 0, function* () {
    return getPreCallPrepBlocksForTemplate(brickId);
});
export const getPreCallPrepTemplate = (templateId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_pre_call_prep_template", {
        id: templateId,
    });
    return response.template;
});
export const removePreCallPrepBlockFromTemplate = (templateId, blockId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/call_nav/remove_pre_call_prep_block_from_template", {
        template_id: templateId,
        block_id: blockId,
    });
});
export const getOrCreateUserProfile = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/get_or_create_user_profile");
});
export const updateUserProfile = (userProfile) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    return yield Request.post("/api/update_user_profile", {
        user_profile_id: userProfile.id,
        precall_notification_duration: userProfile.precall_notification_duration,
        precall_slack_enabled: userProfile.precall_slack_enabled,
        precall_email_enabled: userProfile.precall_email_enabled,
        autojoin_external_meetings: userProfile.autojoin_external_meetings,
        autojoin_internal_meetings: userProfile.autojoin_internal_meetings,
        custom_bot_name: userProfile.custom_bot_name,
        no_live_bot: userProfile.no_live_bot,
        first_call_pre_call_prep_template_id: (_a = userProfile.first_call_pre_call_prep_template) === null || _a === void 0 ? void 0 : _a.id,
        follow_up_calls_pre_call_prep_template_id: (_b = userProfile.follow_up_calls_pre_call_prep_template) === null || _b === void 0 ? void 0 : _b.id,
        versioned_live_call_mosaic_state: userProfile.versioned_live_call_mosaic_state,
        default_note_template: userProfile.default_note_template,
        timezone: userProfile.timezone,
    });
});
export const paragonIntegrationsUpdateCallback = (user) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/paragon_integrations_update_callback", {
        paragon_user: user,
    });
});
export const startCallOrUserStream = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    if (callId === 0) {
        return yield Request.post("/api/start_backend_for_user_stream", {});
    }
    return yield Request.post("/api/pick_backend_for_call", {
        call_id: callId,
    });
});
export const getManualNote = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/call_nav/get_manual_note", {
        call_id: callId,
    });
    return response.note;
});
export const updateManualNote = (callId, note) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/call_nav/update_manual_note", {
        call_id: callId,
        note: note,
    });
    return response.note;
});
export const getCrmFields = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_crm_fields");
    return response.fields;
});
export const createCrmField = (name, typeDefinition, applicableObjects) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_crm_field", {
        name,
        type_definition: typeDefinition,
        applicable_objects: applicableObjects,
    });
    return response;
});
export const deleteCrmField = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_crm_field", { field_id: id });
});
export const getAccounts = (cursor, name) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_all_accounts_in_tenant", {
        cursor,
        name,
    });
});
export const getCrmContacts = (accountId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_crm_contacts", {
        account_id: accountId,
    });
    return response.contacts;
});
export const getContacts = (cursor, email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_all_contacts_in_tenant", {
        cursor,
        email,
    });
});
export const createOrUpdateCrmAccountData = (accountId, fieldId, value) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_or_update_crm_account_data", {
        account_id: accountId,
        field_id: fieldId,
        value: value,
    });
    return response.id;
});
export const setAutojoinForCall = (callId, autojoin) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/set_autojoin_for_call", {
        call_id: callId,
        autojoin: autojoin,
    });
});
export const createCrmAccount = (name, website, linkedinProfileUrl, fieldValues) => __awaiter(void 0, void 0, void 0, function* () {
    // Field values are stored in DB as JSONB with z+crmFieldId as the key
    // so we need to remove the z prefix and convert the keys to numbers
    const field_values = Object.entries(fieldValues).reduce((acc, [key, value]) => {
        acc[parseInt(key.replace("z", ""), 10)] = value;
        return acc;
    }, {});
    return yield Request.post("/api/create_crm_account", {
        name,
        website,
        linkedin_profile_url: linkedinProfileUrl,
        field_values,
    });
});
export const getCrmAccount = (accountId, includeNonCrmAccounts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_crm_account", {
        account_id: accountId,
        include_non_crm_accounts: includeNonCrmAccounts,
    });
});
export const updateCrmAccount = (accountId, name, website, linkedinProfileUrl, ownerId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_crm_account", {
        account_id: accountId,
        name,
        website,
        linkedin_profile_url: linkedinProfileUrl,
        owner_id: ownerId,
    });
});
export const getCoachingPrompts = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_coaching_prompts");
    return response.coaching_prompts;
});
export const createOrUpdateCrmOpportunityData = (opportunityId, fieldId, value) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_or_update_crm_opportunity_data", {
        opportunity_id: opportunityId,
        field_id: fieldId,
        value: value,
    });
    return response.id;
});
export const createCrmOpportunity = (name, accountId, fieldValues) => __awaiter(void 0, void 0, void 0, function* () {
    // Field values are stored in DB as JSONB with z+crmFieldId as the key
    // so we need to remove the z prefix and convert the keys to numbers
    const field_values = Object.entries(fieldValues).reduce((acc, [key, value]) => {
        acc[parseInt(key.replace("z", ""), 10)] = value;
        return acc;
    }, {});
    return yield Request.post("/api/create_crm_opportunity", {
        name,
        account_id: accountId,
        field_values,
    });
});
export const getCrmOpportunity = (opportunityId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_crm_opportunity", {
        opportunity_id: opportunityId,
    });
});
export const updateCrmOpportunity = (opportunityId, name, accountId, ownerId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_crm_opportunity", {
        opportunity_id: opportunityId,
        name,
        account_id: accountId,
        owner_id: ownerId,
    });
});
export const createOrUpdateCrmContactData = (contactId, fieldId, value) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_or_update_crm_contact_data", {
        contact_id: contactId,
        field_id: fieldId,
        value: value,
    });
    return response.id;
});
export const createCrmContact = (name, email, linkedinProfileUrl, accountId, fieldValues) => __awaiter(void 0, void 0, void 0, function* () {
    // Field values are stored in DB as JSONB with z+crmFieldId as the key
    // so we need to remove the z prefix and convert the keys to numbers
    const field_values = Object.entries(fieldValues).reduce((acc, [key, value]) => {
        acc[parseInt(key.replace("z", ""), 10)] = value;
        return acc;
    }, {});
    const response = yield Request.post("/api/create_crm_contact", {
        name,
        email,
        linkedin_profile_url: linkedinProfileUrl,
        account_id: accountId,
        field_values,
    });
    if ("error" in response) {
        return Object.assign({ type: "error" }, response);
    }
    return Object.assign({ type: "success" }, response);
});
export const createCrmLead = (name, email, fieldValues, companyName, website, accountId, contactId, opportunityId) => __awaiter(void 0, void 0, void 0, function* () {
    // Field values are stored in DB as JSONB with z+crmFieldId as the key
    // so we need to remove the z prefix and convert the keys to numbers
    const field_values = Object.entries(fieldValues).reduce((acc, [key, value]) => {
        acc[parseInt(key.replace("z", ""), 10)] = value;
        return acc;
    }, {});
    const response = yield Request.post("/api/create_crm_lead", {
        name,
        email,
        company_name: companyName,
        website,
        account_id: accountId,
        contact_id: contactId,
        opportunity_id: opportunityId,
        field_values,
    });
    if ("error" in response) {
        return Object.assign({ type: "error" }, response);
    }
    return Object.assign({ type: "success" }, response);
});
export const getCrmContact = (contactId, includeNonCrmContacts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_crm_contact", {
        contact_id: contactId,
        include_non_crm_contacts: includeNonCrmContacts,
    });
});
export const getCrmLead = (leadId, includeNonCrmLeads) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_crm_lead", {
        lead_id: leadId,
        include_non_crm_leads: includeNonCrmLeads,
    });
});
export const getCrmLeads = (leadIds, includeNonCrmLeads) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.post("/api/get_crm_leads", {
        lead_ids: leadIds,
        include_non_crm_leads: includeNonCrmLeads,
    });
    return resp["leads"];
});
export const updateCrmContact = (contactId, name, email, accountId, linkedinProfileUrl, roleName, ownerId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/update_crm_contact", {
        contact_id: contactId,
        name,
        email,
        account_id: accountId,
        linkedin_profile_url: linkedinProfileUrl,
        role_name: roleName,
        owner_id: ownerId,
    });
    if ("error" in response) {
        return Object.assign({ type: "error" }, response);
    }
    return Object.assign({ type: "success" }, response);
});
export const updateCrmLead = (leadId, name, email, companyName, website, accountId, contactId, opportunityId, ownerId, fieldValues) => __awaiter(void 0, void 0, void 0, function* () {
    const field_values = Object.entries(fieldValues || {}).reduce((acc, [key, value]) => {
        acc[parseInt(key.replace("z", ""), 10)] = value;
        return acc;
    }, {});
    const response = yield Request.post("/api/update_crm_lead", {
        lead_id: leadId,
        name,
        email,
        company_name: companyName,
        website,
        account_id: accountId,
        owner_id: ownerId,
        contact_id: contactId,
        opportunity_id: opportunityId,
        field_values,
    });
    if ("error" in response) {
        return Object.assign({ type: "error" }, response);
    }
    return Object.assign({ type: "success" }, response);
});
export const validateCrmDataCsv = (crmObject, file) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    formData.append("crm_object", crmObject);
    formData.append("file", file);
    return yield Request.post("/api/validate_crm_import_csv", formData);
});
export const importCrmDataCsv = (crmObject, file) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    formData.append("crm_object", crmObject);
    formData.append("file", file);
    return yield Request.post("/api/import_crm_data_from_csv", formData);
});
export const downloadCrmDataCsv = (crmObject) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/download_crm_data_csv", {
        crm_object: crmObject,
    }, {
        responseType: "blob",
    });
});
export const getPlaybookItems = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_playbook_items");
    return response.playbook_items;
});
export const editContact = (id, full_name, linkedin_profile_url, role) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/update_contact", {
        id: id,
        full_name: full_name,
        linkedin_profile_url: linkedin_profile_url,
        role_name: role,
    });
    return response;
});
export const queryLiveCalls = (json_query, cursor, order_by, per_page = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/query_live_calls", {
        json_query,
        cursor,
        order_by,
        per_page,
    });
    return response;
});
export const getCallsForTableView = (json_query, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    const response = yield Request.get("/api/query_live_calls_view", {
        json_query: new_json_query,
        per_page: perPage,
        page,
    });
    return response;
});
export const getCallsTableCsv = (filter_query, ids, visible_columns) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/get_calls_table_csv", {
        filter_query,
        ids,
        visible_columns,
    });
});
export const getLiveCallSummaries = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_live_call_summaries", {
        call_id: callId,
    });
    return response;
});
export const getPlaybackPage = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_playback_page", {
        call_id: callId,
    });
    return response;
});
export const createCallRecordingClip = (callId, startTimeSeconds, endTimeSeconds, clipTitle, tags) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_call_recording_clip", {
        call_id: callId,
        start_time_seconds: startTimeSeconds,
        end_time_seconds: endTimeSeconds,
        clip_title: clipTitle,
        tags,
    });
    return response.call_recording_clip;
});
export const deleteCallRecordingClip = (clipId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_call_recording_clip", {
        clip_id: clipId,
    });
});
export const getAllCallRecordingClips = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_all_call_recording_clips", {
        call_id: callId,
    });
    return response.call_recording_clips;
});
export const downloadCallRecordingClip = (clipId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/download_call_recording_clip", {
        clip_id: clipId,
    });
    return response.signed_url;
});
export const getLiveCallIdByGoogleCalendarId = (google_calendar_id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/get_live_call_id_by_google_calendar_id", {
        google_calendar_id,
    });
    return response;
});
export const getActiveTenant = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_active_tenant");
});
export const updateTenant = (tenant) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_tenant", tenant);
});
export const deletePlaybook = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_playbook", { playbook_id: id });
});
export const forceDeletePlaybook = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_playbook", {
        playbook_id: id,
        confirm_delete: true,
    });
});
export const deletePlaybookItemGroup = (id, forceDelete = false) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/delete_playbook_item_group", {
        playbook_item_group_id: id,
        confirm_delete: forceDelete,
    });
    return response;
});
export const createCallAndSetParticipants = (callName, participantEmails, meetingLink, callTime, callDurationMinutes, joinSetting, callType) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_call_and_set_participants", {
        callName,
        participantEmails,
        meetingLink,
        callTime: callTime.toISOString(),
        callDurationMinutes,
        joinSetting,
        callType,
    });
    return response;
});
export const deleteLiveCall = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_live_call", { id });
});
export const addGroupToPlaybook = (playbookId, playbookItemGroupId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/add_group_to_playbook", {
        playbook_id: playbookId,
        playbook_item_group_id: playbookItemGroupId,
    });
});
export const createPlaybook = (name, details) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_playbook", {
        name,
        details: JSON.stringify(details),
    });
});
export const updatePlaybook = (playbook_id, name, details) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_playbook", {
        playbook_id,
        name,
        details: JSON.stringify(details),
        publish_state: "D", // TODO: remove this, right now "D" is hardcoded everywhere.
    });
});
export const getAllUsersInTenant = (ids, cursor, email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/get_all_users_in_tenant", {
        ids,
        cursor,
        email,
    });
});
export const queryCrmObjects = (crmObject, fieldToQuery, queryValue, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/query_crm_objects", {
        crm_object: crmObject,
        field_to_query: fieldToQuery,
        query_value: queryValue,
        cursor,
    });
});
export const fetchLeadCrmObject = (objectId) => __awaiter(void 0, void 0, void 0, function* () {
    return fetchCrmObject(CrmObjectTypeEnum.LEAD, objectId);
});
export const fetchCrmObject = (crmObject, objectId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/fetch_crm_object", {
        crm_object: crmObject,
        object_id: objectId,
    });
});
export const queryOmniSearch = (userQuery, accountsLimit, callsLimit, contactsLimit, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/omni_search", {
        user_query: userQuery,
        accounts_limit: accountsLimit,
        calls_limit: callsLimit,
        contacts_limit: contactsLimit,
        cursor,
    });
});
export const getPlaybookItemGroupById = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get(`/call_nav/get_playbook_item_group?playbook_item_group_id=${id}`);
});
export const createPlaybookItemGroup = (name, callStage, relevantSpeaker, notePrompt, reminderMinutes, useWrapperPrompt) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post(`/call_nav/create_playbook_item_group`, {
        name,
        details: "{}",
        call_stage: callStage,
        relevant_speaker: relevantSpeaker,
        relevance_prompt: notePrompt,
        note_prompt: notePrompt,
        reminder_minutes: reminderMinutes,
        use_wrapper_prompt: useWrapperPrompt,
    });
});
export const updatePlaybookItemGroup = (playbook_item_group_id, name, callStage, relevantSpeaker, notePrompt, reminderMinutes, useWrapperPrompt) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post(`/call_nav/update_playbook_item_group`, {
        playbook_item_group_id,
        name,
        details: "{}",
        call_stage: callStage,
        relevant_speaker: relevantSpeaker,
        relevance_prompt: notePrompt,
        note_prompt: notePrompt,
        reminder_minutes: reminderMinutes,
        use_wrapper_prompt: useWrapperPrompt,
    });
});
export const getLlmOutputFeedback = (liveCallId, accountId) => __awaiter(void 0, void 0, void 0, function* () {
    if (liveCallId === undefined && accountId === undefined) {
        return [];
    }
    if (liveCallId != null && isNaN(liveCallId)) {
        return [];
    }
    if (accountId != null && isNaN(accountId)) {
        return [];
    }
    return yield Request.get("/api/get_llm_output_feedback", {
        live_call_id: liveCallId,
        account_id: accountId,
    });
});
export const updateLlmOutputFeedback = (provenanceLogId, userFeedback) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_llm_output_feedback", {
        provenance_log_id: provenanceLogId,
        user_feedback: userFeedback,
    });
});
export const deleteLlmOutputFeedback = (provenanceLogId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/api/delete_llm_output_feedback", {
        provenance_log_id: provenanceLogId,
    });
});
export const getLiveCallSummary = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_live_call_summary", {
        call_id: callId,
    });
});
export const getHubspotFieldsForPostCall = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_hubspot_fields_for_post_call");
});
export const getConfigurationForAccountDetailsResearchBlock = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_configuration_for_account_details_research_block");
});
export const updateTenantPreCallPrepDeliveryConfig = (tenantId, targetAppName, deliveryConfig) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_tenant_pre_call_prep_delivery_config", {
        tenant_id: tenantId,
        delivery_target_app_name: targetAppName,
        delivery_target_config: deliveryConfig,
    });
});
export const getDataSourceConfigForResearchBlock = (researchBlockType) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_data_source_config_for_research_block", {
        research_block_type: researchBlockType,
    });
});
export const getAccountResearchBlockOutputs = (accountId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_account_research_block_outputs", {
        account_id: accountId,
    });
});
export const generateAccountResearchBlockOutput = (accountId, researchBlockId, forceRefresh = false) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/generate_account_research_block_output", {
        account_id: accountId,
        research_block_id: researchBlockId,
        force_refresh: forceRefresh,
    });
});
export const getAccountResearchBlockMemberships = (accountId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_account_research_block_memberships", {
        account_id: accountId,
    });
});
export const getAccountSummary = (accountId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/generate_account_summary", {
        account_id: accountId,
    });
});
export const getAccountDescription = (accountId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/generate_account_description", {
        account_id: accountId,
    });
});
export const getAppIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_app_integration_data");
});
export const getExternalApiTokens = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_external_api_tokens");
});
export const createExternalApiToken = ({ name, scopes, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_external_api_token", {
        name: name,
        scopes: scopes,
    });
});
export const deleteExternalApiToken = (token_jwt) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_external_api_token", {
        token_jwt,
    });
});
export const createWebhookSubscription = ({ name, url, events, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_webhook_subscription", {
        name,
        url,
        events,
    });
});
export const deleteWebhookSubscription = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_webhook_subscription", {
        id,
    });
});
export const getWebhookSubscriptions = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_webhook_subscriptions");
});
export const sendDummyWebhookEvent = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/send_dummy_webhook_event", {
        id,
    });
});
export const getTableLayouts = (table_name, defaultLayout) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.get(`/api/get_table_layouts?table_name=${table_name}`);
    const defLayout = resp.views.find((view) => view.is_default) || defaultLayout;
    const filteredViews = resp.views.filter((view) => view !== defLayout);
    return [defLayout, ...filteredViews];
});
export const createOrUpdateTableLayout = (data) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.post("/api/create_or_update_table_layout", data);
    return resp.views;
});
export const deleteTableLayout = (data) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.post("/api/delete_table_layout", data);
    return resp.views;
});
export const createSharedTableLayout = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_shared_table_layout", data);
});
export const getSharedTableLayout = (id) => __awaiter(void 0, void 0, void 0, function* () {
    if (!id) {
        return [];
    }
    const resp = yield Request.get(`/api/get_shared_table_layout?id=${id}`);
    return resp.views;
});
export const publicGetExpiringLink = (linkId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get(`/api/public_get_expiring_link`, {
        link_id: linkId,
    });
});
export const createExpiringLinkForCallRecording = (callId, accessExpirationDays) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_expiring_link_for_call_recording", {
        call_id: callId,
        access_expiration_days: accessExpirationDays,
    });
});
export const createExpiringLinkForCallRecordingClip = (clipId, accessExpirationDays) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_expiring_link_for_call_recording_clip", {
        clip_id: clipId,
        access_expiration_days: accessExpirationDays,
    });
});
export const getCallRecordingDetailsPublic = (linkId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get(`/api/get_call_recording_details_public`, {
        link_id: linkId,
    });
});
export const getCallRecordingClipDetailsPublic = (linkId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get(`/api/get_call_recording_clip_details_public`, {
        link_id: linkId,
    });
});
export const queryCrmOwners = (queryValue, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/query_crm_owners", {
        query_value: queryValue,
        ids,
        cursor,
    });
});
export const getHomePageMetaData = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_home_page_metadata");
});
export const reorderAccountResearchBlocks = (order) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/reorder_account_research_blocks", { order });
});
export const getChatTemplates = (applicableTo, userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.get("/api/get_chat_templates", {
        applicable_to: applicableTo,
        userQuery,
        cursor,
    });
});
const APPLICABLE_TO_DISPLAY_NAME_MAP = {
    Account: "Account page",
    "Live Call": "Live meeting chat",
    "Post Call Output": "Post call chat",
};
export const getAllChatTemplates = () => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.get("/api/get_all_chat_templates");
    return resp.chat_templates.map((chatTemplate) => {
        return Object.assign(Object.assign({}, chatTemplate), { applicable_to_label: APPLICABLE_TO_DISPLAY_NAME_MAP[chatTemplate.applicable_to] });
    });
});
export const createChatTemplate = (title, templateString, source, applicableTo, priority, description) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.post("/api/create_chat_template", {
        title,
        template_string: templateString,
        source,
        applicable_to: applicableTo,
        priority,
        description,
    });
    return Object.assign(Object.assign({}, resp), { applicable_to_label: APPLICABLE_TO_DISPLAY_NAME_MAP[applicableTo] });
});
export const updateChatTemplate = (id, title, templateString, source, applicableTo, priority, description) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield Request.post("/api/update_chat_template", {
        id,
        title,
        template_string: templateString,
        source,
        applicable_to: applicableTo,
        priority,
        description,
    });
    return Object.assign(Object.assign({}, resp), { applicable_to_label: APPLICABLE_TO_DISPLAY_NAME_MAP[applicableTo] });
});
export const incrementChatTemplateUsageCount = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/increment_chat_template_usage_count", {
        id,
    });
});
export const deleteChatTemplate = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_chat_template", {
        id,
    });
});
export const getMeetingTemplates = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_meeting_templates");
    return response;
});
export const createMeetingTemplate = ({ pre_call_template_id, playbook_id, name, description, description_for_llm, meeting_type_for_crm, tracker_brick_set_id, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/create_meeting_template", {
        pre_call_template_id,
        tracker_brick_set_id,
        playbook_id,
        name,
        description,
        description_for_llm,
        meeting_type_for_crm,
    });
});
export const updateMeetingTemplate = ({ meeting_template_id, pre_call_template_id, playbook_id, tracker_brick_set_id, name, description, description_for_llm, meeting_type_for_crm, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/update_meeting_template", {
        meeting_template_id,
        pre_call_template_id,
        playbook_id,
        tracker_brick_set_id,
        name,
        description,
        description_for_llm,
        meeting_type_for_crm,
    });
});
export const deleteMeetingTemplate = (meeting_template_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_meeting_template", {
        meeting_template_id,
    });
});
export const queryMeetingTemplates = (json_query, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    const response = yield Request.get("/api/query_meeting_templates", {
        json_query: new_json_query,
        per_page: perPage,
        page,
    });
    return response;
});
export const awaitBrickExecution = (brickResponse, callback) => __awaiter(void 0, void 0, void 0, function* () {
    while (Object.keys(brickResponse.task_id_to_brick_id).length > 0) {
        brickResponse = yield Request.post("/api/get_brick_outputs", {
            task_id_to_brick_id: JSON.stringify(brickResponse.task_id_to_brick_id),
        });
        brickResponse.brick_outputs.forEach(callback);
        yield new Promise((resolve) => setTimeout(resolve, 2000));
    }
});
export const executeBricks = (brickIds, callback, callId, accountId, forceRefresh, contactId, runBrickDependenciesForType) => __awaiter(void 0, void 0, void 0, function* () {
    let brickResponse = yield Request.post("/api/run_bricks", {
        brick_ids: brickIds,
        call_id: callId,
        account_id: accountId,
        force_refresh: !!forceRefresh,
        contact_id: contactId,
        run_brick_dependencies_for_types: runBrickDependenciesForType !== null && runBrickDependenciesForType !== void 0 ? runBrickDependenciesForType : [],
    });
    yield awaitBrickExecution(brickResponse, callback);
});
export const makeBrickPreview = (brickId, newPrompt, forceRefresh, fromGallery, selectedDataSources, selectedSourceBricks, selectedModel, webDataModel, suppressCitations, contextParams, scoringCriteria) => __awaiter(void 0, void 0, void 0, function* () {
    const brickResponse = yield Request.post("/api/make_brick_preview", {
        brick_id: brickId,
        new_prompt: newPrompt,
        force_refresh: forceRefresh,
        account_id: contextParams === null || contextParams === void 0 ? void 0 : contextParams.accountId,
        call_id: contextParams === null || contextParams === void 0 ? void 0 : contextParams.callId,
        contact_id: contextParams === null || contextParams === void 0 ? void 0 : contextParams.contactId,
        from_gallery: fromGallery,
        selected_data_sources: selectedDataSources,
        selected_source_brick_ids: selectedSourceBricks.map((brick) => brick.id),
        selected_model: selectedModel,
        scoring_criteria: scoringCriteria !== null && scoringCriteria !== void 0 ? scoringCriteria : "",
        selected_web_data_model: webDataModel,
        suppress_citations: suppressCitations,
    });
    return brickResponse;
});
export const getCallStats = (callId) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.get("/api/get_call_stats", {
        call_id: callId,
    });
});
export const setBrickOrdering = (brickIds) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/api/set_brick_ordering", {
        brick_ids: brickIds,
    });
});
export const setBrickSetOrdering = (brickSetIds) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/api/set_brick_set_ordering", {
        brick_set_ids: brickSetIds,
    });
});
export const getCrmData = (jsonQuery, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const json_query = getConvertedJsonQuery(jsonQuery);
    return Request.get("/api/get_crm_data", {
        json_query,
        page,
        per_page: perPage,
    });
});
export const getAccountData = (jsonQuery, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    return getCrmData(jsonQuery, page, perPage);
});
export const getOpportunityData = (jsonQuery, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    return getCrmData(jsonQuery, page, perPage);
});
export const getContactData = (jsonQuery, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    return getCrmData(jsonQuery, page, perPage);
});
export const getLeadData = (jsonQuery, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    return getCrmData(jsonQuery, page, perPage);
});
export const deleteCrmObjects = (crmObjectType, ids) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/api/delete_crm_objects", {
        crm_object: crmObjectType,
        crm_object_ids: ids,
    });
});
export const getAllGalleryBricks = () => __awaiter(void 0, void 0, void 0, function* () {
    return Request.get("/api/get_all_gallery_bricks");
});
export const copyBricks = (brickIds, fromGallery) => __awaiter(void 0, void 0, void 0, function* () {
    const { bricks } = yield Request.post("/api/copy_bricks", {
        brick_ids: brickIds,
        from_gallery: fromGallery,
    });
    return bricks;
});
export const getGongIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    return Request.get("/api/get_gong_integration_data");
});
export const setGongIntegration = (gongData) => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/api/set_gong_integration", {
        base_url: gongData.provider_data.base_url,
        key: gongData.provider_data.key,
        secret: gongData.provider_data.secret,
    });
});
export const disconnectGongIntegration = () => __awaiter(void 0, void 0, void 0, function* () {
    return Request.post("/api/disconnect_gong_integration");
});
