var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "../class_names";
import { CrmObjectTypeEnum, } from "../types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useEffect, useMemo, useState } from "react";
import Dropdown from "../common/dropdown";
import { SALESFORCE_OBJECT_TYPES } from "./data_processing_config";
import FieldSchemaRenderer from "./field_schema_renderer";
import { AsyncSearchSelect } from "../components/async_search_select";
import { queryCrmObjects } from "../common/endpoints";
import { useQueryClient } from "react-query";
import WaitingSpinner from "../waiting_spinner";
export const SalesforceTargetItem = ({ targetDetails, sfdcFields, instanceUrl, }) => {
    var _a, _b, _c;
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/salesforce_logo_color.png", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: [_jsxs("button", Object.assign({ className: classNames("flex items-center self-stretch gap-2", "group", "text-xs font-medium text-gray-600", "hover:outline-none hover:cursor-pointer hover:text-blue-600"), disabled: !instanceUrl, onClick: () => {
                            window.open(`${instanceUrl}/${targetDetails.objectId}`, "_blank");
                        } }, { children: [(_a = targetDetails.objectName) !== null && _a !== void 0 ? _a : "Unknown object", _jsx(ArrowTopRightOnSquareIcon, { className: "w-4 h-4 opacity-0 group-hover:opacity-100" })] })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-black" }, { children: sfdcFields
                            ? (_b = sfdcFields[targetDetails.objectType].find((field) => field.name === targetDetails.fieldName)) === null || _b === void 0 ? void 0 : _b.label
                            : ((_c = targetDetails.fieldLabel) !== null && _c !== void 0 ? _c : "Unknown field") }))] }))] }));
};
export const SalesforceDestinationSelector = ({ call, selectedTarget, salesforceFields, onTargetDetailsChange, }) => {
    var _a, _b, _c, _d, _e;
    const [selectedObjectType, setSelectedObjectType] = useState((_a = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectType) !== null && _a !== void 0 ? _a : CrmObjectTypeEnum.OPPORTUNITY);
    const [selectedObject, setSelectedObject] = useState({
        objectId: (_b = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectId) !== null && _b !== void 0 ? _b : "No object selected",
        objectName: (_c = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectName) !== null && _c !== void 0 ? _c : "No object selected",
    });
    const [selectedFieldName, setSelectedFieldName] = useState((_d = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.fieldName) !== null && _d !== void 0 ? _d : "No field selected");
    useEffect(() => {
        if ((selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectType) === selectedObjectType) {
            setSelectedObject({
                objectId: selectedTarget.objectId,
                objectName: selectedTarget.objectName,
            });
            setSelectedFieldName(selectedTarget.fieldName);
            return;
        }
        if (salesforceFields[selectedObjectType].length === 0) {
            setSelectedFieldName(`No field selected`);
        }
        else {
            setSelectedFieldName(salesforceFields[selectedObjectType][0].name);
        }
    }, [selectedObjectType, call]);
    const selectedSalesforceField = useMemo(() => {
        return salesforceFields[selectedObjectType].find((field) => field.name === selectedFieldName);
    }, [selectedFieldName, salesforceFields, selectedObjectType]);
    const validTargetDetails = () => {
        return (selectedObject.objectId !== `No object selected` &&
            selectedFieldName !== "No field selected");
    };
    useEffect(() => {
        var _a, _b, _c;
        if (!validTargetDetails()) {
            return;
        }
        onTargetDetailsChange({
            objectType: selectedObjectType,
            objectId: selectedObject.objectId,
            fieldName: selectedFieldName,
            objectName: (_a = selectedObject.objectName) !== null && _a !== void 0 ? _a : `"Unknown ${selectedObjectType}"`,
            fieldLabel: (_c = (_b = salesforceFields[selectedObjectType].find((field) => field.name === selectedFieldName)) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : "Unknown field",
        });
    }, [selectedObjectType, selectedObject, selectedFieldName]);
    // The below objects memoize the options and selected value that we send
    // to SearchableDropdowns. This helps us reduce the number of .find lookups
    // and providing us O(1) time checks for figuring whether the selected object
    // belongs to these options.
    const salesforceFieldOptions = useMemo(() => {
        return salesforceFields[selectedObjectType]
            .map((field) => ({
            value: field.name,
            label: field.label,
        }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [salesforceFields, selectedObjectType]);
    const allSfdcFieldsByName = useMemo(() => {
        return salesforceFields[selectedObjectType].reduce((acc, object) => {
            acc[object.name] = object;
            return acc;
        }, {});
    }, [selectedObjectType, salesforceFields]);
    // This method is used to continuously fetch SFDC objects for the
    // selectedObjectType based on the user query and cursor.
    const queryClient = useQueryClient();
    const sfdcObjectsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const sfdcQueryResponse = yield queryClient.fetchQuery({
            queryKey: ["sfdcObjects", selectedObjectType],
            queryFn: () => queryCrmObjects(selectedObjectType, 
            /* fieldToQuery= */ "name", userQuery, cursor),
        });
        return {
            results: sfdcQueryResponse.results.map((obj) => {
                return { label: obj.name, value: obj.id };
            }),
            next_cursor: sfdcQueryResponse.next_cursor,
        };
    });
    return (_jsxs("div", Object.assign({ className: "w-96 flex flex-col flex-grow items-start self-stretch gap-4" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-base" }, { children: "Select a destination" })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Object" })), _jsx(Dropdown, { options: SALESFORCE_OBJECT_TYPES.map((obj) => ({
                            value: obj,
                            label: obj,
                        })), defaultSelected: selectedObjectType, onOptionSelect: (option) => {
                            setSelectedObjectType(option);
                        }, buttonClasses: "bg-white rounded-lg" })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: `${selectedObjectType} name` })), _jsx(AsyncSearchSelect, { id: selectedObjectType, dataFetcher: sfdcObjectsFetcher, onSelect: (option) => {
                            if (!option)
                                return;
                            setSelectedObject({
                                objectId: option.value,
                                objectName: option.label,
                            });
                        }, selectedOption: selectedObject
                            ? {
                                value: selectedObject.objectId,
                                label: (_e = selectedObject.objectName) !== null && _e !== void 0 ? _e : "Unknown object",
                            }
                            : undefined, buttonClasses: "bg-white rounded-lg" })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsxs("span", Object.assign({ className: "flex flex-row text-sm font-bold text-gray-500" }, { children: ["Field", " "] })), salesforceFieldOptions.length == 0 ? (_jsx(WaitingSpinner, { text: "Loading your fields from Salesforce" })) : (_jsx(AsyncSearchSelect, { id: `${selectedObjectType}-fields`, dataFetcher: (userQuery, ids, cursor) => {
                            return new Promise((resolve) => {
                                const filteredResults = salesforceFieldOptions.filter((field) => field.label.toLowerCase().includes(userQuery.toLowerCase()));
                                resolve({
                                    results: filteredResults,
                                });
                            });
                        }, selectedOption: selectedFieldName in allSfdcFieldsByName
                            ? {
                                value: selectedFieldName,
                                label: allSfdcFieldsByName[selectedFieldName].label,
                            }
                            : undefined, onSelect: (option) => {
                            if (!option)
                                return;
                            setSelectedFieldName(option.value);
                        }, buttonClasses: "bg-white rounded-lg" })), !!selectedSalesforceField && (_jsx(FieldSchemaRenderer, { id: selectedSalesforceField.name, fieldSchema: selectedSalesforceField.schema }))] }))] })));
};
