var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon, DocumentArrowDownIcon, } from "@heroicons/react/24/outline";
import { markLatestActivityTime } from "../activity_utils";
import { useLocation, Outlet } from "react-router-dom";
import { Popover } from "@headlessui/react";
import * as endpoints from "../common/endpoints";
import { CrmObjectTypeEnum } from "../types";
import { useQuery } from "react-query";
import { useCallback } from "react";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
const CrmHome = () => {
    const location = useLocation();
    const activeTab = location.pathname.split("/").pop();
    const { data: activeTenant, isLoading: activeTenantLoading } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: endpoints.getActiveTenant,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        keepPreviousData: true,
    });
    // For jwt token refresh
    markLatestActivityTime();
    const getCrmObjectForTabName = useCallback((tabName) => {
        switch (tabName) {
            case "accounts":
                return CrmObjectTypeEnum.ACCOUNT;
            case "opportunities":
                return CrmObjectTypeEnum.OPPORTUNITY;
            case "contacts":
                return CrmObjectTypeEnum.CONTACT;
            case "leads":
                return CrmObjectTypeEnum.LEAD;
            default:
                return CrmObjectTypeEnum.ACCOUNT;
        }
    }, []);
    const crmObject = getCrmObjectForTabName(activeTab);
    const handleDownloadCsvClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield endpoints.downloadCrmDataCsv(crmObject);
        const nowTime = new Date().getTime();
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${activeTab}${nowTime}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    });
    const getLabelForCrmObject = (crmObject) => {
        if (activeTenantLoading) {
            return "";
        }
        const hubspotConnected = (activeTenant === null || activeTenant === void 0 ? void 0 : activeTenant.connected_crm) === "HubSpot";
        switch (crmObject) {
            case CrmObjectTypeEnum.ACCOUNT:
                return hubspotConnected ? "Companies" : "Accounts";
            case CrmObjectTypeEnum.OPPORTUNITY:
                return hubspotConnected ? "Deals" : "Opportunities";
            case CrmObjectTypeEnum.CONTACT:
                return "Contacts";
            case CrmObjectTypeEnum.LEAD:
                return "Leads";
        }
    };
    const crmObjects = ["accounts", "opportunities", "contacts"];
    const leadsEnabled = useFeatureFlagEnabled("LEADS_TABLE");
    if (leadsEnabled) {
        crmObjects.push("leads");
    }
    return (_jsxs("div", Object.assign({ className: "w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-wds-gray-1 overflow-x-auto" }, { children: [_jsxs("div", Object.assign({ className: "pt-6 pl-6 flex justify-between items-center bg-wds-gray-1" }, { children: [_jsx("span", Object.assign({ className: "text-black text-3xl" }, { children: getLabelForCrmObject(getCrmObjectForTabName(activeTab !== null && activeTab !== void 0 ? activeTab : "accounts")) })), _jsxs(Popover, Object.assign({ as: "div" }, { children: [_jsxs(Popover.Button, Object.assign({ className: "text-blue-500 text-base font-bold px-4 py-2 flex gap-2 items-center hover:bg-blue-100 hover:rounded-full hover:outline-none hover:ring-0 focus:outline-none focus:ring-0" }, { children: ["Actions", _jsx(ChevronDownIcon, { className: "w-5 h-5", "aria-hidden": "true" })] })), _jsx(Popover.Panel, Object.assign({ className: "absolute z-50 top-4 right-0 w-[180px] mt-12 mr-4 bg-white flex flex-col rounded-lg border border-neutral-200 shadow-lg" }, { children: _jsxs("button", Object.assign({ className: "flex gap-2 p-2 w-full text-left hover:bg-neutral-100", onClick: handleDownloadCsvClick }, { children: [_jsx(DocumentArrowDownIcon, { className: "w-5 h-5 text-blue-500" }), "Download CSV"] })) }))] }))] })), _jsx(Outlet, {})] })));
};
export default CrmHome;
