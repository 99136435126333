var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { CrmObjectTypeEnum } from "../../../types";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "../../../components/table";
import { Button } from "../../../components/button";
import { Link } from "react-router-dom";
import { ContactAvatar } from "../../../common/contact_avatar";
import { useQuery } from "react-query";
import { getActiveTenant, getAppIntegrationData, getCrmContact, } from "../../../common/endpoints";
import { getExternalCrmObjectLink } from "../../common/external_crm_link";
import { getConnectedCrmLogo } from "../../../common/crm_logo";
import { useState } from "react";
import { CallCrmContactModal } from "../../../common/call_crm_contact_modal";
export const AccountContactsTable = ({ contacts, contactEmailDraftBrickExists, onMoreDetailsClick, onDraftEmailClick, invalidateAccountContacts, }) => {
    const { data: tenant } = useQuery({
        queryKey: ["tenant"],
        queryFn: () => getActiveTenant(),
        refetchOnWindowFocus: false,
    });
    const { data: storedAppIntegrationData } = useQuery({
        queryKey: ["storedAppIntegrationData"],
        queryFn: () => getAppIntegrationData(),
        refetchOnWindowFocus: false,
    });
    const [contactCreationModalOpen, setContactCreationModalOpen] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const { data: selectedContact } = useQuery({
        queryKey: ["selectedContact", selectedContactId],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            if (!selectedContactId)
                return undefined;
            return yield getCrmContact(selectedContactId, 
            /* includeNonCrmContacts= */ true);
        }),
        refetchOnWindowFocus: false,
        enabled: !!selectedContactId,
    });
    if (contacts.length === 0) {
        return _jsx("span", Object.assign({ className: "text-wds-gray-4" }, { children: "No contacts found" }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Table, Object.assign({ className: "w-full" }, { children: [_jsx(TableHeader, Object.assign({ className: "border-t border-b border-wds-gray-3 bg-wds-gray-1" }, { children: _jsxs(TableRow, { children: [_jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "Name" })), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "More details" })), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "Action" }))] }) })), _jsx(TableBody, { children: contacts.map((contact) => {
                            var _a;
                            return (_jsxs(TableRow, Object.assign({ className: "hover:bg-transparent" }, { children: [_jsx(TableCell, { children: _jsx(ContactAvatar, { contact: contact }) }), _jsx(TableCell, Object.assign({ className: "p-0" }, { children: _jsx("div", Object.assign({ className: "flex items-center py-4 px-4 gap-2.5 self-stretch grow" }, { children: _jsxs(Button, Object.assign({ onClick: () => onMoreDetailsClick(contact), variant: "light", className: "flex justify-between self-stretch items-center gap-0.5 grow rounded-md" }, { children: ["Contact, work history and...", _jsx(ChevronRightIcon, { className: "w-4 h-4 shrink-0" })] })) })) })), _jsx(TableCell, Object.assign({ className: "p-0" }, { children: _jsxs("div", Object.assign({ className: "flex gap-2.5 self-stretch grow items-center py-4 px-4" }, { children: [(tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm) && (_jsx(_Fragment, { children: contact.external_id ? (_jsx(Link, Object.assign({ to: (_a = getExternalCrmObjectLink(tenant.connected_crm, storedAppIntegrationData !== null && storedAppIntegrationData !== void 0 ? storedAppIntegrationData : [], CrmObjectTypeEnum.CONTACT, contact.external_id)) !== null && _a !== void 0 ? _a : "", target: "_blank" }, { children: _jsxs(Button, Object.assign({ variant: "tableAction", size: "tableAction" }, { children: [_jsx("img", { src: getConnectedCrmLogo(tenant.connected_crm), className: "w-4 h-4 shrink-0" }), "View"] })) }))) : (_jsxs(Button, Object.assign({ variant: "tableAction", size: "tableAction", onClick: () => {
                                                            setContactCreationModalOpen(true);
                                                            setSelectedContactId(contact.id);
                                                        } }, { children: [_jsx("img", { src: getConnectedCrmLogo(tenant.connected_crm), className: "w-4 h-4 shrink-0" }), "Create"] }))) })), contactEmailDraftBrickExists && (_jsxs(Button, Object.assign({ variant: "tableAction", size: "tableAction", onClick: () => onDraftEmailClick(contact) }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/gmail_logo.png", className: "shrink-0" }), "Draft"] }))), contact.linkedin_profile_url &&
                                                    contact.linkedin_profile_url.length > 0 && (_jsx(Link, Object.assign({ to: contact.linkedin_profile_url, target: "_blank" }, { children: _jsxs(Button, Object.assign({ variant: "tableAction", size: "tableAction" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png", className: "shrink-0" }), "Profile"] })) })))] })) }))] }), contact.id));
                        }) })] })), tenant && selectedContact && (_jsx(CallCrmContactModal, { tenant: tenant, contact: selectedContact, open: contactCreationModalOpen, setOpen: setContactCreationModalOpen, onSuccess: () => {
                    setSelectedContactId(null);
                    invalidateAccountContacts();
                } }))] }));
};
