var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CrmTableV2 } from "./crm_table_v2";
import { useMemo, useRef, useState } from "react";
import { CrmObjectTypeEnum, } from "../types";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../components/checkbox";
import { Button } from "../components/button";
import CrmFieldValue from "./crm_field_value";
import { createCrmAccount, createOrUpdateCrmAccountData, getAccountData, getActiveTenant, getAllBricks, queryCrmOwners, updateCrmAccount, } from "../common/endpoints";
import { useInfiniteQuery, useQuery, useQueryClient, } from "react-query";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { getFilterFieldType } from "./common/crm_field_utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import ReactMarkdown from "react-markdown";
import { ScrollArea } from "../components/scroll_area";
import { useCrmFields } from "./use_crm_fields";
import { Drawer, DrawerContent } from "../components/drawer_dialog";
import { AccountPage } from "./account_details";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
import { useCustomSearchParams } from "./use_memoised_search_params";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { Mosaic } from "react-mosaic-component";
import { ChatWithTable } from "../live_cards_chat_bot/chat_with_table";
import { getCrmChatSource } from "../live_cards_chat_bot/chat_source_menu";
import useTableChatMosaic from "../live_cards_chat_bot/use_table_chat_mosaic";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { BrickScoreDisplay } from "../bricks/brick_score_display";
import { useCrmFilterFetchers } from "./use_filters";
const DEFAULT_ACCOUNTS_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_ACCOUNTS_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Account",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "",
    column_order: [],
    columns: {
        "row-select": 75,
        name: 10,
        website: 150,
        linkedin_profile_url: 150,
        last_modified_time: 250,
        last_call_time: 250,
        last_call_link: 250,
        last_call_summary: 400,
    },
};
export const CrmAccountsV2 = () => {
    var _a, _b;
    const { searchParams, layouts, isLoading, isError } = useCustomSearchParams("Account", DEFAULT_TABLE_LAYOUT);
    const filter = searchParams.has("filter")
        ? ((_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_ACCOUNTS_FILTER)
        : DEFAULT_ACCOUNTS_FILTER;
    const orderBy = searchParams.has("order_by")
        ? ((_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_ACCOUNTS_ORDER_BY)
        : DEFAULT_ACCOUNTS_ORDER_BY;
    const { accountIdParam } = useParams();
    const hasAccountIdParam = accountIdParam !== undefined;
    const navigate = useNavigate();
    // State to store the CRM accounts keyed by their ID.
    // These are useful when the user inline edits a column of an account
    const [crmAccountsMap, setCrmAccountsMap] = useState(new Map());
    const [visibleColumns, setVisibleColumns] = useState();
    const queryClient = useQueryClient();
    const searchQuery = useRef("");
    const setSearchQuery = (query) => {
        searchQuery.current = query;
        queryClient.invalidateQueries({ queryKey: ["getPaginatedAccounts"] });
    };
    const filterQuery = JSON.stringify({
        table: "Account",
        structured_filter: {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    filter,
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: searchQuery.current,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "website",
                                        value: searchQuery.current,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        order_by_v2: orderBy,
    });
    const { data, fetchNextPage, isFetching, isLoading: isLoadingAccounts, } = useInfiniteQuery(["getPaginatedAccounts", filterQuery], ({ pageParam = 0 }) => getAccountData(filterQuery, pageParam + 1, 50), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        getNextPageParam: (_lastGroup, groups) => groups.length,
        onSettled: (data) => {
            if (!data)
                return;
            setCrmAccountsMap(new Map(data.pages
                .flatMap((page) => page.data)
                .map((account) => [account.id, account])));
        },
    });
    // Fetch all Account Research Blocks for rendering the research block
    // columns
    const { data: allBricksResponse } = useQuery({
        queryKey: ["allResearchBlocks"],
        queryFn: getAllBricks,
        refetchOnWindowFocus: false,
    });
    const allBricks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.bricks;
    const accountsPaginatedData = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const total = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0 ? data === null || data === void 0 ? void 0 : data.pages[0].total : 0; }, [data]);
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        const columns = [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsxs("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: [_jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }), _jsx(Link, Object.assign({ to: window.location.pathname +
                                `/account/${row.original.id}` +
                                window.location.search }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "group flex h-8 w-8 hover:bg-wds-gray-2 rounded-full text-wds-blue-3 hover:text-wds-blue-5 translate-y-0.5" }, { children: _jsx(ArrowTopRightOnSquareIcon, { className: "w-5 h-5 flex-shrink-0" }) })) }))] }))),
                enableHiding: false,
                size: 75,
            }),
            columnHelper.accessor((row) => row.name, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        setCrmAccountsMap((prevCrmAccountsMap) => {
                            const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                            if (updatedCrmAccount) {
                                updatedCrmAccount.name = newValue;
                                return new Map([
                                    ...prevCrmAccountsMap,
                                    [parseInt(props.row.id), updatedCrmAccount],
                                ]);
                            }
                            return prevCrmAccountsMap;
                        });
                        yield updateCrmAccount(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* website= */ undefined, 
                        /* linkedin_profile_url= */ undefined);
                    }) })),
                enableHiding: false,
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor("website", {
                id: "website",
                header: "Website",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -2, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: (_a = props.getValue()) !== null && _a !== void 0 ? _a : "", onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.website = newValue;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                            yield updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ newValue, 
                            /* linkedin_profile_url= */ undefined);
                        }) }));
                },
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor("linkedin_profile_url", {
                id: "linkedin_profile_url",
                header: "LinkedIn URL",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -3, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: (_a = props.getValue()) !== null && _a !== void 0 ? _a : "", onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.linkedin_profile_url = newValue;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                            yield updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ undefined, 
                            /* linkedin_profile_url= */ newValue);
                        }) }));
                },
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor("timestamp", {
                id: "timestamp",
                header: "Last modified time",
                cell: (props) => new Date(props.getValue()).toLocaleString(),
            }),
            columnHelper.accessor("last_call_time", {
                id: "last_call_time",
                header: "Last call time",
                cell: (props) => props.getValue() ? new Date(props.getValue()).toLocaleString() : null,
            }),
            columnHelper.accessor("last_call_link", {
                id: "last_call_link",
                header: "Last call link",
                cell: (props) => (_jsx("span", Object.assign({ className: "link-styles" }, { children: props.getValue() ? (_jsx("a", Object.assign({ href: props.getValue() }, { children: "Link to call" }))) : null }))),
            }),
            columnHelper.accessor("manager_name", {
                id: "manager_name",
                header: "Manager",
                cell: (props) => props.getValue(),
            }),
            columnHelper.accessor("team_name", {
                id: "team_name",
                header: "Team",
                cell: (props) => props.getValue(),
            }),
            columnHelper.accessor("department_name", {
                id: "department_name",
                header: "Department",
                cell: (props) => props.getValue(),
            }),
            columnHelper.accessor("last_call_summary", {
                id: "last_call_summary",
                header: "Last call summary",
                cell: (props) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: props.getValue() })) })) })] }) })),
            }),
            ...(allBricks !== null && allBricks !== void 0 ? allBricks : [])
                .filter((block) => block.research_block_type === "ACCOUNT")
                .map((researchBlock) => columnHelper.accessor((row) => {
                var _a, _b, _c;
                return (_c = (_b = (_a = row.research_block_outputs) === null || _a === void 0 ? void 0 : _a.find((blockOutput) => blockOutput.block_id === researchBlock.id)) === null || _b === void 0 ? void 0 : _b.markdown) !== null && _c !== void 0 ? _c : "";
            }, {
                id: `${researchBlock.id}`,
                header: researchBlock.name,
                cell: (props) => {
                    var _a;
                    const content = props.getValue();
                    if (!content || content.length === 0) {
                        return null;
                    }
                    // We go find the scoring brick (if any), which should be another brick in
                    // the same row (same account). These don't have the ACCOUNT research
                    // block type, so they don't show up aside from their parent brick.
                    const scoringBrickOutput = researchBlock.scoring_brick_id
                        ? (_a = props.row.original.research_block_outputs) === null || _a === void 0 ? void 0 : _a.find((blockOutput) => blockOutput.block_id === researchBlock.scoring_brick_id)
                        : null;
                    const scoringOutputAsJson = scoringBrickOutput === null || scoringBrickOutput === void 0 ? void 0 : scoringBrickOutput.markdown;
                    const scoringOutput = scoringOutputAsJson
                        ? JSON.parse(scoringOutputAsJson)
                        : null;
                    const scoringColor = scoringOutput === null || scoringOutput === void 0 ? void 0 : scoringOutput.score_color;
                    const rationale = scoringOutput === null || scoringOutput === void 0 ? void 0 : scoringOutput.rationale;
                    return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: "overflow-hidden text-ellipsis link-styles inline-flex items-center gap-1" }, { children: [scoringColor && (_jsx(BrickScoreDisplay, { scoring_color: scoringColor, rationale: rationale || "No rationale provided" })), _jsx(ReactMarkdown, Object.assign({ className: "flex line-clamp-2 " }, { children: props.getValue() }))] })) })), _jsx(TooltipContent, { children: _jsxs(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: [scoringColor && (_jsx(BrickScoreDisplay, { scoring_color: scoringColor, rationale: rationale || "No rationale provided" })), _jsx(ReactMarkdown, { children: props.getValue() })] })) })] }) }));
                },
                size: 400,
            })),
            columnHelper.accessor("owner_id", {
                id: "owner_id",
                header: "Owner",
                cell: (props) => {
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "crm_owner",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                const { results } = yield queryCrmOwners(userQuery, 
                                /* ids= */ undefined, cursor);
                                return {
                                    results: results.map((owner) => ({
                                        value: owner.id.toString(),
                                        label: owner.name || owner.email,
                                    })),
                                };
                            }),
                        }, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const ownerId = parseInt(option.value, 10);
                            const response = yield updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ undefined, 
                            /* linkedin_profile_url= */ undefined, 
                            /* owner_id= */ ownerId);
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.owner_id = response.owner_id;
                                    updatedCrmAccount.owner_name = response.owner_name;
                                    updatedCrmAccount.owner_email = response.owner_email;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                        }), selectedOption: props.row.original.owner_id
                            ? {
                                value: props.row.original.owner_id.toString(),
                                label: props.row.original.owner_name ||
                                    props.row.original.owner_email ||
                                    "",
                            }
                            : undefined }));
                },
            }),
        ];
        return columns;
    }, [allBricks]);
    const filterFields = useMemo(() => {
        if (columns.length === 0)
            return [];
        const filterFields = [
            ...columns
                .filter((column) => { var _a, _b; return !!((_b = (_a = column.meta) === null || _a === void 0 ? void 0 : _a.fieldSchema) === null || _b === void 0 ? void 0 : _b.type); })
                .map((column) => {
                var _a;
                return {
                    id: column.id,
                    name: (_a = column.header) !== null && _a !== void 0 ? _a : column.id,
                    type: getFilterFieldType(column.meta.fieldSchema),
                };
            }),
            {
                id: "last_call_time",
                name: "Last call time",
                type: "date",
                options: [
                    "Today",
                    "Yesterday",
                    "Tomorrow",
                    "Next week",
                    "Last week",
                    "Last 2 weeks",
                    "Last month",
                    "Last year",
                ],
            },
            {
                id: "timestamp",
                name: "Last modified time",
                type: "date",
                options: [
                    "Today",
                    "Yesterday",
                    "Tomorrow",
                    "Next week",
                    "Last week",
                    "Last 2 weeks",
                    "Last month",
                    "Last year",
                ],
            },
            {
                id: "owner_id",
                name: "Owner",
                type: "reference",
            },
            {
                id: "manager_id",
                name: "Manager",
                type: "reference",
            },
            {
                id: "team_id",
                name: "Team",
                type: "reference",
            },
            {
                id: "department_id",
                name: "Department",
                type: "reference",
            },
        ];
        return filterFields;
    }, [columns]);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmAccountsMap.values());
    }, [crmAccountsMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmAccount = yield createCrmAccount(newRowData.find((rowData) => rowData.columnId === "name").value, newRowData.find((rowData) => rowData.columnId === "website").value, newRowData.find((rowData) => rowData.columnId === "linkedin_profile_url")
            .value, crmFieldValues);
        setCrmAccountsMap((prevCrmAccountsMap) => {
            return new Map([
                ...prevCrmAccountsMap,
                [newCrmAccount.id, newCrmAccount],
            ]);
        });
    });
    const onCrmFieldValueChange = (accountId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        yield createOrUpdateCrmAccountData(accountId, crmField.id, newValue);
        setCrmAccountsMap((prevMap) => {
            const updatedAccount = prevMap.get(accountId);
            if (updatedAccount) {
                if (updatedAccount.account_fields) {
                    // Field values are stored in DB as JSONB with z+crmFieldId as the key
                    // There is a zprefix to ensure that the key is treated as a string and not a number
                    // otherwiser django queries fail
                    updatedAccount.account_fields[`z${crmField.id}`] = newValue;
                }
                else {
                    updatedAccount.account_fields = {
                        [`z${crmField.id}`]: newValue,
                    };
                }
                return new Map([...prevMap, [accountId, updatedAccount]]);
            }
            return prevMap;
        });
    });
    const { crmFields } = useCrmFields();
    const { referenceObjectFetcherMap } = useCrmFilterFetchers();
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        refetchOnWindowFocus: false,
    });
    const { chatCollapsed, updateChatCollapsed, mosaicState, onResize, onRelease, } = useTableChatMosaic();
    const enableChatWithCrmTable = useFeatureFlagEnabled("CHAT_WITH_CRM_TABLE");
    if (!accountsPaginatedData ||
        isLoadingAccounts ||
        !crmFields ||
        isLoading ||
        isError ||
        !layouts ||
        layouts.length === 0) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    const CrmTableElement = (_jsx(CrmTableV2, { crmObject: CrmObjectTypeEnum.ACCOUNT, crmFields: crmFields, tableRows: tableData, totalRows: total, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: referenceObjectFetcherMap, columnObjectsFetcher: [], defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, onSearchQueryChange: setSearchQuery, searchParams: searchParams, layouts: layouts, fetchNextPage: fetchNextPage, isFetching: isFetching, invalidateTableData: () => {
            queryClient.invalidateQueries({
                queryKey: ["getPaginatedAccounts"],
            });
        }, onVisibleColumnsChange: setVisibleColumns }));
    const renderTableAndChat = () => {
        if (!enableChatWithCrmTable) {
            return CrmTableElement;
        }
        return (_jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => {
                if (id === "table")
                    return CrmTableElement;
                if (id === "chat")
                    return (_jsx(ChatWithTable, { filterQuery: getConvertedJsonQuery(filterQuery), collapsed: chatCollapsed, setCollapsed: updateChatCollapsed, chatSource: getCrmChatSource("account", tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm), visibleColumns: visibleColumns }));
                return _jsx(_Fragment, {});
            }, initialValue: mosaicState, onChange: onResize, onRelease: onRelease, value: mosaicState, className: "h-[83vh] my-mosaic" }));
    };
    return (_jsxs(_Fragment, { children: [renderTableAndChat(), _jsx(Drawer, Object.assign({ open: hasAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate("/crm/home/accounts" + window.location.search);
                } }, { children: _jsx(DrawerContent, Object.assign({ className: "h-full sm:max-w-none w-[92vw] min-h-screen p-0 m-0", hideCloseButton: true }, { children: _jsx(AccountPage, {}) })) }))] }));
};
