import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import RightDrawer from "../right_drawer";
import { Separator } from "./separator";
import { Button } from "./button";
import { PencilEditIcon } from "../icons/editor_icons";
const ItemDetailsDrawer = (props) => {
    const { title, details, isOpen, onClose, deleteCallback } = props;
    return (_jsx(RightDrawer, Object.assign({ title: title, isOpen: isOpen, onClose: onClose, deleteCallback: deleteCallback }, { children: _jsxs("div", Object.assign({ className: "flex flex-col justify-between w-full h-full overflow-y-auto" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col space-y-6 pb-4 overflow-y-auto" }, { children: details.map((detail, index) => (_jsxs("div", Object.assign({ className: "flex flex-col space-y-1" }, { children: [_jsxs("div", Object.assign({ className: "text-wds-black text-lg font-semibold" }, { children: [detail.title, ":"] })), _jsx("div", Object.assign({ className: "text-wds-black text-sm font-normal leading-tight" }, { children: detail.value }))] }), index))) })), props.editCallback && (_jsxs("div", Object.assign({ className: "flex flex-col w-auto" }, { children: [_jsx(Separator, { className: "w-auto" }), _jsx("div", Object.assign({ className: "flex flex-row justify-end items-center w-full pt-4" }, { children: _jsxs(Button, Object.assign({ variant: "black", onClick: props.editCallback, className: "flex flex-row space-x-2 rounded-lg" }, { children: [_jsxs("span", { children: ["Edit ", props.itemName] }), _jsx(PencilEditIcon, { className: "h-4 w-4 text-white fill-white" })] })) }))] })))] })) })));
};
export default ItemDetailsDrawer;
