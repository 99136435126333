import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/button";
import { AccountBlockCard } from "../styled_components";
import { AccountResearchBlockOutputRefreshButton } from "./account_brick_refresh_button";
import ResearchBlockWithProvenance from "../../../common/research_block_with_provenance";
import classNames from "../../../class_names";
import { BrickScoreDisplay } from "../../../bricks/brick_score_display";
/**
 * This component renders a collapsed card for an account brick.
 *
 * @param account: The account object
 * @param brick: The pre-call prep block
 * @param accountResearchBlockOutput: The output of the research block for the account
 * @param generateResearchBlockOutput: Function to generate the output for the research block
 * @param outputGenerationInProgress: Whether the output is being generated
 * @returns
 */
export const AccountBrickOutputCollapsedCard = ({ account, brick, accountResearchBlockOutput, generateResearchBlockOutput, outputGenerationInProgress, onReadMoreClick, highlight, }) => {
    return (_jsx(AccountBlockCard, Object.assign({ className: classNames("rounded-3xl", highlight &&
            "border-2 shadow-[0_4px_8px_0_rgba(35,45,56,0.16)] border-[rgba(53,110,255,0.32)]") }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1.5 shrink-0 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 justify-between items-center self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-center gap-3" }, { children: [accountResearchBlockOutput.score_json && (_jsx(BrickScoreDisplay, { scoring_color: accountResearchBlockOutput.score_json.score_color, rationale: accountResearchBlockOutput.score_json.rationale })), _jsx("span", Object.assign({ className: "text-lg font-medium line-clamp-1" }, { children: brick.name })), _jsx("div", Object.assign({ className: "flex shrink-0 p-1 justify-center items-center rounded bg-wds-blue-1" }, { children: _jsx("span", Object.assign({ className: "text-wds-blue-3 text-xs" }, { children: "Custom research block" })) }))] })), _jsx(AccountResearchBlockOutputRefreshButton, { account: account, brick: brick, accountResearchBlockOutput: accountResearchBlockOutput, generateResearchBlockOutput: generateResearchBlockOutput, outputGenerationInProgress: outputGenerationInProgress })] })), _jsx(AccountBrickOutputWithReadMore, { accountResearchBlockOutput: accountResearchBlockOutput, onReadMoreClick: onReadMoreClick, outputGenerationInProgress: outputGenerationInProgress })] })) })));
};
export const AccountBrickOutputWithReadMore = ({ accountResearchBlockOutput, outputGenerationInProgress, onReadMoreClick, }) => {
    var _a;
    const [isClamped, setIsClamped] = useState(false);
    const contentRef = useRef(null);
    useEffect(() => {
        // Check for whether the content is clamped.
        // Ref: https://stackoverflow.com/questions/52169520/how-can-i-check-whether-line-clamp-is-enabled
        const checkClamping = () => {
            if (contentRef.current) {
                const { scrollHeight, clientHeight } = contentRef.current;
                setIsClamped(scrollHeight > clientHeight);
            }
        };
        const observer = new ResizeObserver(checkClamping);
        if (contentRef.current)
            observer.observe(contentRef.current);
        checkClamping();
        return () => {
            observer.disconnect();
        };
    }, []);
    return (_jsx("div", { children: accountResearchBlockOutput.html ? (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ ref: contentRef, className: "text-wds-gray-6 text-sm line-clamp-4 link-styles font-['Lato'] p-1" }, { children: _jsx(ResearchBlockWithProvenance, { htmlString: accountResearchBlockOutput.html, provenanceRecords: accountResearchBlockOutput.provenance_records, collapsable: false }) })), isClamped && (_jsx(Button, Object.assign({ variant: "ghost", className: "h-auto p-0 hover:bg-transparent", onClick: onReadMoreClick }, { children: "Read More" })))] })) : (_jsx(_Fragment, { children: outputGenerationInProgress ? (_jsx("span", Object.assign({ className: "animate-pulse text-sm text-wds-gray-5" }, { children: "Generating output..." }))) : (_jsx("span", Object.assign({ className: "text-sm text-wds-gray-5" }, { children: (_a = accountResearchBlockOutput === null || accountResearchBlockOutput === void 0 ? void 0 : accountResearchBlockOutput.error_message) !== null && _a !== void 0 ? _a : "No information available" }))) })) }));
};
