var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getActiveTenant, updateTenant } from "./common/endpoints";
import { Card, CardContent, CardFooter, CardHeader, CardTitle, } from "./components/card";
import { SettingsLoader } from "./common/loaders";
import { useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "./components/alert";
import { Separator } from "./components/separator";
import { Button } from "./components/button";
import { PencilIcon, PlusCircleIcon, TrashIcon, } from "@heroicons/react/24/outline";
import BaseModal2 from "./base_modal2";
import { z } from "zod";
import { Tooltip, TooltipProvider, TooltipContent, TooltipTrigger, } from "./components/tooltip";
const TENANT_METADATA_SCHEMA = z.object({
    Name: z.string().min(1),
    Value: z.string().min(1),
});
const FORM_FIELD_META = {
    Name: {
        type: "text",
        placeholder: "Name",
        defaultValue: "",
        required: true,
    },
    Value: {
        type: "textarea",
        placeholder: "Value",
        defaultValue: "",
        required: true,
    },
};
export const CompanyDetailsIndex = () => {
    const [companyMetadata, setCompanyMetadata] = useState(new Map());
    // Tenant description is stored in the Tenant model itself instead of company_metadata
    // and hence added separately in the component.
    const [tenantDescription, setTenantDescription] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [addNewField, setAddNewField] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { data: activeTenant, isLoading } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        onSuccess: (tenant) => {
            var _a;
            setTenantDescription(tenant.description);
            setCompanyMetadata(new Map(Object.entries((_a = tenant.company_metadata) !== null && _a !== void 0 ? _a : {})));
        },
        refetchOnWindowFocus: false,
    });
    const queryClient = useQueryClient();
    const updateCompanyMetadataMutation = useMutation({
        mutationFn: ({ updatedDescription, updatedCompanyMetadata, }) => {
            if (!activeTenant) {
                return Promise.reject("No active tenant");
            }
            return updateTenant(Object.assign(Object.assign({}, activeTenant), { description: updatedDescription !== null && updatedDescription !== void 0 ? updatedDescription : activeTenant.description, company_metadata: updatedCompanyMetadata
                    ? Object.fromEntries(updatedCompanyMetadata)
                    : activeTenant.company_metadata }));
        },
        onSuccess: () => {
            queryClient.invalidateQueries("activeTenant");
        },
    });
    const renderMetadataField = (key, value, onValueChange, removable) => {
        return (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("span", Object.assign({ className: "w-1/3 text-wds-gray-5 text-sm font-medium" }, { children: key })), isEditing ? (_jsxs("div", Object.assign({ className: "flex items-center w-2/3" }, { children: [_jsx("textarea", { value: value, onChange: (e) => onValueChange(e.target.value), className: "w-full rounded-md outline-none focus:ring-0 focus:border-wds-blue-3" }), removable && (_jsx(Button, Object.assign({ variant: "ghost", className: "hover:bg-transparent text-wds-gray-5 hover:text-red-500", onClick: () => {
                                setCompanyMetadata((prev) => {
                                    const newMap = new Map(prev);
                                    newMap.delete(key);
                                    return newMap;
                                });
                            } }, { children: _jsx(TrashIcon, { className: "w-4 h-4 shrink-0" }) })))] }))) : (_jsx(_Fragment, { children: value && value.length > 0 ? (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("span", Object.assign({ className: "w-2/3 text-sm font-medium line-clamp-3" }, { children: value })) })), _jsx(TooltipContent, Object.assign({ align: "start", className: "w-96" }, { children: value }))] }) })) : (_jsx("span", Object.assign({ className: "text-wds-gray-5" }, { children: "Not provided" }))) }))] }), key));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-2 p-4 bg-wds-gray-1 min-h-screen items-center" }, { children: [_jsx("span", Object.assign({ className: "w-full text-black text-3xl p-2" }, { children: "Company Details" })), _jsxs(Alert, Object.assign({ variant: "default" }, { children: [_jsx(AlertTitle, Object.assign({ className: "text-base font-medium text-wds-gray-6" }, { children: "How to use company details in custom prompts?" })), _jsx(AlertDescription, Object.assign({ className: "text-sm text-wds-gray-5" }, { children: `You can use the below fields in your custom prompts by
            using the template variables. For example, if you have a field called 
            "Typical pain points", you can use the template {typical_pain_points} in your custom prompts.` }))] })), isLoading || !activeTenant ? (_jsx(SettingsLoader, {})) : (_jsxs(Card, Object.assign({ className: "mt-4 w-2/3" }, { children: [_jsxs(CardHeader, { children: [_jsxs("div", Object.assign({ className: "flex gap-4 justify-between self-stretch" }, { children: [_jsx(CardTitle, Object.assign({ className: "text-wds-gray-5 text-lg font-medium" }, { children: "Additional Information" })), _jsxs("div", Object.assign({ className: "flex gap-1" }, { children: [_jsxs(Button, Object.assign({ variant: "invertedBlack", onClick: () => setAddNewField(true), className: "gap-1" }, { children: [_jsx(PlusCircleIcon, { className: "w-4 h-4 shrink-0" }), "Add new"] })), !isEditing && (_jsxs(Button, Object.assign({ variant: "invertedBlack", onClick: () => setIsEditing(true), className: "gap-1 border border-wds-black rounded-lg px-2" }, { children: [_jsx(PencilIcon, { className: "w-4 h-4 shrink-0" }), "Edit"] })))] }))] })), _jsx(Separator, { orientation: "horizontal" })] }), _jsx(CardContent, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-3" }, { children: [renderMetadataField("Description", tenantDescription, (value) => setTenantDescription(value), 
                                        /* removable= */ false), Array.from(companyMetadata.entries()).map(([key, value]) => renderMetadataField(key, value, (value) => setCompanyMetadata(new Map(companyMetadata).set(key, value)), 
                                        /* removable= */ true))] })) }), isEditing && (_jsxs(CardFooter, Object.assign({ className: "flex flex-col gap-2 items-end justify-center" }, { children: [_jsx(Separator, { orientation: "horizontal" }), _jsxs("div", Object.assign({ className: "flex gap-1" }, { children: [_jsx(Button, Object.assign({ variant: "invertedBlack", onClick: () => {
                                                    var _a;
                                                    setIsEditing(false);
                                                    setTenantDescription(activeTenant.description);
                                                    setCompanyMetadata(new Map(Object.entries((_a = activeTenant.company_metadata) !== null && _a !== void 0 ? _a : {})));
                                                }, className: "rounded-lg border border-wds-black" }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: "black", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    setIsSaving(true);
                                                    yield updateCompanyMetadataMutation.mutateAsync({
                                                        updatedDescription: tenantDescription,
                                                        updatedCompanyMetadata: companyMetadata,
                                                    });
                                                    setIsEditing(false);
                                                    setIsSaving(false);
                                                }), className: "rounded-lg", disabled: isSaving }, { children: isSaving ? "Saving..." : "Save" }))] }))] })))] })))] })), _jsx(BaseModal2, { title: "Add company details", description: "Add a new metadata for your company.", open: addNewField, setOpen: setAddNewField, submitText: "Add", submitCallback: (values) => __awaiter(void 0, void 0, void 0, function* () {
                    const updatedMetadata = new Map(companyMetadata);
                    updatedMetadata.set(values.Name, values.Value);
                    yield updateCompanyMetadataMutation.mutateAsync({
                        updatedCompanyMetadata: updatedMetadata,
                    });
                    setAddNewField(false);
                }), zodSchema: TENANT_METADATA_SCHEMA, formFieldMeta: FORM_FIELD_META })] }));
};
