import { useQuery, useQueryClient } from "react-query";
import { getCrmContacts } from "../../common/endpoints";
export const useAccountContacts = ({ accountId }) => {
    const { data: accountContacts, isLoading: contactsLoading, isError: contactsError, } = useQuery({
        queryKey: ["accountContacts", accountId],
        queryFn: () => getCrmContacts(accountId),
        staleTime: Infinity,
    });
    const queryClient = useQueryClient();
    const invalidateAccountContacts = () => {
        queryClient.invalidateQueries({
            queryKey: ["accountContacts", accountId],
        });
    };
    return {
        accountContacts,
        contactsLoading,
        contactsError,
        invalidateAccountContacts,
    };
};
