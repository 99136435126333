var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CrmTableV2 } from "./crm_table_v2";
import { useMemo, useRef, useState } from "react";
import { CrmObjectTypeEnum, } from "../types";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../components/checkbox";
import CrmFieldValue from "./crm_field_value";
import { createCrmLead, getActiveTenant, getCrmLead, getLeadData, queryCrmOwners, updateCrmLead, } from "../common/endpoints";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
import { useCrmFields } from "./use_crm_fields";
import { useCustomSearchParams } from "./use_memoised_search_params";
import useTableChatMosaic from "../live_cards_chat_bot/use_table_chat_mosaic";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { ChatWithTable } from "../live_cards_chat_bot/chat_with_table";
import { getCrmChatSource } from "../live_cards_chat_bot/chat_source_menu";
import { Mosaic } from "react-mosaic-component";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { accountsFetcher, contactsFetcher, opportunitiesFetcher, } from "../calls_table/calls_table_fetchers";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, DrawerContent } from "../components/drawer_dialog";
import { AccountPage } from "./account_details";
import { useCrmFilterFetchers } from "./use_filters";
const DEFAULT_LEADS_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_LEADS_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Lead",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "",
    columns: {
        "row-select": 35,
        name: 150,
        email: 150,
        webiste: 150,
        company_name: 200,
        account: 200,
        contact: 200,
        opportunity: 200,
    },
    column_order: [],
};
export const CrmLeads = () => {
    var _a, _b;
    const { searchParams, layouts, isLoading, isError } = useCustomSearchParams("Lead", DEFAULT_TABLE_LAYOUT);
    const filter = searchParams.has("filter")
        ? ((_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_LEADS_FILTER)
        : DEFAULT_LEADS_FILTER;
    const orderBy = searchParams.has("order_by")
        ? ((_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_LEADS_ORDER_BY)
        : DEFAULT_LEADS_ORDER_BY;
    const { accountIdParam } = useParams();
    const hasAccountIdParam = accountIdParam !== undefined;
    const navigate = useNavigate();
    // State to store the CRM leads keyed by their ID.
    const [crmLeadsMap, setCrmLeadsMap] = useState(new Map());
    const searchQuery = useRef("");
    const setSearchQuery = (query) => {
        searchQuery.current = query;
        queryClient.invalidateQueries({ queryKey: ["getLeadData"] });
    };
    const [visibleColumns, setVisibleColumns] = useState();
    const filterQuery = JSON.stringify({
        table: "Lead",
        structured_filter: {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    filter,
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "email",
                                        value: searchQuery.current,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: searchQuery.current,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        order_by_v2: orderBy,
    });
    const { data, fetchNextPage, isFetching, isLoading: isLoadingLeads, } = useInfiniteQuery(["getLeadData", filterQuery], ({ pageParam = 0 }) => getLeadData(filterQuery, pageParam + 1, 50), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        getNextPageParam: (_lastGroup, groups) => groups.length,
        onSettled: (data) => {
            if (!data)
                return;
            setCrmLeadsMap(new Map(data.pages
                .flatMap((page) => page.data)
                .map((lead) => [lead.id, lead])));
        },
    });
    const leadsPaginatedData = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const total = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0 ? data === null || data === void 0 ? void 0 : data.pages[0].total : 0; }, [data]);
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        const columns = [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "flex items-center justify-center pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
                enableHiding: false,
                size: 35,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 100 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmLead(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* email= */ undefined, 
                        /* companyName= */ undefined, 
                        /* website= */ undefined, 
                        /* accountId= */ undefined, 
                        /* contactId= */ undefined, 
                        /* opportunityId= */ undefined, 
                        /* ownerId= */ undefined, 
                        /* fieldValues= */ undefined);
                        setCrmLeadsMap((prevMap) => {
                            const updatedLead = prevMap.get(parseInt(props.row.id));
                            if (updatedLead) {
                                updatedLead.name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedLead],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                enableHiding: false,
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 100 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.email) !== null && _a !== void 0 ? _a : ""; }, {
                id: "email",
                header: "Email",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 60 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmLead(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ newValue, 
                        /* companyName= */ undefined, 
                        /* website= */ undefined, 
                        /* accountId= */ undefined, 
                        /* contactId= */ undefined, 
                        /* opportunityId= */ undefined, 
                        /* ownerId= */ undefined, 
                        /* fieldValues= */ undefined);
                        setCrmLeadsMap((prevMap) => {
                            const updatedLead = prevMap.get(parseInt(props.row.id));
                            if (updatedLead) {
                                updatedLead.email = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedLead],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 60 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.company_name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "company_name",
                header: "Company name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 500 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmLead(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* companyName= */ newValue, 
                        /* website= */ undefined, 
                        /* accountId= */ undefined, 
                        /* contactId= */ undefined, 
                        /* opportunityId= */ undefined, 
                        /* ownerId= */ undefined, 
                        /* fieldValues= */ undefined);
                        setCrmLeadsMap((prevMap) => {
                            const updatedLead = prevMap.get(parseInt(props.row.id));
                            if (updatedLead) {
                                updatedLead.company_name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedLead],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 500 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.website) !== null && _a !== void 0 ? _a : ""; }, {
                id: "website",
                header: "Website",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 500 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmLead(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* companyName= */ undefined, 
                        /* website= */ newValue, 
                        /* accountId= */ undefined, 
                        /* contactId= */ undefined, 
                        /* opportunityId= */ undefined, 
                        /* ownerId= */ undefined, 
                        /* fieldValues= */ undefined);
                        setCrmLeadsMap((prevMap) => {
                            const updatedLead = prevMap.get(parseInt(props.row.id));
                            if (updatedLead) {
                                updatedLead.website = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedLead],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 500 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.account_id) !== null && _a !== void 0 ? _a : ""; }, {
                id: "account",
                header: "Account",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "account",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                return accountsFetcher(userQuery, 
                                /* ids= */ undefined, cursor, queryClient);
                            }),
                        }, selectedOption: props.row.original.account_id
                            ? {
                                label: (_a = props.row.original.account_name) !== null && _a !== void 0 ? _a : "",
                                value: `${props.row.original.account_id}`,
                            }
                            : undefined, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const updatedLead = yield updateCrmLead(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* companyName= */ undefined, 
                            /* website= */ undefined, 
                            /* accountId= */ parseInt(option.value), 
                            /* contactId= */ undefined, 
                            /* opportunityId= */ undefined, 
                            /* ownerId= */ undefined, 
                            /* fieldValues= */ undefined);
                            if (updatedLead.type === "error") {
                                // TODO: Surface the error message in the UI.
                                return;
                            }
                            setCrmLeadsMap((prevMap) => {
                                const existingLead = prevMap.get(parseInt(props.row.id));
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(updatedLead.id, Object.assign(Object.assign({}, existingLead), { account_id: updatedLead.account_id, account_name: updatedLead.account_name }));
                                return updatedMap;
                            });
                        }) }));
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.contact_id) !== null && _a !== void 0 ? _a : ""; }, {
                id: "contact",
                header: "Contact",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "contact",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                return contactsFetcher(userQuery, 
                                /* ids= */ undefined, cursor, queryClient);
                            }),
                        }, selectedOption: props.row.original.contact_id
                            ? {
                                label: (_a = props.row.original.contact_name) !== null && _a !== void 0 ? _a : "",
                                value: `${props.row.original.contact_id}`,
                            }
                            : undefined, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const updatedLead = yield updateCrmLead(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* companyName= */ undefined, 
                            /* website= */ undefined, 
                            /* accountId= */ undefined, 
                            /* contactId= */ parseInt(option.value), 
                            /* opportunityId= */ undefined, 
                            /* ownerId= */ undefined, 
                            /* fieldValues= */ undefined);
                            if (updatedLead.type === "error") {
                                // TODO: Surface the error message in the UI.
                                return;
                            }
                            setCrmLeadsMap((prevMap) => {
                                const existingLead = prevMap.get(parseInt(props.row.id));
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(updatedLead.id, Object.assign(Object.assign({}, existingLead), { contact_id: updatedLead.account_id, contact_name: updatedLead.contact_name }));
                                return updatedMap;
                            });
                        }) }));
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.opportunity_id) !== null && _a !== void 0 ? _a : ""; }, {
                id: "opportunity",
                header: "Opportunity",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "opportunity",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                return opportunitiesFetcher(userQuery, 
                                /* ids= */ undefined, cursor, queryClient);
                            }),
                        }, selectedOption: props.row.original.opportunity_id
                            ? {
                                label: (_a = props.row.original.opportunity_name) !== null && _a !== void 0 ? _a : "",
                                value: `${props.row.original.opportunity_id}`,
                            }
                            : undefined, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const updatedLead = yield updateCrmLead(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* companyName= */ undefined, 
                            /* website= */ undefined, 
                            /* accountId= */ undefined, 
                            /* contactId= */ undefined, 
                            /* opportunityId= */ parseInt(option.value), 
                            /* ownerId= */ undefined, 
                            /* fieldValues= */ undefined);
                            if (updatedLead.type === "error") {
                                // TODO: Surface the error message in the UI.
                                return;
                            }
                            setCrmLeadsMap((prevMap) => {
                                const existingLead = prevMap.get(parseInt(props.row.id));
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(updatedLead.id, Object.assign(Object.assign({}, existingLead), { opportunity_id: updatedLead.opportunity_id, opportunity_name: updatedLead.opportunity_name }));
                                return updatedMap;
                            });
                        }) }));
                },
            }),
            columnHelper.accessor("owner_id", {
                id: "owner_id",
                header: "Owner",
                cell: (props) => {
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "crm_owner",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                const { results } = yield queryCrmOwners(userQuery, 
                                /* ids= */ undefined, cursor);
                                return {
                                    results: results.map((owner) => ({
                                        value: owner.id.toString(),
                                        label: owner.name || owner.email,
                                    })),
                                };
                            }),
                        }, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const ownerId = parseInt(option.value, 10);
                            const updatedLead = yield updateCrmLead(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* companyName= */ undefined, 
                            /* website= */ undefined, 
                            /* accountId= */ undefined, 
                            /* contactId= */ undefined, 
                            /* opportunityId= */ ownerId, 
                            /* ownerId= */ undefined, 
                            /* fieldValues= */ undefined);
                            if (updatedLead.type === "error") {
                                // TODO: Surface the error message in the UI.
                                return;
                            }
                            setCrmLeadsMap((prevMap) => {
                                const existingLead = prevMap.get(parseInt(props.row.id));
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(updatedLead.id, Object.assign(Object.assign({}, existingLead), { owner_id: updatedLead.owner_id, owner_email: updatedLead.owner_email, owner_name: updatedLead.owner_name }));
                                return updatedMap;
                            });
                        }), selectedOption: props.row.original.owner_id
                            ? {
                                value: props.row.original.owner_id.toString(),
                                label: props.row.original.owner_name ||
                                    props.row.original.owner_email ||
                                    "",
                            }
                            : undefined }));
                },
            }),
        ];
        return columns;
    }, []);
    const queryClient = useQueryClient();
    const { referenceObjectFetcherMap } = useCrmFilterFetchers();
    const filterFields = useMemo(() => {
        const filterFields = [
            {
                id: "name",
                name: "Name",
                type: "string",
            },
            {
                id: "email",
                name: "Email",
                type: "string",
            },
            {
                id: "account_id",
                name: "Account",
                type: "reference",
            },
            {
                id: "contact_id",
                name: "Contact",
                type: "reference",
            },
            {
                id: "opportunity_id",
                name: "Opportunity",
                type: "reference",
            },
            {
                id: "owner_id",
                name: "Owner",
                type: "reference",
            },
        ];
        return filterFields;
    }, []);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmLeadsMap.values());
    }, [crmLeadsMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f, _g;
        const newCrmLead = yield createCrmLead(newRowData.find((rowData) => rowData.columnId === "name").value, newRowData.find((rowData) => rowData.columnId === "email").value, crmFieldValues, (_c = newRowData.find((rowData) => rowData.columnId === "company_name")) === null || _c === void 0 ? void 0 : _c.value, (_d = newRowData.find((rowData) => rowData.columnId === "website")) === null || _d === void 0 ? void 0 : _d.value, (_e = newRowData.find((rowData) => rowData.columnId === "account")) === null || _e === void 0 ? void 0 : _e.value, (_f = newRowData.find((rowData) => rowData.columnId === "contact")) === null || _f === void 0 ? void 0 : _f.value, (_g = newRowData.find((rowData) => rowData.columnId === "opportunity")) === null || _g === void 0 ? void 0 : _g.value);
        if (newCrmLead.type === "error") {
            // TODO: Surface the error message in the UI.
            return;
        }
        const updatedCrmLead = yield getCrmLead(newCrmLead.id);
        setCrmLeadsMap((prevMap) => {
            return new Map([...prevMap, [updatedCrmLead.id, updatedCrmLead]]);
        });
    });
    const onCrmFieldValueChange = (leadId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateCrmLead(leadId, 
        /* name= */ undefined, 
        /* email= */ undefined, 
        /* companyName= */ undefined, 
        /* website= */ undefined, 
        /* accountId= */ undefined, 
        /* contactId= */ undefined, 
        /* opportunityId= */ undefined, 
        /* ownerId= */ undefined, { [crmField.id]: newValue });
        setCrmLeadsMap((prevMap) => {
            const updatedLead = prevMap.get(leadId);
            if (updatedLead) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedLead.lead_fields) {
                    updatedLead.lead_fields[`z${crmField.id}`] = newValue;
                }
                else {
                    updatedLead.lead_fields = {
                        [`z${crmField.id}`]: newValue,
                    };
                }
                return new Map([...prevMap, [leadId, updatedLead]]);
            }
            return prevMap;
        });
    });
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        refetchOnWindowFocus: false,
    });
    const { crmFields } = useCrmFields();
    const { chatCollapsed, updateChatCollapsed, mosaicState, onResize, onRelease, } = useTableChatMosaic();
    const enableChatWithCrmTable = useFeatureFlagEnabled("CHAT_WITH_CRM_TABLE");
    if (!leadsPaginatedData ||
        isLoadingLeads ||
        !crmFields ||
        isLoading ||
        isError ||
        !layouts ||
        layouts.length === 0) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    const CrmTableElement = (_jsx(CrmTableV2, { crmObject: CrmObjectTypeEnum.LEAD, crmFields: crmFields, tableRows: tableData, totalRows: total, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: referenceObjectFetcherMap, columnObjectsFetcher: [
            {
                columnId: "account",
                objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
            },
            {
                columnId: "opportunity",
                objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () { return opportunitiesFetcher(userQuery, /* ids= */ undefined, cursor); }),
            },
            {
                columnId: "contact",
                objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () { return contactsFetcher(userQuery, /* ids= */ undefined, cursor); }),
            },
        ], defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, onSearchQueryChange: setSearchQuery, searchParams: searchParams, layouts: layouts, fetchNextPage: fetchNextPage, isFetching: isFetching, invalidateTableData: () => {
            queryClient.invalidateQueries({
                queryKey: ["getLeadData"],
            });
        }, onVisibleColumnsChange: setVisibleColumns }));
    if (!enableChatWithCrmTable) {
        return CrmTableElement;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => {
                    if (id === "table")
                        return CrmTableElement;
                    if (id === "chat")
                        return (_jsx(ChatWithTable, { filterQuery: getConvertedJsonQuery(filterQuery), collapsed: chatCollapsed, setCollapsed: updateChatCollapsed, chatSource: getCrmChatSource("lead", tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm), visibleColumns: visibleColumns }));
                    return _jsx(_Fragment, {});
                }, initialValue: mosaicState, onChange: onResize, onRelease: onRelease, value: mosaicState, className: "h-[83vh] my-mosaic" }), _jsx(Drawer, Object.assign({ open: hasAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate("/crm/home/leads" + window.location.search);
                } }, { children: _jsx(DrawerContent, Object.assign({ className: "h-full sm:max-w-none w-[92vw] min-h-screen p-0 m-0", hideCloseButton: true }, { children: _jsx(AccountPage, {}) })) }))] }));
};
