import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Checkbox } from "../components/checkbox";
import { Button } from "../components/button";
const DeliveryTargetSelection = ({ deliveryTargets, setDeliveryTargets, deliveryTargetOptions = [], }) => {
    var _a, _b;
    const [selectedParentTarget, setSelectedParentTarget] = useState((_b = (_a = deliveryTargetOptions[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null);
    const handleTargetChange = (targetId, checked) => {
        const newDeliveryTargets = new Set(deliveryTargets);
        if (checked) {
            newDeliveryTargets.add(targetId);
        }
        else {
            newDeliveryTargets.delete(targetId);
        }
        setDeliveryTargets(newDeliveryTargets);
    };
    return (_jsxs("div", Object.assign({ className: "self-stretch bg-white flex flex-col justify-start items-start gap-6 text-wds-black" }, { children: [_jsx("div", Object.assign({ className: "text-xl font-normal leading-none" }, { children: "Prompt Destination" })), _jsxs("div", Object.assign({ className: "h-[416px] flex flex-row w-full justify-start items-start border border-wds-gray-3 rounded-lg overflow-hidden text-sm" }, { children: [_jsx("div", Object.assign({ className: "w-1/3 h-full bg-wds-gray-1 py-2 overflow-y-auto" }, { children: deliveryTargetOptions.map((target) => (_jsxs(Button, Object.assign({ variant: "ghost", className: `px-3 py-2.5 w-full h-auto rounded-none text-black hover:bg-wds-gray-2 ${selectedParentTarget === target.id ? "bg-wds-gray-2" : ""} flex justify-between items-center whitespace-normal text-left`, onClick: () => setSelectedParentTarget(target.id) }, { children: [_jsx("span", { children: target.name }), deliveryTargets.has(target.id) && (_jsx("div", Object.assign({ className: "w-5 h-5 rounded-full flex-shrink-0 bg-green-600 flex items-center justify-center ml-2" }, { children: _jsx(CheckIcon, { className: "w-4 h-4 stroke-2 text-white" }) })))] }), target.id))) })), _jsx("div", Object.assign({ className: "w-2/3 h-full p-4 overflow-y-auto" }, { children: selectedParentTarget && (_jsx("div", Object.assign({ className: "flex flex-col gap-2" }, { children: deliveryTargetOptions
                                .filter((target) => target.id === selectedParentTarget)
                                .map((target) => (_jsx("label", Object.assign({ className: "flex flex-col w-full py-1 justify-start items-start gap-2.5" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row justify-start items-center gap-1.5" }, { children: [_jsx(Checkbox, { checked: deliveryTargets.has(target.id), onCheckedChange: (checked) => handleTargetChange(target.id, checked) }), _jsx("div", Object.assign({ className: "font-medium leading-tight" }, { children: target.name }))] })) }), target.id))) }))) }))] }))] })));
};
export default DeliveryTargetSelection;
