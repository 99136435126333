import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { getColumnIdColumnNameMap, getColumns } from "./calls_table_columns";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
import { CallsTableToolbarActions } from "./calls_table_toolbar_actions";
import { useNavigate, useParams } from "react-router-dom";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { FIELDS, SORTABLE_FIELDS } from "./calls_table_filters";
import { useBuildFieldsForFilter, useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import CallPage from "../home/call_v3";
import { useQuery, useQueryClient, } from "react-query";
import { getAppIntegrationData } from "../common/endpoints";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
import { AccountPage } from "../crm/account_details";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
export function CallsTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { callIdParam, accountIdParam } = useParams();
    const hasCallOrAccountIdParam = callIdParam !== undefined || accountIdParam !== undefined;
    const calls = data.calls;
    const total = data.total;
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", data.tenant.id],
        queryFn: getAppIntegrationData,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    // Memoize the columns so they don't re-render on every render
    const columns = React.useMemo(() => getColumns(data.tenant, data.filterQuery, data.crmFields, queryClient, data.playbookItemGroups, data.allTrackerBricks, appIntegrationData), [data.playbookItemGroups, appIntegrationData]);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = React.useState(getColumnOrder(columns, matchingLayout));
    const columnIdMap = React.useMemo(() => getColumnIdColumnNameMap(), []);
    const playbook_item_names = data.playbookItemGroups
        ? data.playbookItemGroups.map((item) => item.default_item.name)
        : data.allTrackerBricks.map((item) => item.name);
    const fieldsForFilter = useBuildFieldsForFilter(data.crmFields, FIELDS, "calls");
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(fieldsForFilter);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const { table } = useDataTable({
        data: calls,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: Object.assign(Object.assign(Object.assign({ call_state: false, call_state_with_reason: false, call_source: false, meeting_host: false, participants: false, call_duration_minutes: false, post_call_all_slack_items_sent: false, post_call_any_slack_item_sent: false, post_call_crm_updates_mapped: false, post_call_crm_updates_pushed: false, playbook_content: false, description: false, account_id: false, opportunity_id: false, salesperson_speech_speed_wpm: false, salesperson_talk_time_percent: false, autojoin: false }, Object.fromEntries(playbook_item_names.map((name) => [name, false]))), Object.fromEntries(data.crmFields.map((crmField) => [
                `account_fields__z${crmField.id.toString()}`,
                false,
            ]))), Object.fromEntries(data.crmFields.map((crmField) => [
                `opportunity_fields__z${crmField.id.toString()}`,
                false,
            ]))),
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, floatingBar: _jsx(CallsTableFloatingBar, { table: table }), columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: data.fetchNextPage, total: total, totalFetched: calls.length, isFetching: data.isFetching, subTabTriggerRenderer: _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx(PhoneIcon, { className: "h-4 w-4 text-wds-gray-5" }), _jsx("span", { children: "Calls" })] })) }, { children: _jsx(DataTableToolbarV2, Object.assign({ table: table, columnIdMap: columnIdMap, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_TABLE_LAYOUT, onVisibleColumnsChange: data.onVisibleColumnsChange, onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me calls", sortRuleBuilderPanelTitle: "Sort calls by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, onSearchQueryChange: data.setSearchQuery, matchingLayout: matchingLayout }, { children: _jsx(CallsTableToolbarActions, { table: table, filterQueryJson: getConvertedJsonQuery(data.filterQuery) }) })) })), _jsx(Drawer, Object.assign({ open: hasCallOrAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate("/calls" + window.location.search);
                } }, { children: _jsxs(DrawerContent, Object.assign({ className: "h-full sm:max-w-none p-0 w-[92vw] min-h-screen p-0 m-0", hideCloseButton: true }, { children: [!accountIdParam && (_jsxs(_Fragment, { children: [_jsx(DrawerTitle, Object.assign({ className: "hidden" }, { children: "Call Details" })), _jsx(CallPage, { updateSelectedCall: () => { }, onClose: () => navigate("/calls" + window.location.search) })] })), accountIdParam && _jsx(AccountPage, { accId: accountIdParam })] })) }))] }));
}
