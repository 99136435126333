import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowTopRightOnSquareIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon, MinusIcon, PhoneIcon, PlusIcon, UserIcon, } from "@heroicons/react/24/outline";
import { AccountBlockCard } from "./styled_components";
import { useAccountCalls } from "./use_account_calls";
import { CallItemLoaderFrame } from "../../common/loaders";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "../../components/table";
import { useMemo, useState } from "react";
import { Button } from "../../components/button";
import { Link } from "react-router-dom";
import Badge from "../../common/badge";
import { useQuery } from "react-query";
import { getLiveCallSummary } from "../../common/endpoints";
import classNames from "../../class_names";
import CallTimeDisplay from "../../home/call_time_display";
import { Separator } from "../../components/separator";
const CallSummary = ({ call, expanded, }) => {
    var _a;
    const { data: callSummary, isLoading: callSummaryLoading } = useQuery({
        queryKey: ["callSummary", call.id],
        queryFn: () => getLiveCallSummary(call.id),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const isFutureCall = new Date(call.call_time) > new Date();
    const DEFAULT_ZERO_SUMMARY_TEXT = isFutureCall
        ? "A summary will be available after the call has taken place"
        : "No summary available for this call";
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: classNames("text-wds-gray-6 text-sm", expanded ? "" : "line-clamp-1") }, { children: call.name })), callSummaryLoading ? (_jsx(CallItemLoaderFrame, {})) : (_jsx("span", Object.assign({ className: classNames("text-wds-gray-5 text-sm", expanded ? "" : "line-clamp-2") }, { children: (_a = callSummary === null || callSummary === void 0 ? void 0 : callSummary.call_brief) !== null && _a !== void 0 ? _a : DEFAULT_ZERO_SUMMARY_TEXT })))] })));
};
const CallAttendees = ({ call, expanded, setExpanded, }) => {
    const DEFAULT_NUMBER_OF_PARTICIPANTS_SHOWN = 2;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start justify-center gap-1" }, { children: [call.participants
                .slice(0, expanded ? undefined : DEFAULT_NUMBER_OF_PARTICIPANTS_SHOWN)
                .map((p) => {
                var _a;
                return (_jsxs("div", Object.assign({ className: "flex items-center gap-1" }, { children: [p.profile_pic && p.profile_pic.length > 0 ? (_jsx("img", { src: p.profile_pic_url, className: "h-6 w-6 rounded-full" })) : (_jsx("div", Object.assign({ className: "rounded-full" }, { children: _jsx(UserIcon, { className: "h-6 w-6 text-wds-gray-4" }) }))), _jsx("span", Object.assign({ className: "text-wds-gray-6 text-sm line-clamp-1 w-32 overflow-hidden" }, { children: (_a = p.contact_name) !== null && _a !== void 0 ? _a : p.email }))] }), p.id));
            }), call.participants.length > DEFAULT_NUMBER_OF_PARTICIPANTS_SHOWN && (_jsx(Button, Object.assign({ variant: "ghost", onClick: () => setExpanded(!expanded), className: "flex gap-1 hover:bg-transparent p-0" }, { children: expanded ? (_jsxs(_Fragment, { children: [_jsx(MinusIcon, { className: "w-4 h-4 text-wds-blue-3" }), _jsx("span", { children: "Hide" })] })) : (_jsxs(_Fragment, { children: [_jsx(PlusIcon, { className: "w-4 h-4 text-wds-blue-3" }), _jsx("span", { children: `${call.participants.length - DEFAULT_NUMBER_OF_PARTICIPANTS_SHOWN} more` })] })) })))] })));
};
export const AccountCallsBlock = ({ account }) => {
    // Maximum number of calls to render in the table
    // If there are more cals, we will show a button to redirect
    // the user to the calls table.
    const MAX_CALLS_TO_RENDER = 5;
    const { queryCallsResponse, callsLoading, callsError } = useAccountCalls({
        accountId: account.id,
    });
    const [searchQuery, setSearchQuery] = useState("");
    // Set of call IDs that have their views expanded.
    const [expandedCallIds, setExpandedCallIds] = useState(new Set());
    const updateCallExpansionState = (callId, expanded) => {
        if (expanded) {
            setExpandedCallIds((prev) => {
                const updatedSet = new Set(prev);
                updatedSet.add(callId);
                return updatedSet;
            });
            return;
        }
        setExpandedCallIds((prev) => {
            const updatedSet = new Set(prev);
            updatedSet.delete(callId);
            return updatedSet;
        });
    };
    const filteredCalls = useMemo(() => {
        if (!queryCallsResponse || queryCallsResponse.calls.length === 0)
            return [];
        return queryCallsResponse.calls.filter((call) => call.name.includes(searchQuery));
    }, [queryCallsResponse, searchQuery]);
    const renderCallTimestamp = (call) => {
        const upcomingCall = new Date(call.call_time) > new Date();
        return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1" }, { children: [upcomingCall && (_jsx(Badge, { text: "Upcoming", classes: "bg-wds-blue-1 text-wds-blue-4 px-1" })), _jsx(CallTimeDisplay, { call: call, className: "text-wds-gray-6 text-sm p-0", callTimeClassNames: "text-wds-gray-5 text-xs p-0", showCalendarIcon: false, showClockIcon: false, showDate: true, showTime: true })] })));
    };
    if (callsError) {
        return null;
    }
    return (_jsxs(AccountBlockCard, Object.assign({ className: "pb-0 rounded-3xl" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(PhoneIcon, { className: "w-6 h-6 shrink-0" }), _jsx("span", Object.assign({ className: "text-lg font-medium" }, { children: "Calls" })), queryCallsResponse && (_jsx("span", Object.assign({ className: "text-wds-gray-5" }, { children: `(${queryCallsResponse.total})` })))] })), _jsx("div", Object.assign({ className: "flex items-center gap-4" }, { children: _jsxs("div", Object.assign({ className: "flex w-80 h-8 px-3 items-center gap-1 shrink-0 border border-gray-300 bg-white rounded-lg" }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 text-gray-500" }), _jsx("input", { type: "text", placeholder: "Search by name", className: "w-full h-full focus:outline-none border-0 focus:ring-0 text-gray-500", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value) })] })) }))] })), callsLoading || !queryCallsResponse ? (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse pb-6" }, { children: "Fetching calls" }))) : (_jsx(_Fragment, { children: filteredCalls.length ? (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsxs(Table, { children: [_jsx(TableHeader, Object.assign({ className: "border-t border-b border-wds-gray-3 bg-wds-gray-1" }, { children: _jsxs(TableRow, { children: [_jsx(TableHead, { className: "w-16" }), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "Timestamp" })), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-3/5" }, { children: "Summary" })), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "Attendees" })), _jsx(TableHead, { className: "w-16" })] }) })), _jsx(TableBody, { children: filteredCalls
                                        .sort((call1, call2) => new Date(call1.call_time) <= new Date(call2.call_time)
                                        ? 1
                                        : -1)
                                        .slice(/* start= */ 0, /* end= */ MAX_CALLS_TO_RENDER)
                                        .map((call) => {
                                        return (_jsxs(TableRow, Object.assign({ className: "hover:bg-transparent" }, { children: [_jsx(TableCell, Object.assign({ className: "border-x-0" }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "flex items-center text-wds-gray-4 p-1 h-auto rounded-full", onClick: () => updateCallExpansionState(call.id, !expandedCallIds.has(call.id)) }, { children: expandedCallIds.has(call.id) ? (_jsx(ChevronUpIcon, { className: "w-5 h-5 shrink-0" })) : (_jsx(ChevronRightIcon, { className: "w-5 h-5 shrink-0" })) })) })), _jsx(TableCell, Object.assign({ className: "border-x-0" }, { children: renderCallTimestamp(call) })), _jsx(TableCell, Object.assign({ className: "border-x-0" }, { children: _jsx(CallSummary, { call: call, expanded: expandedCallIds.has(call.id) }) })), _jsx(TableCell, Object.assign({ className: "border-x-0" }, { children: _jsx(CallAttendees, { call: call, expanded: expandedCallIds.has(call.id), setExpanded: (value) => updateCallExpansionState(call.id, value) }) })), _jsx(TableCell, Object.assign({ className: "border-x-0" }, { children: _jsx(Link, Object.assign({ to: `/post_call/${call.id}`, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "flex items-center" }, { children: _jsx(ArrowTopRightOnSquareIcon, { className: "w-5 h-5 shrink-0" }) })) })) }))] }), call.id));
                                    }) })] }), queryCallsResponse.total > MAX_CALLS_TO_RENDER && (_jsxs(_Fragment, { children: [_jsx(Separator, { orientation: "horizontal" }), _jsx("div", Object.assign({ className: "flex items-center justify-center h-12" }, { children: _jsx(Link, Object.assign({ to: `/calls?page=1&per_page=10&order_by=[{"field":"call_time","order":"DESC"}]&filter={"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"IN","field":"account_id","value":[${account.id}]}}]}}]}}`, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link" }, { children: "View all calls" })) })) }))] }))] }))) : (_jsx("span", Object.assign({ className: "text-sm text-wds-gray-5 font-medium pb-6" }, { children: "No calls found for this account" }))) }))] })));
};
