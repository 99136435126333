import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import IntegrationTile from "./integration_tile";
import useIntegrations from "./use_integrations";
import IntegrationModal from "./integration_modal";
import WaitingSpinner from "../waiting_spinner";
import { UserContext } from "../App";
import { SlackPreCallDeliveryTarget } from "./slack_pre_call_delivery_target";
const USER_INTEGRATIONS = ["Google Calendar", "Gmail"];
const Integrations = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { integrations, loaded } = useIntegrations();
    const { userIsTenantAdmin } = useContext(UserContext);
    const [selectedIntegrationName, setSelectedIntegrationName] = useState(null);
    const selectedIntegration = useMemo(() => {
        return integrations.find((integration) => integration.name === selectedIntegrationName);
    }, [selectedIntegrationName, integrations]);
    if (!loaded) {
        return (_jsx("div", Object.assign({ className: "flex justify-center items-center h-screen" }, { children: _jsx(WaitingSpinner, { text: "Loading integrations..." }) })));
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "mt-4 ml-8 text-lg font-black leading-7" }, { children: "Connected Integrations" })), _jsx("div", Object.assign({ className: "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" }, { children: integrations.map((integration) => {
                    if (USER_INTEGRATIONS.indexOf(integration.name) === -1 &&
                        !userIsTenantAdmin) {
                        return null;
                    }
                    return (_jsx(IntegrationTile, { name: integration.name, icon: integration.icon, status: integration.status, handleClick: () => setSelectedIntegrationName(integration.name) }, integration.name));
                }) })), selectedIntegration && (_jsx(IntegrationModal, { isModalOpen: !!selectedIntegration, closeModal: () => setSelectedIntegrationName(null), name: selectedIntegration.name, icon: selectedIntegration.icon, connectionStatus: selectedIntegration.status, connectIntegration: selectedIntegration.handleConnect, disconnectIntegration: selectedIntegration.handleDisconnect, shortDescription: (_a = selectedIntegration.listingContent) === null || _a === void 0 ? void 0 : _a.shortDescription, longDescription: (_b = selectedIntegration.listingContent) === null || _b === void 0 ? void 0 : _b.longDescription, capabilities: (_c = selectedIntegration.listingContent) === null || _c === void 0 ? void 0 : _c.capabilities, connectButtonLabel: (_d = selectedIntegration.listingContent) === null || _d === void 0 ? void 0 : _d.connectButtonLabel, connectButtonLogo: (_e = selectedIntegration.listingContent) === null || _e === void 0 ? void 0 : _e.connectButtonLogo, children: selectedIntegration.name === "Slack" ? (_jsx(SlackPreCallDeliveryTarget, {})) : (selectedIntegration.children), connectButtonDisabled: (selectedIntegration.name === "Salesforce" &&
                    ((_f = integrations.find((i) => i.name === "HubSpot")) === null || _f === void 0 ? void 0 : _f.status) ===
                        "Connected") ||
                    (selectedIntegration.name === "HubSpot" &&
                        ((_g = integrations.find((i) => i.name === "Salesforce")) === null || _g === void 0 ? void 0 : _g.status) ===
                            "Connected") }))] }));
};
export default Integrations;
