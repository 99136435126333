import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MagnifyingGlassIcon, UsersIcon } from "@heroicons/react/24/outline";
import { AccountBlockCard } from "../styled_components";
import { useAccountContacts } from "../use_account_contacts";
import { useMemo, useState } from "react";
import { debounce } from "../../../common/debounce";
import { AccountContactsTable } from "./contacts_table";
export const AccountContactsTableBlock = ({ account, onContactMoreDetailsClick, onContactDraftEmailClick, allResearchBlocks, }) => {
    const { accountContacts, contactsLoading, contactsError, invalidateAccountContacts, } = useAccountContacts({
        accountId: account.id,
    });
    const contactEmailDraftBrickExists = allResearchBlocks
        .filter((brick) => brick.research_block_type === "CONTACT")
        .some((brick) => brick.delivery_targets.includes("ContactEmailDrafts"));
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const filteredContacts = useMemo(() => {
        if (!accountContacts) {
            return [];
        }
        return accountContacts.filter((contact) => {
            var _a, _b;
            return (((_a = contact.full_name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(debouncedQuery.toLowerCase())) ||
                ((_b = contact.email) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(debouncedQuery.toLowerCase())));
        });
    }, [accountContacts, debouncedQuery]);
    if (contactsError) {
        return null;
    }
    return (_jsxs(AccountBlockCard, Object.assign({ className: "rounded-3xl pb-0" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(UsersIcon, { className: "w-6 h-6 shrink-0" }), _jsx("span", Object.assign({ className: "text-lg font-medium" }, { children: "Contacts" })), accountContacts && (_jsx("span", Object.assign({ className: "text-wds-gray-5" }, { children: `(${accountContacts.length})` })))] })), _jsx("div", Object.assign({ className: "flex items-center gap-4" }, { children: _jsxs("div", Object.assign({ className: "flex w-80 h-8 px-3 items-center gap-1 shrink-0 border border-gray-300 bg-white rounded-lg" }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 text-gray-500" }), _jsx("input", { type: "text", placeholder: "Search contacts", className: "w-full h-full focus:outline-none border-0 focus:ring-0 text-gray-500", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value) })] })) }))] })), contactsLoading || !accountContacts ? (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse pb-6" }, { children: "Fetching contacts" }))) : (_jsx("div", Object.assign({ className: "w-full pb-6 max-h-96 overflow-y-auto" }, { children: _jsx(AccountContactsTable, { contacts: filteredContacts, contactEmailDraftBrickExists: contactEmailDraftBrickExists, onDraftEmailClick: onContactDraftEmailClick, onMoreDetailsClick: onContactMoreDetailsClick, invalidateAccountContacts: invalidateAccountContacts }) })))] })));
};
