var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AsyncSearchMultiSelect, } from "../components/async_search_multi_select";
import { queryOmniSearch } from "../common/endpoints";
export const PreviewContextSelector = ({ contextType, contextOptions, setContextOptions, }) => {
    const contextFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
        if (!queryClient) {
            return { results: [] };
        }
        const accountsLimit = contextType === "account" ? 5 : 0;
        const callsLimit = contextType === "call" ? 5 : 0;
        const contactsLimit = contextType === "contact" ? 5 : 0;
        const omniSearchResponse = yield queryClient.fetchQuery({
            queryFn: () => queryOmniSearch(userQuery, accountsLimit, callsLimit, contactsLimit, cursor),
        });
        return {
            results: omniSearchResponse.results
                .filter((result) => result.type.toLowerCase().includes(contextType))
                .map((result) => ({
                value: result.id.toString(),
                label: result.name,
            })),
            next_cursor: omniSearchResponse.next_cursor,
        };
    });
    const setSelectedOptions = (options) => {
        setContextOptions(options.map((option) => ({
            id: option.value,
            value: option.value,
            label: option.label,
            type: contextType,
        })));
    };
    return (_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsxs("div", Object.assign({ className: "text-base font-medium" }, { children: [contextType.charAt(0).toUpperCase() + contextType.slice(1), "s selected (", _jsx("span", Object.assign({ className: "text-wds-blue-3" }, { children: contextOptions.length })), ")"] })), _jsx(AsyncSearchMultiSelect, { dataFetcher: contextFetcher, onChange: setSelectedOptions, selectedOptions: contextOptions, placeholder: `Select ${contextType}s...`, className: "w-full" })] })));
};
