var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from "../components/checkbox";
import { useTenantPreCallDeliveryConfig } from "./use_tenant_pre_call_delivery_config";
import { AsyncSearchSelect, } from "../components/async_search_select";
import { getSlackChannelsForPostCall } from "../common/endpoints";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
export const SlackPreCallDeliveryTarget = () => {
    var _a;
    const [preCallMessageEnabled, setPreCallMessageEnabled] = useState(false);
    const queryClient = useQueryClient();
    const { tenantId, preCallPrepDeliveryTargets, preCallPrepDeliveryConfigUpdateMutation, } = useTenantPreCallDeliveryConfig("SLACK");
    useEffect(() => {
        setPreCallMessageEnabled(preCallPrepDeliveryTargets.length > 0);
    }, [preCallPrepDeliveryTargets]);
    const slackChannelsFetcher = (userQuery, ids, _cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const queryResponse = yield queryClient.fetchQuery({
            queryKey: ["slackChannels"],
            queryFn: getSlackChannelsForPostCall,
            // To not refetch on every user query
            staleTime: Infinity,
        });
        return {
            results: [...queryResponse.private, ...queryResponse.public]
                .filter((channel) => channel.name.toLowerCase().includes(userQuery.toLowerCase()))
                .slice(0, 200)
                .map((obj) => {
                return { label: obj.name, value: obj.id };
            }),
        };
    });
    const clearDeliveryConfig = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!tenantId)
            return;
        yield preCallPrepDeliveryConfigUpdateMutation.mutateAsync({
            tenantId,
        });
    });
    const updateDeliveryConfig = (selectedChannelId, selectedChannelName) => __awaiter(void 0, void 0, void 0, function* () {
        if (!tenantId)
            return;
        yield preCallPrepDeliveryConfigUpdateMutation.mutateAsync({
            tenantId,
            deliveryConfig: {
                target: "SLACK",
                target_details: {
                    channel_id: selectedChannelId,
                    channel_name: selectedChannelName,
                },
                // Hard-coded to 30 minutes for now since we don't allow users to define
                // custom delivery times as of now.
                notification_duration: "P0Y0M0DT0H30M0S",
            },
        });
    });
    if (!preCallPrepDeliveryTargets) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: "p-4 pt-0 flex flex-col gap-4" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 items-center" }, { children: [_jsx(Checkbox, { id: "PRE_CALL_MESSAGE_ENABLED", onClick: () => {
                            if (preCallMessageEnabled) {
                                // Should clear the existing config if the user turns it off.
                                clearDeliveryConfig();
                            }
                            setPreCallMessageEnabled((prev) => !prev);
                        }, checked: preCallMessageEnabled }), _jsx("div", { children: _jsx("label", Object.assign({ htmlFor: "PRE_CALL_MESSAGE_ENABLED", className: "text-sm font-medium" }, { children: "Deliver pre-call prep to Slack?" })) })] })), preCallMessageEnabled && (_jsxs("div", Object.assign({ className: "flex gap-2 items-center" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm" }, { children: "Channel to deliver the pre-call message:" })), _jsx(AsyncSearchSelect, { dataFetcher: slackChannelsFetcher, onSelect: (option) => {
                            if (!option)
                                return;
                            updateDeliveryConfig(option.value, option.label);
                        }, selectedOption: preCallPrepDeliveryTargets.length > 0
                            ? {
                                label: (_a = preCallPrepDeliveryTargets[0].target_details
                                    .channel_name) !== null && _a !== void 0 ? _a : "Unknown channel",
                                value: preCallPrepDeliveryTargets[0].target_details.channel_id,
                            }
                            : undefined, placeholder: "Select channel" })] })))] })));
};
