import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useId, useState } from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "../../../components/dropdown_menu";
import { DropdownMenuButtonContainer } from "./styled_components";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { AsyncSearchMultiSelect, } from "../../../components/async_search_multi_select";
import { useQuery, useQueryClient } from "react-query";
import { Skeleton } from "../../../components/skeleton";
import { StringListEditor } from "../../../components/string_list_editor";
import { ScrollArea } from "../../../components/scroll_area";
export const StringValueEditor = ({ onInputChange, value, }) => {
    return (_jsx("div", Object.assign({ className: "flex w-52 h-8 px-4 items-center gap-2 border border-wds-gray-3 rounded-r-lg bg-white" }, { children: _jsx("input", { type: "text", className: "p-0 h-6 border-none focus:ring-0 focus:border-none bg-white", value: value !== null && value !== void 0 ? value : "", onChange: (e) => {
                onInputChange(e.target.value);
            } }) })));
};
export const NumberValueEditor = ({ onInputChange, value, }) => {
    return (_jsx("div", Object.assign({ className: "flex w-52 h-8 px-4 items-center gap-2 border border-wds-gray-3 rounded-r-lg bg-white" }, { children: _jsx("input", { type: "number", className: "p-0 h-6 border-none focus:ring-0 focus:border-none bg-white", value: value !== null && value !== void 0 ? value : "", onChange: (e) => {
                onInputChange(parseFloat(e.target.value));
            } }) })));
};
export const BooleanValueEditor = ({ onInputChange, value, }) => {
    useEffect(() => {
        onInputChange(value ? true : false);
    }, [value]);
    return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { children: _jsxs(DropdownMenuButtonContainer, Object.assign({ className: "rounded-none rounded-r-lg" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium" }, { children: value ? "True" : "False" })), _jsx(ChevronDownIcon, { className: "w-5 h-5 shrink-0" })] })) }), _jsxs(DropdownMenuContent, { children: [_jsx(DropdownMenuItem, Object.assign({ className: "hover:cursor-pointer", onClick: () => onInputChange(true) }, { children: "True" })), _jsx(DropdownMenuItem, Object.assign({ className: "hover:cursor-pointer", onClick: () => onInputChange(false) }, { children: "False" }))] })] }));
};
export const SelectValueEditor = ({ onInputChange, options, value, }) => {
    useEffect(() => {
        const selectedValue = options.find((option) => option === value);
        if (selectedValue) {
            onInputChange(selectedValue);
        }
    }, [value]);
    return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { children: _jsxs(DropdownMenuButtonContainer, Object.assign({ className: "rounded-none rounded-r-lg" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium" }, { children: value })), _jsx(ChevronDownIcon, { className: "w-5 h-5 shrink-0" })] })) }), _jsx(DropdownMenuContent, { children: _jsx(ScrollArea, Object.assign({ className: "max-h-60 overflow-auto" }, { children: options.map((option) => (_jsx(DropdownMenuItem, Object.assign({ className: "hover:cursor-pointer", onClick: () => onInputChange(option) }, { children: option })))) })) })] }));
};
export const DateValueEditor = ({ onInputChange, value, }) => {
    const getFormattedValue = (value) => {
        if (!value)
            return "";
        if (typeof value === "string")
            return value.split("T")[0];
        return value.toISOString().split("T")[0];
    };
    return (_jsx("div", Object.assign({ className: "flex w-52 h-8 px-4 items-center gap-2 border border-wds-gray-3 rounded-r-lg bg-white" }, { children: _jsx("input", { type: "date", className: "p-0 h-6 border-none focus:ring-0 focus:border-none bg-white", value: getFormattedValue(value), onChange: (e) => {
                if (!e.target.value || !e.target.value.length) {
                    return;
                }
                // This converts the date to the user's local time zone
                const [year, month, day] = e.target.value.split("-").map(Number);
                const parsedDate = new Date(year, month - 1, day, 0, 0, 0);
                onInputChange(parsedDate);
            } }) })));
};
export const ReferenceValueEditor = ({ filterField, dataFetcher, onInputChange, value, }) => {
    const componentId = useId();
    const queryClient = useQueryClient();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { isLoading: selectedOptionsLoading } = useQuery({
        queryKey: ["selectedOptions", filterField.id, value],
        queryFn: () => {
            if (value && value.length > 0) {
                return dataFetcher(
                /* userQuery= */ "", value, 
                /* cursor= */ "", queryClient);
            }
            return { results: [] };
        },
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setSelectedOptions(data.results.map((item) => {
                return {
                    value: item.value,
                    label: item.label,
                };
            }));
        },
        // This is to make sure that we only fetch objects the very first time
        // this component mounts. (We make a fetch call only if there are pre-selected
        // values in the filter).
        enabled: selectedOptions.length === 0,
    });
    if (selectedOptionsLoading) {
        return _jsx(Skeleton, { className: "h-8 w-52" });
    }
    return (_jsx(AsyncSearchMultiSelect, { id: componentId, dataFetcher: dataFetcher, onChange: (options) => {
            setSelectedOptions(options);
            onInputChange(options.map((option) => parseInt(option.value)));
        }, selectedOptions: selectedOptions, placeholder: `Search ${filterField.name.toLowerCase()}...` }));
};
export const SubstringInFilterEditor = ({ onInputChange, value, }) => {
    const componentId = useId();
    return (_jsx(StringListEditor, { id: componentId, selectedStrings: value !== null && value !== void 0 ? value : [], onChange: onInputChange }));
};
